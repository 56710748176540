// import { useContext } from 'react'

// Enrutamiento
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

// Layout
import VisualSAMWeb from './componentes/layout/VisualSAMWeb'
import Bienvenida from './componentes/layout/Bienvenida'

import Login from './componentes/admin/Login'
import Proformas from './componentes/ventas/proformas/Proformas'
import Facturas from './componentes/ventas/facturas/Facturas'
import Clientes from './componentes/ventas/clientes/Clientes'

import { SesionProvider } from './context/SesionContext'

function App () {
  // Captura el Click Derecho de la pantalla para evitar ver el menú y acceder al código fuente
  document.oncontextmenu = function () { return false }
  return (
    <Router>
      <SesionProvider>
        <Routes>
          <Route path='/' element={<VisualSAMWeb />}>
            <Route path='login' element={<Login />} />
            <Route path='/' element={<Bienvenida />} />
            <Route path='bienvenida' element={<Bienvenida />} />
            <Route path='proformas/nuevas' element={<Proformas existentes={false} />} />
            <Route path='proformas/existentes' element={<Proformas existentes />} />
            <Route path='facturas/nuevas' element={<Facturas existentes={false} />} />
            <Route path='facturas/existentes' element={<Facturas existentes />} />
            <Route path='clientes' element={<Clientes opcMenuCall='' filtrando={false} existentes={false} updateTablaDoc={false} />} />
            <Route
              path='*'
              element={
                <div className='m-5'>
                  <h3>404 La página no existe</h3>
                </div>
                }
            />
          </Route>
        </Routes>
      </SesionProvider>
    </Router>
  )
}

export default App
