import axios from 'axios'
import Swal from 'sweetalert2'

import encryptStorage from '../utils/encryptStorage'

let urlMySQLFinal = process.env.REACT_APP_URL_BACKEND_MYSQL
let urlOrclFinal = process.env.REACT_APP_URL_BACKEND_ORCL
if (process.env.REACT_APP_URL_BACKEND_MYSQL2) {
  let urlMySQLBackend = process.env.REACT_APP_URL_BACKEND_MYSQL2.split(':')
  urlMySQLBackend = urlMySQLBackend[0] + ':' + urlMySQLBackend[1]
  urlMySQLFinal = window.location.origin === urlMySQLBackend
    ? process.env.REACT_APP_URL_BACKEND_MYSQL2
    : process.env.REACT_APP_URL_BACKEND_MYSQL
}
if (process.env.REACT_APP_URL_BACKEND_ORCL2) {
  let urlOrclBackend = process.env.REACT_APP_URL_BACKEND_ORCL2.split(':')
  urlOrclBackend = urlOrclBackend[0] + ':' + urlOrclBackend[1]
  urlOrclFinal = window.location.origin === urlOrclBackend
    ? process.env.REACT_APP_URL_BACKEND_ORCL2
    : process.env.REACT_APP_URL_BACKEND_ORCL
}
console.log('🚀 ~ file: axios.js:8 ~ urlMySQLFinal, urlOrclFinal:', urlMySQLFinal, urlOrclFinal)
const clienteAxios = axios.create({
  baseURL: urlOrclFinal
})
const axiosMysql = axios.create({
  baseURL: urlMySQLFinal
})

// clienteAxios.interceptors.request.use((config) => {
//   return config
// }, (error) => {
//   return Promise.reject(error)
// })

function restAPIAxios (msjError, config, sinConsTec) {
  // Solo para capturar el token y que no sea necesario enviarlo en cada pedido de restAPIAxios
  const sesion = encryptStorage.getItem('sesion')
  if (sesion && sesion.token) {
    config = { ...config, headers: { ...config.headers, Authorization: `Bearer ${sesion.token}` } }
  }

  const promesa = clienteAxios(config)
  function devuelveDatos (msjError) {
    return new Promise((resolve, reject) => {
      resolve(
        promesa.then((respuesta) => {
          return respuesta
        }).then((datos) => {
          return datos
        }).catch((error) => {
          if (msjError !== '') {
            if (error.response) {
              // No hay error realmente. Solo se necesita presentar el mensaje
              if (error.response.data.code && error.response.data.code.substring(0, 7) === 'VSW-000') {
                Swal.fire({
                  title: msjError,
                  text: error.response.data.msjError,
                  footer: error.response.data.code,
                  icon: 'info'
                })
              } else if (error.response.data.code && error.response.data.code.substring(0, 7) === 'VSW-001') {
                Swal.fire({
                  title: msjError,
                  text: error.response.data.msjError,
                  footer: 'Consulte a su técnico',
                  icon: 'error'
                })
              } else if (error.response.data.msjError) {
                Swal.fire({
                  title: msjError,
                  text: sinConsTec ? error.response.data.msjError : 'Consulte a su técnico',
                  footer: sinConsTec ? '' : error.response.data.msjError,
                  icon: sinConsTec ? 'info' : 'error'
                })
              } else {
                Swal.fire({
                  title: msjError,
                  text: sinConsTec ? error.response.data : 'Consulte a su técnico',
                  footer: sinConsTec ? '' : error.response.data,
                  icon: sinConsTec ? 'info' : 'error'
                })
              }
            } else {
              Swal.fire({
                title: msjError,
                text: sinConsTec ? error.code : 'Consulte a su técnico',
                footer: sinConsTec ? '' : error.code,
                icon: sinConsTec ? 'info' : 'error'
              })
            }
          }
          return error
        })
      )
    })
  }

  return devuelveDatos(msjError)
  // return procesandoPromesa(promesa, msjError)
}

export function restAPIMySQL (msjError, config, sinConsTec) {
  // Solo para capturar el token y que no sea necesario enviarlo en cada pedido de restAPIAxios
  const sesion = encryptStorage.getItem('sesion')
  if (sesion && sesion.token) {
    config = { ...config, headers: { ...config.headers, Authorization: `Bearer ${sesion.token}` } }
  }

  const promesa = axiosMysql(config)
  function devuelveDatos (msjError) {
    return new Promise((resolve, reject) => {
      resolve(
        promesa.then((respuesta) => {
          return respuesta
        }).then((datos) => {
          return datos
        }).catch((error) => {
          if (msjError !== '') {
            if (error.response) {
              if (error.response.data.msjError) {
                Swal.fire({
                  title: msjError,
                  text: sinConsTec ? error.response.data.msjError : 'Consulte a su técnico',
                  footer: sinConsTec ? '' : error.response.data.msjError,
                  icon: sinConsTec ? 'info' : 'error'
                })
              } else {
                Swal.fire({
                  title: msjError,
                  text: sinConsTec ? error.response.data : 'Consulte a su técnico',
                  footer: sinConsTec ? '' : error.response.data,
                  icon: sinConsTec ? 'info' : 'error'
                })
              }
            } else {
              Swal.fire({
                title: msjError,
                text: sinConsTec ? error.code : 'Consulte a su técnico',
                footer: sinConsTec ? '' : error.code,
                icon: sinConsTec ? 'info' : 'error'
              })
            }
          }
          return error
        })

      )
    })
  }

  return devuelveDatos(msjError)
}

export default restAPIAxios
