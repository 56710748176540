import { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Dropdown, SplitButton } from 'react-bootstrap'

import { SesionContext, nuevaSesion } from '../../context/SesionContext'
import encryptStorage from '../../utils/encryptStorage'

const MnuProformas = () => {
  const navigate = useNavigate()
  const [sesion, setSesion] = useContext(SesionContext)
  // console.log(sesion)
  const userVSAM = encryptStorage.getItem('userVSAM')
  if (!userVSAM) return null
  // Asigna si tiene acceso a todo el botón (menú Facturación y opción Ventas)
  const accMnuProf = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_FACTURACION) === -1
  const accMnuVtas = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_VENTAS) === -1
  const accCejaProf = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_PROF_CEJA) === -1
  const accModificar = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_PROF_MOD) === -1

  const ctrlClickNuevas = (e) => {
    // Por si acaso este modificando una Proforma
    // console.log(sesion)
    const nSesion = nuevaSesion(false, sesion,
      {
        proformas: { clave: 0, index: 0, modificando: false, filtrando: false }
      })
    setSesion(nSesion)
    navigate('/proformas/nuevas')
  }

  function ctrlClickExistente (e) {
    // console.log('ctrlClickExistente', sesion)
    const nSesion = nuevaSesion(false, sesion,
      {
        proformas: {
          clave: sesion.proformas.clave,
          index: sesion.proformas.index,
          modificando: false,
          filtrando: true
        }
      })
    setSesion(nSesion)
    navigate('/proformas/existentes')
  }

  function ctrlClickModificando (e) {
    const nSesion = nuevaSesion(false, sesion,
      {
        proformas: { clave: 0, index: 0, modificando: true, filtrando: false }
      })
    setSesion(nSesion)
    navigate('/proformas/nuevas')
  }

  return (
    <SplitButton
      className='bg-secondary-subtle me-3'
      size='sm'
      variant='outline-secondary text-black'
      title='Proformas'
      disabled={!accMnuProf}
      onClick={ctrlClickNuevas}
    >
      <Dropdown.Item
        className='p-0'
        size='sm'
        disabled={!accMnuVtas || !accCejaProf}
      >
        <Link
          className={'dropdown-item' + ((accMnuVtas && accCejaProf) ? '' : ' disabled')}
          to='/proformas/nuevas' onClick={ctrlClickNuevas}
        >Nuevas
        </Link>
      </Dropdown.Item>
      <Dropdown.Item
        className='p-0'
        size='sm'
        disabled={!accMnuVtas || !accCejaProf}
      >
        <Link
          className={'dropdown-item' + (accMnuVtas && accCejaProf ? '' : ' disabled')}
          to='/proformas/existentes' onClick={ctrlClickExistente}
        >Existentes
        </Link>
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        className='p-0'
        size='sm'
        disabled={!accMnuVtas || !accModificar}
      >
        <Link
          className={'dropdown-item' + (accMnuVtas && accModificar ? '' : ' disabled')}
          to='/proformas/nuevas' onClick={ctrlClickModificando}
        >Modificando
        </Link>
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        className='p-0'
        size='sm'
      >
        <Link
          className='dropdown-item'
          to='/proformas/reporte'
        >Reporte
        </Link>
      </Dropdown.Item>
    </SplitButton>
  )
}

export default MnuProformas
