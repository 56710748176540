import { useContext, useEffect, useState } from 'react'
import { Container, InputGroup, Form, Button, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Swal from 'sweetalert2'

import restAPIAxios from '../../../utils/axios'
import { SesionContext } from '../../../context/SesionContext'
import encryptStorage from '../../../utils/encryptStorage'

import {
  gcFmtosBotones, gcTiposCliente, gcCliCreIniciaDiaMes, gcCliCrePeriodoMensual,
  anchoPantDisponible, infoVendedorSel
} from '../../../utils/generales'

import CampoNumerico from '../../generales/CampoNumerico'
import TablaCampoFecha from '../../generales/TablaCampoFecha'
import TablaOpcion from '../../generales/TablaOpcion'

import imgArriba from '../../../iconos/ARRIBA.PNG'

function Cliente ({
  filtrando, existentes, datCliente, estaCreando, opcMenuCall, idRowFiltro,
  devuelveOcultaCliEdit, devuelveCargando, devuelveCreaModCli
}) {
  const [sesion] = useContext(SesionContext)
  const poolName = sesion.orcl_host

  const [regActual, setRegActual] = useState({})

  // Extrae los datos de la empresa (mysql)
  const userEmpConfig = encryptStorage.getItem('config')

  // Variables de Acceso
  let accDsctoVtas = false
  let accDiasCre = false
  const userVSAM = encryptStorage.getItem('userVSAM')
  if (userVSAM) {
    accDsctoVtas = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_VTAS_DSCTO_MOD) === -1
    accDiasCre = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_DIAS_CRE) === -1
  }

  useEffect(() => {
    setRegActual(datCliente[1])
  }, [datCliente])

  // FUNCIONES DE CONTROL (EVENTOS) DE ELEMENTOS DEL COMPONENTE
  function ctrlChangeCampo (e) {
    // e.preventDefault()
    setRegActual({ ...regActual, [e.target.id]: e.target.value })
  }
  function ctrlGrabar (e) {
    e.preventDefault()
    async function grabar () {
      devuelveCargando(true)
      const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE ${estaCreando ? 'CREAR' : 'MODIFICAR'} EL CLIENTE`,
        {
          method: estaCreando ? 'post' : 'patch',
          url: estaCreando ? '/cliente/crea' : '/cliente/modifica',
          data: {
            poolName,
            orclUserVSAM: sesion.orcl_usuarioVSAM,
            noUser: sesion.no_usuarioVSAM,
            noEmp: sesion.no_empresaVSAM,
            datos: regActual
          }
        }
      )
      devuelveCargando(false)
      if (respuestaAPI.status === 200) {
        devuelveCreaModCli(estaCreando ? respuestaAPI.data.registro : regActual, estaCreando)
        // Mensajes finales después de crear el cliente
        // Mensaje cuando el cliente ya estaba creado en T_CLIENTE
        if (estaCreando) {
          if (respuestaAPI.data.yaExiste.cliente) {
            Swal.fire(
              'EL CLIENTE YA EXISTIA',
              'El sistema seleccionó los datos del cliente existente',
              'info'
            )
          // Mensaje cuando el cliente ya estaba creado en T_INSTITUCION
          } else if (respuestaAPI.data.yaExiste.instit) {
            Swal.fire(
              'EL CLIENTE YA EXISTIA COMO INSTITUCION',
              'Se creó el cliente con los datos existentes de la institución más los otros digitados por el usuario',
              'info'
            )
          }
        }
      }
    }
    Swal.fire({
      title: 'CONFIRME',
      text: 'Los datos ingresados son correctos?',
      footer: estaCreando
        ? 'Va a crear el nuevo cliente'
        : `Va a modificar los datos del cliente (${datCliente[1].ID} ${datCliente[1].NOMBRE}) `,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'SI',
      cancelButtonText: 'NO'
    }).then((result) => {
      if (result.isConfirmed) grabar()
    })
  }
  function ctrlClickArriba (e) {
    // e.preventDefault()
    devuelveOcultaCliEdit()
  }
  return (
    <>
      <Row
        className='mb-3'
        style={{ minHeight: opcMenuCall ? 400 : 700, minWidth: opcMenuCall ? anchoPantDisponible(idRowFiltro) : undefined }}
      >
        <Col className='ms-3' xs='auto' style={{ maxWidth: 50 }}>
          <OverlayTrigger overlay={<Tooltip>regresar</Tooltip>}>
            <Button
              className={gcFmtosBotones.conImagen}
              size='sm'
              onClick={ctrlClickArriba}
            ><img src={imgArriba} alt='Arriba' width={gcFmtosBotones.imgWidth} />
            </Button>
          </OverlayTrigger>
        </Col>
        <Col className='me-3'>
          <Container className={'ps-3 pe-3 pb-2 mb-3 shadow rounded border' + (estaCreando ? ' border-secondary' : ' border-success')}>
            <div
              className={'text-center fw-bold rounded border mb-2' + (estaCreando ? ' bg-secondary-subtle border-secondary' : ' bg-success-subtle border-success')}
            >{estaCreando ? 'DATOS DEL NUEVO CLIENTE' : 'DATOS DEL CLIENTE QUE MODIFICA'}
            </div>
            <Form onSubmit={ctrlGrabar}>
              {/* ID (ruc, ci, pasaporte), TIPO, CATEGORIA Y SIGLAS */}
              <Row className=''>
                <Col>
                  <InputGroup
                    className='mb-1'
                    size='sm'
                    style={{ minWidth: 270, maxWidth: 300 }}
                  >
                    <InputGroup.Text
                      className='' size='sm' id='lblID'
                    >ID (ruc, ci, pasaporte):
                    </InputGroup.Text>
                    <Form.Control
                      className=''
                      id='ID'
                      type='text'
                      autoFocus
                      required
                      maxLength={15}
                      aria-describedby='lblID'
                      value={regActual.ID}
                      onChange={ctrlChangeCampo}
                    />
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup
                    className='mb-1'
                    size='sm'
                  >
                    <InputGroup.Text
                      className='' size='sm' id='lblTIPO'
                    >Tipo:
                    </InputGroup.Text>
                    <Form.Select
                      className='' size='sm'
                      style={{ minWidth: 160, maxWidth: 170 }}
                      id='TIPO'
                      value={regActual.TIPO}
                      onChange={ctrlChangeCampo}
                    >{Object.keys(gcTiposCliente).map((valor, index) =>
                      (<option key={index} value={valor}>{gcTiposCliente[valor]}</option>)
                    )}
                    </Form.Select>
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup
                    className='mb-1'
                    size='sm'
                    style={{ width: 180 }}
                  >
                    <InputGroup.Text
                      className='' size='sm' id='lblCATEGORIA'
                    >Categoría:
                    </InputGroup.Text>
                    <Form.Control
                      className=''
                      id='CATEGORIA'
                      type='text'
                      maxLength={10}
                      aria-describedby='lblCATEGORIA'
                      value={regActual.CATEGORIA ?? ''}
                      onChange={ctrlChangeCampo}
                    />
                  </InputGroup>
                </Col>
                <Col xs='auto'>
                  <InputGroup
                    className='mb-1'
                    size='sm'
                    style={{ width: 160 }}
                  >
                    <InputGroup.Text
                      className='' size='sm' id='lblSIGLAS'
                    >Siglas:
                    </InputGroup.Text>
                    <Form.Control
                      className=''
                      id='SIGLAS'
                      type='text'
                      maxLength={10}
                      aria-describedby='lblSIGLAS'
                      value={regActual.SIGLAS ?? ''}
                      onChange={ctrlChangeCampo}
                    />
                  </InputGroup>
                </Col>
              </Row>
              {/* NOMBRE */}
              <InputGroup
                className='mb-1'
                size='sm'
              >
                <InputGroup.Text className='' size='sm' id='lblNOMBRE'>Nombre:</InputGroup.Text>
                <Form.Control
                  className=''
                  id='NOMBRE'
                  type='text'
                  required
                  maxLength={64}
                  aria-describedby='lblNOMBRE'
                  value={regActual.NOMBRE ?? ''}
                  onChange={ctrlChangeCampo}
                />
              </InputGroup>
              {/* DIRECCION */}
              <InputGroup
                className='mb-1'
                size='sm'
              >
                <InputGroup.Text className='' size='sm' id='lblDIRECC'>Dirección:</InputGroup.Text>
                <Form.Control
                  className=''
                  id='DIRECC'
                  type='text'
                  maxLength={64}
                  aria-describedby='lblDIRECC'
                  value={regActual.DIRECC ?? ''}
                  onChange={ctrlChangeCampo}
                />
              </InputGroup>
              {/* TELEFONO Y FAX */}
              <Row className=''>
                <Col>
                  <InputGroup
                    className='mb-1'
                    size='sm'
                    style={{ minWidth: 300 }}
                  >
                    <InputGroup.Text
                      className='' size='sm' id='lblTELEF'
                    >Teléfonos:
                    </InputGroup.Text>
                    <Form.Control
                      className=''
                      id='TELEF'
                      type='text'
                      maxLength={30}
                      aria-describedby='lblTELEF'
                      value={regActual.TELEF ?? ''}
                      onChange={ctrlChangeCampo}
                    />
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup
                    className='mb-1'
                    size='sm'
                    style={{ minWidth: 300 }}
                  >
                    <InputGroup.Text
                      className='' size='sm' id='lblFAX'
                    >{userEmpConfig.textos_labels.cliFax || 'Fax'}:
                    </InputGroup.Text>
                    <Form.Control
                      className=''
                      id='FAX'
                      type='text'
                      maxLength={30}
                      aria-describedby='lblFAX'
                      value={regActual.FAX ?? ''}
                      onChange={ctrlChangeCampo}
                    />
                  </InputGroup>
                </Col>
              </Row>
              {/* DSCTO, NO_PV, DIAS CREDITO Y FECHA DESDE */}
              <Row className=''>
                <Col>
                  <InputGroup className='' size='sm' style={{ width: 160 }}>
                    <InputGroup.Text id='lblPDscto'>Dscto.:</InputGroup.Text>
                    <CampoNumerico
                      idName='P_DSCTO' devuelveVal={(campo, valor) => setRegActual({ ...regActual, [campo]: valor })}
                      fmtoInput='text-end' decimales={userEmpConfig.decimales}
                      deshabilitado={existentes || !accDsctoVtas} soloPositivo
                      min={0} max={100}
                    >{regActual.P_DSCTO}
                    </CampoNumerico>
                    <InputGroup.Text id='' className=''>%</InputGroup.Text>
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup className='' size='sm' style={{ width: 110 }}>
                    <InputGroup.Text id='lblNO_PV'>No. PV:</InputGroup.Text>
                    <CampoNumerico
                      idName='NO_PV' devuelveVal={(campo, valor) => setRegActual({ ...regActual, [campo]: valor })}
                      fmtoInput='text-end' decimales={0}
                      deshabilitado={existentes} soloPositivo
                      min={0} max={99}
                    >{regActual.NO_PV}
                    </CampoNumerico>
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup className='' size='sm' style={{ width: 150 }}>
                    <InputGroup.Text id='lblPDscto'>Días crédito:</InputGroup.Text>
                    <CampoNumerico
                      idName='V_INI_X_COB' fmtoInput='text-end' decimales={0}
                      devuelveVal={(campo, valor) => setRegActual({ ...regActual, [campo]: valor })}
                      deshabilitado={existentes || !accDiasCre} soloPositivo
                      min={0}
                    >{regActual.V_INI_X_COB}
                    </CampoNumerico>
                  </InputGroup>
                </Col>
                <Col xs='auto'>
                  <TablaCampoFecha
                    infoCampo={
                                {
                                  fmto: ['mb-1 me-2', '', ''],
                                  id: 'F_DESDE',
                                  label: 'Cliente desde:',
                                  required: true,
                                  disabled: (existentes),
                                  conCondFiltro: false,
                                  showHora: false,
                                  dias: 0
                                }
                              }
                    valTabla={regActual.F_DESDE}
                    devuelveVal={(valor) => setRegActual({ ...regActual, F_DESDE: valor })}
                    devuelveEditando={(valor) => { /* no debe hacer nada, pero es necesario enviar la funcion */ }}
                    devuelveCond={(valor) => { /* no debe hacer nada, pero es necesario enviar la funcion */ }}
                  />
                </Col>
              </Row>
              {/* EMAIL */}
              <InputGroup
                className='mb-1'
                size='sm'
              >
                <InputGroup.Text
                  className='' size='sm' id='lblEMAIL'
                >{userEmpConfig.textos_labels.cliEmail || 'Email'}:
                </InputGroup.Text>
                <Form.Control
                  className=''
                  id='EMAIL'
                  type='text'
                  maxLength={250}
                  aria-describedby='lblEMAIL'
                  value={regActual.EMAIL ?? ''}
                  onChange={ctrlChangeCampo}
                />
              </InputGroup>
              {/* ATENCION PERSONA y VENDEDOR */}
              <Row>
                <Col>
                  <InputGroup
                    className='mb-1'
                    size='sm'
                  >
                    <InputGroup.Text className='' size='sm' id='lblATTPERSONA'>Att. Persona:</InputGroup.Text>
                    <Form.Control
                      className=''
                      id='ATTPERSONA'
                      type='text'
                      maxLength={64}
                      aria-describedby='lblATTPERSONA'
                      value={regActual.ATTPERSONA ?? ''}
                      onChange={ctrlChangeCampo}
                    />
                  </InputGroup>
                </Col>
                <Col>
                  <TablaOpcion
                    valTabla={regActual.NO_VENDEDOR}
                    infoTablaLista={infoVendedorSel(['mb-1', '', ''], (userEmpConfig.textos_labels.cliVendedor || 'Vendedor'))}
                    devuelveDatos={({ nuevoVal, index, descrip }) => {
                      setRegActual({ ...regActual, NO_VENDEDOR: nuevoVal, VEND_NOMBRE: descrip })
                    }}
                    deshabilitado={false}
                    devuelveEditando={(valor) => { /* no debe hacer nada, pero es necesario enviar la funcion */ }}
                  />
                </Col>
              </Row>
              {/* OTROS DATOS */}
              <InputGroup
                className='mb-1'
                size='sm'
              >
                <InputGroup.Text className='' size='sm' id='lblOTROS_DATOS'>Otros Datos:</InputGroup.Text>
                <Form.Control
                  className=''
                  id='OTROS_DATOS'
                  type='text'
                  maxLength={250}
                  aria-describedby='lblOTROS_DATOS'
                  value={regActual.OTROS_DATOS ?? ''}
                  onChange={ctrlChangeCampo}
                />
              </InputGroup>
              {/* DATO ADICIONAL */}
              <InputGroup
                className='mb-1'
                size='sm'
              >
                <InputGroup.Text
                  className='' size='sm' id='lblEXTRA1_TXT'
                >{userEmpConfig.textos_labels.cliExtra1 || 'Dato adicional'}:
                </InputGroup.Text>
                <Form.Control
                  className=''
                  id='EXTRA1_TXT'
                  type='text'
                  maxLength={250}
                  aria-describedby='lblEXTRA1_TXT'
                  value={regActual.EXTRA1_TXT ?? ''}
                  onChange={ctrlChangeCampo}
                />
              </InputGroup>
              {/* DATOS PARA EL CONTROL DE CREDITO */}
              <Row
                className='ms-1 me-1 pt-1 pb-1 border border-primary-subtle align-items-center'
              >
                <Col>
                  <Form.Check
                    className='text-center'
                    id='L_CREDITO'
                    style={{ width: 200 }}
                    type='checkbox'
                    label='Control crédito máximo: '
                    reverse
                    checked={regActual.L_CREDITO === -1 || !accDiasCre}
                    disabled={!accDiasCre}
                    onChange={(e) => {
                      // ** No usar e.preventDefault() porque causa efectos no deseados al presionar en el check
                      setRegActual({
                        ...regActual,
                        [e.target.id]: e.target.checked ? -1 : 0,
                        V_LIMITE_CRE: 0,
                        VCRE_PERIODO_MESES: null,
                        VCRE_DIA_INI: null
                      })
                    }}
                  />
                </Col>
                <Col>
                  <InputGroup
                    className='' size='sm' style={{ width: 210 }}
                  >
                    <InputGroup.Text id='lblPDscto'>Valor máximo:</InputGroup.Text>
                    <CampoNumerico
                      idName='V_LIMITE_CRE' fmtoInput='text-end'
                      decimales={userEmpConfig.decimales}
                      devuelveVal={(campo, valor) => setRegActual({ ...regActual, [campo]: valor })}
                      deshabilitado={existentes || !accDiasCre || regActual.L_CREDITO === 0}
                      soloPositivo min={0}
                    >{regActual.V_LIMITE_CRE}
                    </CampoNumerico>
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup
                    className='mb-1' size='sm' style={{ width: 255 }}
                  >
                    <InputGroup.Text
                      className='' size='sm' id='lblVCRE_PERIODO_MESES'
                    >Período ctrl. cada:
                    </InputGroup.Text>
                    <Form.Select
                      className='' size='sm'
                      id='VCRE_PERIODO_MESES'
                      value={regActual.VCRE_PERIODO_MESES ?? ''}
                      disabled={regActual.L_CREDITO === 0}
                      onChange={ctrlChangeCampo}
                    ><option key={0} value={null} />
                      {Object.keys(gcCliCrePeriodoMensual).map((valor, index) =>
                        (<option key={index + 1} value={valor}>{gcCliCrePeriodoMensual[valor]}</option>)
                      )}
                    </Form.Select>
                  </InputGroup>
                </Col>
                <Col xs='auto'>
                  <InputGroup
                    className='mb-1' size='sm' style={{ width: 225 }}
                  >
                    <InputGroup.Text
                      className='' size='sm' id='lblVCRE_DIA_INI'
                    >Inicia día del mes:
                    </InputGroup.Text>
                    <Form.Select
                      className='' size='sm'
                      id='VCRE_DIA_INI'
                      value={regActual.VCRE_DIA_INI ?? ''}
                      disabled={regActual.L_CREDITO === 0}
                      onChange={ctrlChangeCampo}
                    ><option key={0} value={null} />
                      {Object.keys(gcCliCreIniciaDiaMes).map((valor, index) =>
                        (<option key={index + 1} value={valor}>{gcCliCreIniciaDiaMes[valor]}</option>)
                      )}
                    </Form.Select>
                  </InputGroup>
                </Col>
              </Row>
              <Row className='justify-content-center mt-2'>
                <Button
                  type='submit'
                  style={{ width: 100 }}
                >{estaCreando ? 'Crear' : 'Modificar'}
                </Button>
              </Row>
            </Form>
          </Container>
        </Col>
      </Row>
    </>
  )
}
export default Cliente
