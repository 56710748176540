import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import { Button, Container, Row, Col, Form, OverlayTrigger, Tooltip, SplitButton, Dropdown } from 'react-bootstrap'

import { SesionContext, nuevaSesion } from '../../../context/SesionContext'
import restAPIAxios from '../../../utils/axios.js'
import encryptStorage from '../../../utils/encryptStorage'

import { gcFmtosBotones } from '../../../utils/generales.js'

import imgGrabar from '../../../iconos/GRABAR.BMP'
import imgCancelar from '../../../iconos/CANCELAR.BMP'
import imgModificar from '../../../iconos/MODIFICAR.ICO'
import imgFiltrar from '../../../iconos/FILTRAR.ICO'
import imgListado from '../../../iconos/LISTADO.BMP'
import imgImprimir from '../../../iconos/IMPRIMIR.ICO'
import imgMail from '../../../iconos/MAIL.ICO'

import imgMas from '../../../iconos/MAS.ICO'
import imgMenos from '../../../iconos/MENOS.ICO'

import imgPrimero from '../../../iconos/PRIMERO.PNG'
import imgAnterior from '../../../iconos/ANTERIOR.PNG'
import imgSiguiente from '../../../iconos/SIGUIENTE.PNG'
import imgUltimo from '../../../iconos/ULTIMO.PNG'

function Botones (props) {
  const totalRegs = props.totalRegs
  const existentes = props.existentes
  const regActual = props.datosReg
  const editando = props.editando

  const navigate = useNavigate()
  const [sesion, setSesion] = useContext(SesionContext)

  const [grabando, setGrabando] = useState(false)

  const userVSAM = encryptStorage.getItem('userVSAM')
  let accCejaProf = false
  let accModificar = false
  let accEliminar = false
  if (userVSAM) {
    accCejaProf = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_PROF_CEJA) === -1
    accModificar = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_PROF_MOD) === -1
    accEliminar = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_PROF_ELI) === -1
  }
  const poolName = sesion.orcl_host

  // Extrae los datos de la empresa (mysql)
  const userEmpConfig = encryptStorage.getItem('config')

  function ctrlClickSiguiente (e) {
    // e.preventDefault()
    if (sesion.proformas.index < totalRegs - 1) {
      props.devuelveSiguiente()
    }
  }
  function ctrlClick10Sig (e) {
    // e.preventDefault()
    if (sesion.proformas.index < totalRegs - 10) {
      props.devuelve10Sig()
    }
  }
  function ctrlClickUltima (e) {
    // e.preventDefault()
    if (sesion.proformas.index !== totalRegs - 1) {
      props.devuelveUltima()
    }
  }

  function ctrlClickAnterior (e) {
    // e.preventDefault()
    if (sesion.proformas.index > 0) {
      // console.log(totalRegs, sesion)
      props.devuelveAnterior()
    }
  }
  function ctrlClick10Ant (e) {
    // e.preventDefault()
    if (sesion.proformas.index > 9) {
      // console.log(totalRegs, sesion)
      props.devuelve10Ant()
    }
  }
  function ctrlClickPrimera (e) {
    // e.preventDefault()
    if (sesion.proformas.index !== 0) {
      props.devuelvePrimera()
    }
  }

  function ctrlClickNuevoReg (e) {
    // e.preventDefault()
    const maxNuevas = userEmpConfig.numeros_maximos ? userEmpConfig.numeros_maximos.profNuevas : 5
    if (totalRegs >= maxNuevas) {
      Swal.fire(
        'NO ES POSIBLE CREAR MAS PROFORMAS NUEVAS',
        `El número máximo de proformas nuevas permitido es ${maxNuevas}`,
        'info'
      )
      return
    }
    async function insNuevoReg () {
      setGrabando(true)
      props.estaGrabando(true)
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CREAR UNA NUEVA PROFORMA',
        {
          method: 'post',
          url: '/proformas/nuevas',
          data: { poolName, orclUserVSAM: sesion.orcl_usuarioVSAM, noEmp: sesion.no_empresaVSAM, noUser: sesion.no_usuarioVSAM }
        }
      )
      setGrabando(false)
      props.estaGrabando(false)
      if (respuestaAPI.status === 200) {
        const nSesion = nuevaSesion(false, sesion,
          {
            proformas: {
              clave: respuestaAPI.data[0].NO_REG_CREADO,
              index: respuestaAPI.data[0].CONT_REGS - 1,
              modificando: sesion.proformas.modificando,
              filtrando: sesion.proformas.filtrando
            }
          })
        setSesion(nSesion)
      }
    }
    insNuevoReg()
  }
  function ctrlClickEliminaReg (e) {
    // e.preventDefault()
    async function deleteReg () {
      setGrabando(true)
      props.estaGrabando(true)
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ELIMINAR EL REGISTRO',
        {
          method: 'delete',
          url: `/proformas/nuevas/${sesion.proformas.clave}`,
          data: { poolName }
        }
      )
      setGrabando(false)
      props.estaGrabando(false)
      if (respuestaAPI.status === 200) {
        const nuevoIndex = sesion.proformas.index === 0 ? 0 : sesion.proformas.index - 1
        // console.log('nuevoIndex = ' + nuevoIndex)
        const nSesion = nuevaSesion(false, sesion,
          {
            proformas: {
              clave: -1,
              index: nuevoIndex,
              modificando: sesion.proformas.modificando,
              filtrando: sesion.proformas.filtrando
            }
          })
        setSesion(nSesion)
      }
    }
    Swal.fire({
      title: 'CONFIRME',
      text: `Desea eliminar el registro ${sesion.proformas.index + 1}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'SI',
      cancelButtonText: 'NO'
    }).then((result) => {
      if (result.isConfirmed) deleteReg()
    })
  }
  function ctrlClickGrabar (e) {
    // e.preventDefault()
    if (props.profValida()) {
      async function grabaProforma () {
        setGrabando(true)
        props.estaGrabando(true)
        const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE ${modificando ? 'MODIFICAR' : 'GRABAR'} LA PROFORMA`,
          {
            method: 'post',
            url: modificando ? '/proformas/modifica' : '/proformas/crea',
            data: {
              poolName,
              orclUserVSAM: sesion.orcl_usuarioVSAM,
              noEmp: sesion.no_empresaVSAM,
              noUser: sesion.no_usuarioVSAM,
              noReg: sesion.proformas.clave,
              noProforma: regActual.NO_PROFORMA,
              noProfMax: userEmpConfig.numeros_maximos.proforma
            }
          }
        )
        setGrabando(false)
        props.estaGrabando(false)
        if (respuestaAPI.status === 200) {
          const nuevoNoProf = respuestaAPI.data.length > 0 ? respuestaAPI.data[0].NO_PROFORMA : regActual.NO_PROFORMA
          Swal.fire(
            `LA PROFORMA SE ${modificando ? 'MODIFICO' : 'GRABO'} CON EXITO`,
            regActual.NO_PROFORMA === nuevoNoProf
              ? ''
              : `El sistema grabó la proforma con el No. ${nuevoNoProf}`,
            'success'
          )
          if (modificando) {
            // No se pudo recuperar la nueva proforma modificada
            if (respuestaAPI.data.length === 0) {
              const nSesion = nuevaSesion(false, sesion,
                {
                  proformas: { clave: 0, index: 0, modificando: false, filtrando: true }
                })
              setSesion(nSesion)
            // Si se pudo recuperar la nueva proforma modificada y envía a existentes para verla
            } else {
              props.devuelveDatDoc(respuestaAPI.data, true, true)
              const nSesion = nuevaSesion(false, sesion,
                {
                  proformas: { clave: regActual.NO_PROFORMA, index: 0, modificando: false, filtrando: false }
                })
              setSesion(nSesion)
              navigate('/proformas/existentes')
            }
          } else props.devuelveDatDoc(respuestaAPI.data, false, true)
        } else {
          if (respuestaAPI.response.status === 500 && respuestaAPI.response.data.yaExiste) {
            Swal.fire(
              'LA PROFORMA YA EXISTE',
              'Cambie el número de proforma y vuelva a intentarlo',
              'info'
            )
          }
        }
      }
      Swal.fire({
        title: 'CONFIRME',
        text: 'Los datos ingresados son correctos?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'SI',
        cancelButtonText: 'NO'
      }).then((result) => {
        if (result.isConfirmed) grabaProforma()
      })
    }
  }
  async function ctrClickModificar (e) {
    // e.preventDefault()
    setGrabando(true)
    props.estaGrabando(true)
    // console.log({poolName, orclUserVSAM: sesion.orcl_usuarioVSAM, noEmp: sesion.no_empresaVSAM
    //  , noUser: sesion.no_usuarioVSAM, noProforma: props.children})
    const respuestaAPI = await restAPIAxios('NO FUE POSIBLE PREPARAR LA PROFORMA PARA SU MODIFICACION',
      {
        method: 'post',
        url: '/proformas/preModifica',
        data: {
          poolName,
          orclUserVSAM: sesion.orcl_usuarioVSAM,
          noEmp: sesion.no_empresaVSAM,
          noUser: sesion.no_usuarioVSAM,
          noProforma: props.children
        }
      }
    )
    setGrabando(false)
    props.estaGrabando(false)
    // console.log(respuestaAPI)
    if (respuestaAPI.status === 200) {
      // Coloca la clave devuelta (NO_REG) del registro creado para la modificación
      const claveProf = respuestaAPI.data[0]
      const nSesion = nuevaSesion(false, sesion,
        {
          proformas: {
            clave: claveProf,
            index: sesion.proformas.index,
            modificando: true,
            filtrando: sesion.proformas.filtrando
          }
        })
      setSesion(nSesion)
      navigate('/proformas/nuevas')
    } else {
      // console.log(respuestaAPI.response.status, respuestaAPI.response.data)
      if (respuestaAPI.response.status === 500 && respuestaAPI.response.data.yaExiste) {
        Swal.fire(
          'ESTA PROFORMA YA ESTA EN PROCESO DE MODIFICACION',
          'Primero cancele la modificación pendiente',
          'info'
        )
      }
    }
  }
  function ctrlClickFiltrando (e) {
    // e.preventDefault()
    props.devuelveNuevoFiltro()
  }
  function ctrlClickCancelar (e) {
    // e.preventDefault()
    const modificando = sesion.proformas.modificando
    async function cancela () {
      setGrabando(true)
      props.estaGrabando(true)
      const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE
        ${modificando ? ' CANCELAR LA MODIFICACION' : ' ELIMINAR LOS REGISTROS'}`,
      {
        method: 'post',
        url: `${modificando ? '/proformas/cancelaMod' : '/proformas/nueva/cancela'}`,
        data: modificando
          ? {
              poolName,
              orclUserVSAM: sesion.orcl_usuarioVSAM,
              noReg: sesion.proformas.clave,
              noProforma: regActual.NO_PROFORMA_MOD
            }
          : {
              poolName,
              orclUserVSAM: sesion.orcl_usuarioVSAM,
              noEmp: sesion.no_empresaVSAM,
              noReg: sesion.proformas.clave
            }
      }
      )
      setGrabando(false)
      props.estaGrabando(false)
      if (respuestaAPI.status === 200) {
        if (modificando) {
          const nSesion = nuevaSesion(false, sesion,
            {
              proformas: { clave: 0, index: 0, modificando: true, filtrando: sesion.proformas.filtrando }
            })
          setSesion(nSesion)
        } else props.recargarCab(regActual.NO_PROFORMA)
      }
    }
    Swal.fire({
      title: 'CONFIRME',
      text: modificando
        ? 'Desea cancelar el proceso de modificación de la proforma?'
        : 'Desea eliminar todos los registros de la proforma?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'SI',
      cancelButtonText: 'NO'
    }).then((result) => {
      if (result.isConfirmed) cancela()
    })
  }
  function ctrlClickListar (e) {
    // e.preventDefault()
    props.devuelveListar()
  }

  const modificando = sesion.proformas.modificando
  const fmtoBotsImg = gcFmtosBotones.conImagen
  const widthBots = gcFmtosBotones.imgWidth

  if (!sesion.auth || !userVSAM) return null

  return (
    <Container className=''>
      <Row
        className='justify-content-around bg-primary-subtle rounded'
        style={{ height: 32 }}
      >
        {/* LADO IZQUIERDO DE LA FILA DE BOTONES */}
        <Col className='' xs='auto'>
          <OverlayTrigger overlay={<Tooltip>{existentes ? 'nuevo filtro' : 'existentes'}</Tooltip>}>
            <Button
              className={fmtoBotsImg} size='sm' disabled={grabando}
              onClick={ctrlClickFiltrando}
            ><img src={imgFiltrar} alt='Filtrar' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>modificar</Tooltip>}>
            <Button
              className={fmtoBotsImg} size='sm' disabled={grabando}
              hidden={!accModificar || !existentes || regActual.ESTADO === 'A'}
              onClick={ctrClickModificar}
            ><img src={imgModificar} alt='modificar' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>{modificando ? 'grabar modificación' : 'grabar'}</Tooltip>}>
            <Button
              className={fmtoBotsImg} size='sm' disabled={grabando} hidden={!accCejaProf || existentes}
              onClick={ctrlClickGrabar}
            ><img src={imgGrabar} alt='grabar' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>listado</Tooltip>}>
            <Button
              className={fmtoBotsImg} size='sm' hidden={!existentes}
              onClick={ctrlClickListar}
            ><img src={imgListado} alt='Listado' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>Email Cliente</Tooltip>}>
            <Button
              className={fmtoBotsImg} size='sm' hidden={!existentes}
              onClick={(e) => props.devuelveRepIndividual('CLIENTE', true)}
            ><img src={imgMail} alt='Email Cliente' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>imprimir</Tooltip>}>
            <SplitButton
              className={gcFmtosBotones.conImagenMenu} variant='' size='sm'
              disabled={grabando} hidden={!existentes}
              title={(<img src={imgImprimir} alt='imprimir' width={widthBots} />)}
              onClick={(e) => props.devuelveRepIndividual('CLIENTE')}
            >
              <Dropdown.Item
                className={gcFmtosBotones.dropDownItem}
                onClick={(e) => props.devuelveRepIndividual('CLIENTE')}
              >Cliente
              </Dropdown.Item>
              <Dropdown.Item
                className={gcFmtosBotones.dropDownItem}
                onClick={(e) => props.devuelveRepIndividual('INTERNO')}
              >Interno
              </Dropdown.Item>
            </SplitButton>

            {/* <Button
              className={fmtoBotsImg} size='sm' disabled={grabando} hidden={!existentes}
              onClick={(e) => { props.devuelveRepIndividual() }}
            ><img src={imgImprimir} alt='imprimir' width={widthBots} />
            </Button> */}
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>{modificando ? 'cancelar modificación' : 'cancelar'}</Tooltip>}>
            <Button
              className={fmtoBotsImg}
              size='sm'
              disabled={grabando}
              hidden={existentes}
              onClick={ctrlClickCancelar}
            ><img src={imgCancelar} alt='cancelar' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>nueva proforma</Tooltip>}>
            <Button
              className={fmtoBotsImg}
              size='sm'
              disabled={grabando}
              hidden={!accCejaProf || existentes || modificando}
              onClick={ctrlClickNuevoReg}
            ><img src={imgMas} alt='crear' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>elimina proforma {existentes ? props.children : sesion.proformas.index + 1}</Tooltip>}>
            <Button
              className={fmtoBotsImg}
              size='sm'
              disabled={grabando || totalRegs <= 1}
              hidden={(existentes && !accEliminar) || (existentes && regActual.ESTADO !== 'A') || modificando}
              onClick={ctrlClickEliminaReg}
            ><img src={imgMenos} alt='eliminar' width={widthBots} />
            </Button>
          </OverlayTrigger>
        </Col>

        {/* TEXTO EN LA MITAD DE LA FILA DE BOTONES */}
        <Col xs='auto'>
          <Form.Label className='fs-6'>{sesion.proformas.index + 1}/{totalRegs}</Form.Label>
        </Col>

        {/* LADO DERECHO DE LA FILA DE BOTONES */}
        <Col className='' xs='auto'>
          <OverlayTrigger overlay={<Tooltip>primera proforma</Tooltip>}>
            <Button
              className={fmtoBotsImg}
              size='sm'
              disabled={grabando || editando || sesion.proformas.index === 0}
              onClick={ctrlClickPrimera}
            ><img src={imgPrimero} alt='primero' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>10 anteriores</Tooltip>}>
            <Button
              className={fmtoBotsImg}
              size='sm'
              disabled={grabando || editando || sesion.proformas.index <= 9}
              onClick={ctrlClick10Ant}
            ><img src={imgAnterior} alt='anterior' width={widthBots} />
              <img src={imgAnterior} alt='anterior' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>proforma anterior</Tooltip>}>
            <Button
              className={fmtoBotsImg}
              size='sm'
              disabled={grabando || editando || sesion.proformas.index === 0}
              onClick={ctrlClickAnterior}
            ><img src={imgAnterior} alt='anterior' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>proforma siguiente</Tooltip>}>
            <Button
              className={fmtoBotsImg}
              size='sm'
              disabled={grabando || editando || sesion.proformas.index === totalRegs - 1}
              onClick={ctrlClickSiguiente}
            ><img src={imgSiguiente} alt='siguiente' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>10 siguientes</Tooltip>}>
            <Button
              className={fmtoBotsImg}
              size='sm'
              disabled={grabando || editando || sesion.proformas.index > totalRegs - 11}
              onClick={ctrlClick10Sig}
            ><img src={imgSiguiente} alt='siguiente' width={widthBots} />
              <img src={imgSiguiente} alt='siguiente' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>última proforma</Tooltip>}>
            <Button
              className={fmtoBotsImg}
              size='sm'
              disabled={grabando || editando || sesion.proformas.index === totalRegs - 1}
              onClick={ctrlClickUltima}
            ><img src={imgUltimo} alt='ultimo' width={widthBots} />
            </Button>
          </OverlayTrigger>
        </Col>
      </Row>
    </Container>
  )
}

export default Botones
