import { useEffect, useContext } from 'react'

import { SesionContext, nuevaSesion } from '../context/SesionContext.jsx'
import { gcTiposDoc } from '../utils/generales.js'

export function useForzaFiltroEnRefresh ({ tipoDoc, setListando }) {
  const [sesion, setSesion] = useContext(SesionContext)
  // Solo para pasar al Filtrando cuando se refresca la pantalla en /facturas/existentes
  // ya que al refrescarla directamente desde windows se queda cargando datos indefinidamente
  // porque al refrescar la pantalla se pierde datFact. Por lo tanto hay que volver al filtrado de datos
  let url = ''
  let sesionDoc
  switch (tipoDoc) {
    case gcTiposDoc.fact: {
      url = '/facturas/existentes'
      sesionDoc = sesion.facturas
      break
    }
    case gcTiposDoc.prof: {
      url = '/proformas/existentes'
      sesionDoc = sesion.proformas
      break
    }
    default: url = ''
      break
  }
  useEffect(() => {
    if (document.readyState === 'interactive') {
      if (window.location.pathname === url && !sesionDoc.filtrando) {
        setListando(false)
        const nSesion = nuevaSesion(false, sesion,
          url === '/proformas/existentes'
            ? { proformas: { ...sesionDoc, filtrando: true } }
            : { facturas: { ...sesionDoc, filtrando: true } }
        )
        setSesion(nSesion)
      }
    }
    // Captura los eventos de teclas para reemplazar el atributo ACCESSKEY de html
    // document.addEventListener('keydown', colocaFoco, true)
  }, [])
  // No van dependencias en este useEffect porque se debe ejecutar una sola vez al cargar la pantalla
}

// Captura el evento resize de la pantalla, para conocer si es menor a 1200
export function useWAncho1200 (setWAncho1200) {
  useEffect(() => {
    function ctrlWindowResize () {
      setWAncho1200(window.innerWidth < 1200)
    }
    window.addEventListener('resize', ctrlWindowResize)
    return () => window.removeEventListener('resize', ctrlWindowResize)
  }, [])
}
