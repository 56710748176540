import { useState } from 'react'
import { Form } from 'react-bootstrap'

function FPsSRI (props) {
  const [valFP, setValFP] = useState(props.children)

  // FUNCIONES EVENTOS CLICK DE LOS CAMPOS
  function ctrlChangeSRIFP (e) {
    // e.preventDefault()
    const index = e.target.options.selectedIndex
    const descrip = e.target.options[index].innerHTML
    setValFP(e.target.value)
    props.devuelveDatos({ nuevoVal: e.target.value, index, descrip })
  }

  return (
    <Form.Select
      className='ms-1 mb-1' style={{ maxWidth: 410 }}
      value={valFP}
      onChange={ctrlChangeSRIFP}
    >
      <option value='01'>01. SIN UTILIZAR SIST. FINANCIERO</option>
      <option value='15'>15. COMPENSACIÓN DE DEUDAS</option>
      <option value='16'>16. TARJETA DE DEBITO</option>
      <option value='17'>17. DINERO ELECTRONICO</option>
      <option value='18'>18. TARJETA PREPAGO</option>
      <option value='19'>19. TARJETA DE CREDITO</option>
      <option value='20'>20. OTROS CON UTILIZACION DEL SISTEMA FINANCIERO</option>
      <option value='21'>21. ENDOSO DE TITULOS</option>
    </Form.Select>
  )
}

export default FPsSRI
