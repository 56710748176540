import { memo, useContext } from 'react'

import Card from 'react-bootstrap/Card'

import { SesionContext } from '../../context/SesionContext'
import { useVerificaToken } from '../../hooks/admin.jsx'

function Bienvenida () {
  const [sesion] = useContext(SesionContext)

  // VERIFICA TOKEN
  // ** Debe ir antes de cualquier uso de sesion porque aqui se puede recargar los valores de sesion del local storage
  useVerificaToken()

  if (!sesion.auth) return null

  return (
    <div className='d-flex justify-content-center m-4'>
      <Card
        className='text-center'
        style={{ width: 400 }}
      >
        <Card.Header className='bg-primary-subtle pt-3 pb-0 fw-bold'>SISTEMA ADMINISTRATIVO-CONTABLE
          <p>
            <span className='h6 text-black'>Visual SAM</span>
            <span className='h6 fst-italic text-primary'> Web</span>
          </p>
        </Card.Header>
        <Card.Title className='m-3 fs-2'>Bienvenido</Card.Title>
      </Card>
    </div>
  )
}

export default memo(Bienvenida)
