/* eslint-disable react/jsx-indent */
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import { Button, Card, Col, Container, Form, InputGroup, Row, Spinner } from 'react-bootstrap'

import { nuevaSesion, useSesion } from '../../../context/SesionContext.jsx'
import restAPIAxios, { restAPIMySQL } from '../../../utils/axios.js'
import encryptStorage from '../../../utils/encryptStorage.js'
import { existeNoProf, factConStockNegativo } from '../../../utils/bd.js'
import {
  cargaSeries, fechaRestaFechas, fechaSumaDias, numberFormat, infoPtoVtaSel,
  infoBodegaSel, infoVendedorSel, setInfoCampoFactura, gcTiposDoc,
  gcTablasLogicas, gcModulos, rutaReps
} from '../../../utils/generales.js'

import Encabezado from './FactEncabezado.jsx'
import EmpSel from '../../admin/EmpSel.jsx'
import Botones from './FactBotones.jsx'

import FPsFactSimple from '../FPsFactSimple.jsx'
import ListaExistentes from './FactListaExistentes.jsx'
import BarraAuditoria from '../../generales/BarraAuditoria.jsx'
import BarraSRI from '../../generales/BarraSRI.jsx'

// import TablaFiltro from '../generales/TablaFiltro.jsx'
import Clientes from '../clientes/Clientes.jsx'
import TablaOpcion from '../../generales/TablaOpcion.jsx'
import TablaCampo from '../../generales/TablaCampo.jsx'
import TablaCampoFecha from '../../generales/TablaCampoFecha.jsx'
import NoFactura from '../NoFactura.jsx'
import CampoNumerico from '../../generales/CampoNumerico.jsx'
import ItemsTransacciones from '../../productos/items/ItemsTransacciones.jsx'

import { useGetParamsEmp, useVerificaToken } from '../../../hooks/admin.jsx'
import { useForzaFiltroEnRefresh, useWAncho1200 } from '../../../hooks/generales.jsx'

function Facturas ({ existentes }) {
  const navigate = useNavigate()
  const [sesion, setSesion] = useSesion()

  const [datFact, setDatFact] = useState([])
  const [datDetalles, setDatDetalles] = useState([])
  const [datTotales, setDatTotales] = useState({
    subtotal: 0,
    totDsctoItems: 0,
    totIVA0: 0,
    pDsctoAdi: 0,
    vDsctoAdi: 0,
    vImponible: 0,
    pIVA: 0,
    vIVA: 0,
    totNeto: 0
  })
  const datosFPVacia = {
    vEfectivo: 0,
    vCambio: 0,
    vCredito: 0,
    vCheque: 0,
    chNo: 0,
    chBco: '',
    chCta: '',
    vTarjeta: 0,
    tarjNoInstit: 0,
    tarjDescrip: '',
    tarjNo: '',
    sriFP: '20',
    sinAsignar: true
  }
  const [datosFPs, setDatosFPs] = useState(datosFPVacia)

  const [noBodega, setNoBodega] = useState(null)
  const [diasCre, setDiasCre] = useState(0)
  const [esNC, setEsNC] = useState(false)
  const [tDocFiltrando, setTDocFiltrando] = useState('todos')

  const [whereExist, setWhereExist] = useState('')
  const [ordenExist, setOrdenExist] = useState('NO_FACTURA')
  const [fechaCond, setFechaCond] = useState('=')

  const [listando, setListando] = useState(false)
  const [grabando, setGrabando] = useState({ enProceso: false, msjPantalla: '' })
  const [cargando, setCargando] = useState(false)
  const [editando, setEditando] = useState(false)
  const [recargar, setRecargar] = useState(false) // Bandera para recargar la cabecera
  const [enEspera, setEnEspera] = useState(false)
  const [fmtoWarningNoProf, setFmtoWarningNoProf] = useState(false)
  const [ocultaFP, setOcultaFP] = useState(true)

  const [wAncho1200, setWAncho1200] = useState(window.innerWidth < 1200)

  const esElect = useRef(false)
  const datExistentes = useRef([])

  // VERIFICA TOKEN
  // ** Debe ir antes de cualquier uso de sesion porque aqui se puede recargar los valores de sesion del local storage
  useVerificaToken()

  // Asigna variables generales
  let poolName = ''
  if (sesion.auth) {
    poolName = sesion.orcl_host
  }
  const noMinRegsListado = 10

  // Asigna las variables que extrae de los datos de la empresa (mysql)
  const userEmpConfig = encryptStorage.getItem('config')
  let serieFact = ''
  let pDsctoMax = 100
  if (userEmpConfig) {
    serieFact = userEmpConfig.series.fact
    pDsctoMax = userEmpConfig.p_dscto_max
  }
  const modificando = sesion.facturas.modificando
  const filtrando = sesion.facturas.filtrando

  // Controla el nivel de acceso al menú de Facturas y asigna variables de acceso
  let accModNo = false
  let accModFecha = false
  let accModFKardex = false
  let accSinStock = false
  let accDiasCre = false
  let accCambiaPMinUtil = false
  let accFormasPago = false
  const userVSAM = encryptStorage.getItem('userVSAM')
  if (userVSAM) {
    // Asigna si tiene acceso a todo el botón (menú Facturación y opción Ventas)
    const accMnuFact = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_FACTURACION) === -1
    const accMnuVtas = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_VENTAS) === -1

    if (!accMnuFact || !accMnuVtas) {
      Swal.fire(
        'NO TIENE ACCESO A FACTURAR',
        'Intente acceder a otras páginas utilizando las opciones de menú disponibles',
        'info'
      )
      navigate('/bienvenida')
    }
    // Variables de Acceso
    accModNo = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_MOD_NO) === -1
    accModFecha = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_MOD_F) === -1
    accModFKardex = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_MOD_FKX) === -1
    accSinStock = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_SIN_STOCK) === -1
    accDiasCre = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_DIAS_CRE) === -1
    accCambiaPMinUtil = userVSAM.RESTRICC_ACC_PARAM.indexOf(process.env.REACT_APP_ACC_CAMBIA_PMIN_UTIL) >= 0
    accFormasPago = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_FORMAS_PAGO) === -1
  }

  let maxValSubTot = 0
  let maxFactsMenXCliDscto = 0
  let lFactElectronica = -1
  let fCerradoHasta = ''
  // Carga los parametros de la empresa
  const paramsEmp = useGetParamsEmp()
  if (paramsEmp) {
    maxValSubTot = paramsEmp.MAX_VXFACT_MEN_X_CLI_CON_DSCTO
    maxFactsMenXCliDscto = paramsEmp.MAX_FACT_MEN_X_CLI_CON_DSCTO
    lFactElectronica = paramsEmp.L_FACT_ELECTRONICA
    fCerradoHasta = paramsEmp.F_CERRADOHASTA === null ? '' : paramsEmp.F_CERRADOHASTA.substring(0, 10)
  }

  // Solo para pasar al Filtrando cuando se refresca la pantalla en /facturas/existentes
  // ya que al refrescarla directamente desde windows se queda cargando datos indefinidamente
  // porque al refrescar la pantalla se pierde datFact. Por lo tanto hay que volver al filtrado de datos
  useForzaFiltroEnRefresh({ tipoDoc: gcTiposDoc.fact, setListando })
  // Captura el evento resize de la pantalla, para conocer si es menor a 1200
  // y así poder reorganizar el No. de Factura y de Proforma o NC
  useWAncho1200(setWAncho1200)

  // Carga las Facturas NUEVAS (del usuario y la empresa respectivos)
  // o Carga Cabecera Facturas vacio para filtro
  useEffect(() => {
    console.log('🚀 ~ file: Facturas.jsx ~ useEffect ~ existentes, modificando:', 'Cargando Facturas...', existentes, modificando)
    async function cargaFactNuevas () {
      setListando(false)
      // Toma la primera serie por si acaso necesite crear el nuevo registro
      let serie
      // Carga todas las series válidas para el usuario y empresa
      serie = cargaSeries(gcTiposDoc.fact, paramsEmp, userEmpConfig.series)[0]
      // Escoge la primera serie de la lista
      serie = 'A' + serie[0].split(',')[0]
      const respuestaAPI = await restAPIAxios(`NO SE PUDO CARGAR
                ${modificando ? ' LA FACTURA PARA SU MODIFICACION' : ' LAS FACTURAS NUEVAS'}`,
      {
        method: 'get',
        url: `/facturas/nuevas/${poolName}/${sesion.orcl_usuarioVSAM}/${sesion.no_usuarioVSAM}/${sesion.no_empresaVSAM}/${serie}/${modificando ? 'SI' : 'NO'}`
      }
      )
      if (respuestaAPI.status === 200) {
        setDatFact(respuestaAPI.data)
        if (respuestaAPI.data.length > 0) {
          let indexFact
          let claveFact
          if (modificando) {
            // Se coloca en la factura que desea modificar (por si acaso haya mas registros para modificar)
            indexFact = respuestaAPI.data.findIndex(registro => {
              return (registro.NO_REG === sesion.facturas.clave)
            })
            if (indexFact === -1) indexFact = 0
            claveFact = respuestaAPI.data[indexFact].NO_REG
          } else {
            // Verifica que el índice de sesión sea válido
            indexFact = respuestaAPI.data.length - 1 < sesion.facturas.index ? 0 : sesion.facturas.index
            claveFact = respuestaAPI.data[indexFact].NO_REG
          }
          seteaVarState(respuestaAPI.data[indexFact])
          if (sesion.facturas.index !== indexFact || sesion.facturas.clave !== claveFact) {
            const nSesion = nuevaSesion(false, sesion,
              { facturas: { clave: claveFact, index: indexFact, modificando, filtrando } })
            setSesion(nSesion)
          }
        } else {
          const nSesion = nuevaSesion(false, sesion,
            {
              facturas: { clave: 0, index: 0, modificando: false, filtrando: true }
            })
          setSesion(nSesion)
          Swal.fire(
            'NO EXISTEN FACTURAS EN PROCESO DE MODIFICACION',
            'Seleccione una factura para su modificación',
            'info'
          )
          navigate('/facturas/existentes')
        }

        // En caso de que la API devuelva error
      } else {
        navigate('/bienvenida')
      }
    }

    function setBuscarExistentes () {
      if (datExistentes.current.length > 0) {
        setDatFact(datExistentes.current)
        seteaVarState(datExistentes.current[0])
        const nSesion = nuevaSesion(false, sesion,
          {
            facturas: {
              clave: datExistentes.current[0].NO_FACTURA,
              index: 0,
              modificando: false,
              filtrando: false
            }
          })
        setSesion(nSesion)
        setListando(false)
      } else {
        setListando(false)
        const regVacio = {
          CLI_NOMBRE: '',
          NO_PTOVENTA: 0,
          NO_BODEGA: 0,
          NO_CLIENTE: '',
          NO_VENDEDOR: '',
          OBS: '',
          NO_FACTURA: esNC ? 'B000-000-' : 'A000-000-',
          NO_PROFORMA: '',
          NO_FACTURA_NC: '',
          FECHA: '',
          F_VCMTO: '',
          F_KARDEX: '',
          NO_FACTURA_MOD: '',
          SRI_AUTORIZACION: null,
          SRI_CLAVE: null,
          SRI_MENSAJE: null
        }
        setDatFact([regVacio])
        seteaVarState(regVacio)
        if (sesion.facturas.index !== 0 || sesion.facturas.clave !== 0) {
          const nSesion = nuevaSesion(false, sesion,
            {
              facturas: { clave: 0, index: 0, modificando: false, filtrando: true }
            })
          setSesion(nSesion)
        }
      }
    }

    if (filtrando) setBuscarExistentes()
    else if (!existentes || modificando) cargaFactNuevas()
  }, [esNC, existentes, modificando, filtrando, lFactElectronica, sesion, serieFact, recargar])
  // ** No hace falta que vaya userEmpConfig, ya que se cargan en su propio Custom Hook y solo se usan para pasar como parámetro
  // ** No hace falta que vaya paramsEmp, ya que se cargan en su propio Custom Hook y solo se usan para pasar como parámetro
  // , pero evita que se ejecute una vez más el useEffect

  // FUNCIONES PRIVADAS DEL COMPONENTE
  // Valida los datos de la Factura
  async function factValida () {
    // Verifica que no grabe items con Valores de Descuentos Negativos e Incorrectos
    async function itemsConDsctosNegativos () {
      setEnEspera(true)
      const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE VALIDAR ITEMS CON DSCTOS. NEGATIVOS EN LA VENTA ${sesion.facturas.clave}`,
        {
          method: 'get',
          url: `/facturas/nueva/dsctosNegativos/${poolName}/${sesion.orcl_usuarioVSAM}/${sesion.facturas.clave}`
        }
      )
      setEnEspera(false)
      if (respuestaAPI.status === 200) return (respuestaAPI.data)
      else return -1
    }
    // Verifica que no grabe items con pctjes. de utilidad menores al mínimo requerido
    async function itemsConPUtilBajoPMin () {
      setEnEspera(true)
      const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE VALIDAR ITEMS CON UTILIDAD MENOR AL MINIMO REQUERIDO EN LA VENTA ${sesion.facturas.clave}`,
        {
          method: 'get',
          url: `/facturas/nueva/PUtilMenorPMin/${poolName}/${sesion.orcl_usuarioVSAM}/${sesion.facturas.clave}/${datTotales.pDsctoAdi}`
        }
      )
      setEnEspera(false)
      if (respuestaAPI.status === 200) return (respuestaAPI.data)
      else return -1
    }
    // Verifica el control de Número Máximo mensual de Ventas por Cliente con Dscto.
    async function getFactsXCliMenMayorA () {
      setEnEspera(true)
      const noCliente = datFact[filtrando ? 0 : sesion.facturas.index].NO_CLIENTE
      const fecha = datFact[filtrando ? 0 : sesion.facturas.index].FECHA
      const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE capturar las facturas con subtotal mayor a $65 para el cliente No. ${noCliente} del mes ${fecha.substring(0, 7)}`,
        {
          method: 'get',
          url: `/facturas/xCliMenMayorA/${poolName}/${sesion.orcl_usuarioVSAM}/${sesion.no_empresaVSAM}/${noCliente}/${fecha}/${maxValSubTot}`
        }
      )
      setEnEspera(false)
      if (respuestaAPI.status === 200) return (respuestaAPI.data)
      else return -1
    }
    // Verifica el control de Número Máximo mensual de Ventas por Cliente con Dscto.
    async function getTieneMultFPs (noFactura) {
      setEnEspera(true)
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE DETERMINAR SI LA FACTURA TIENE O NO COBROS MULTIPLES',
        {
          method: 'get',
          url: `/facturas/tieneMultFPs/${poolName}/${sesion.orcl_usuarioVSAM}/${sesion.no_empresaVSAM}/${noFactura}`
        }
      )
      setEnEspera(false)
      if (respuestaAPI.status === 200) {
        if (respuestaAPI.data.length > 0) return respuestaAPI.data[0].CUANTASFPS > 0
        else return false
      } else return -1
    }

    const noCliente = datFact[filtrando ? 0 : sesion.facturas.index].NO_CLIENTE
    const noFactNC = datFact[filtrando ? 0 : sesion.facturas.index].NO_FACTURA_NC
    // const vTotal = datFact[filtrando?0:sesion.facturas.index].V_TOTAL
    const vTotal = datTotales.totNeto
    const pDsctoOrg = Math.round(datFact[filtrando ? 0 : sesion.facturas.index].P_DSCTO * 100, 4)

    // VERIFICACIONES GENERALES
    // Verifica la FECHA DE CIERRE para FECHA y F_KARDEX
    if (fCerradoHasta >= datFact[sesion.facturas.index].FECHA.substring(0, 10)) {
      Swal.fire(
        `NO PUEDE ${modificando ? 'MODIFICAR' : 'CREAR'} ${esNC ? 'NOTAS DE CREDITO' : 'FACTURAS'} ANTERIORES A ` +
          fCerradoHasta,
        `Cambie la fecha de la ${esNC ? 'nta.credito' : 'factura'} por una superior a la indicada`,
        'info'
      )
      return false
    }
    if (fCerradoHasta >= datFact[sesion.facturas.index].F_KARDEX.substring(0, 10)) {
      Swal.fire(
        `NO PUEDE ${modificando ? 'MODIFICAR' : 'CREAR'} ${esNC ? 'NOTAS DE CREDITO' : 'FACTURAS'} ANTERIORES A ` +
          fCerradoHasta,
        'Cambie la fecha de kardex por una superior a la indicada',
        'info'
      )
      return false
    }
    // Verifica que EXISTA EL CLIENTE
    if (noCliente === null || noCliente === undefined) {
      Swal.fire(
        `NO PUEDE ${modificando ? 'MODIFICAR' : 'CREAR'} ${esNC ? 'NOTAS DE CREDITO' : 'FACTURAS'} SIN ASIGNAR UN CLIENTE`,
        `Asigne un Cliente y vuelva a intentar grabar la ${esNC ? 'nota de crédito' : 'factura'}`,
        'info'
      )
      return false
    }
    // Verifica que EXISTAN ITEMS
    if (datDetalles.length === 0) {
      Swal.fire(
        `NO PUEDE ${modificando ? 'MODIFICAR' : 'CREAR'} ${esNC ? 'NOTAS DE CREDITO' : 'FACTURAS'} SIN ITEMS`,
        `Cree por lo menos un item en la ${esNC ? 'nota de crédito' : 'factura'} antes de grabarla`,
        'info'
      )
      return false
    }
    // Verifica que el %DSCTO no sea mayor al permitido en nuevas.
    // y en modificación, solo si se cambió el porcentaje
    if (modificando
      ? (datTotales.pDsctoAdi !== pDsctoOrg && datTotales.pDsctoAdi > pDsctoMax)
      : datTotales.pDsctoAdi > pDsctoMax) {
      Swal.fire(
        `NO PUEDE ${modificando ? 'MODIFICAR' : 'CREAR'} ${esNC ? 'NOTAS DE CREDITO' : 'FACTURAS'} CON % DSCTO. MAYOR A ${pDsctoMax}`,
        `Cambie el valor del descuento antes de grabar la ${esNC ? 'nota de crédito' : 'factura'}`,
        'info'
      )
      return false
    }
    // Verifica que no existan items con dsctos. negativos o incorrectos
    const res = await itemsConDsctosNegativos()
    // Hubo error en el API
    if (res === -1) return false
    // Existen registros con dsctos. negativos o incorrectos
    else if (res.length > 0) {
      Swal.fire(
        'NO PUEDEN EXISTIR ITEMS CON DSCTOS. NEGATIVOS',
                `el item No. ${res[0].NO_ITEM_USER} (${res[0].CODIGO}) ${res[0].DESCRIP} del registro No. ${res[0].NO_REG_FACT}
                    , no puede grabar valores con dscto. negativo, ni el precio de venta puede ser menor al valor unitario
                    , ni el %dscto. puede ser mayor al 100%`,
                'info'
      )
      return false
    }

    // VERIFICACIONES NC
    if (esNC) {
      // Verifica que haya un NO_FACTURA relacionado si es una NC
      if (noFactNC === null || noFactNC === '' || noFactNC.substring(0, 7) === '000-000') {
        Swal.fire(
          'DEBE COLOCAR UN NUMERO DE FACTURA RELACIONADO A LA NOTA DE CREDITO',
          '',
          'info'
        )
        return false
      }
      // Verifica que la NC tenga un valor total NEGATIVO
      if (datTotales.totNeto >= 0) {
        Swal.fire(
          'LA NOTA DE CREDITO DEBE TENER UN VALOR TOTAL NEGATIVO',
          'Solo las cantidades de cada item, deben estar en negativo',
          'info'
        )
        return false
      }

      // VERIFICACIONES FACTURA
    } else {
      // GENERALES
      // Verifica que la FACTURA tenga un valor total POSITIVO
      if (datTotales.totNeto < 0) {
        Swal.fire(
          'LA FACTURA DEBE TENER UN VALOR TOTAL POSITIVO',
          '',
          'info'
        )
        return false
      }
      // Verifica que la F_VCMTO sea mayor o igual a la FECHA
      if (datFact[sesion.facturas.index].F_VCMTO.substring(0, 10) <
                datFact[sesion.facturas.index].FECHA.substring(0, 10)) {
        Swal.fire(
          'LA FECHA DE VCMTO. DEBE SER MAYOR O IGUAL A LA FECHA DE LA FACTURA',
          '',
          'info'
        )
        return false
      }
      // SOLO MODIFICADAS
      if (modificando) {
        const noFacturaMod = datFact[filtrando ? 0 : sesion.facturas.index].NO_FACTURA_MOD
        const noClienteMod = datFact[filtrando ? 0 : sesion.facturas.index].NO_CLIENTE_MOD
        const vTotalMod = datFact[filtrando ? 0 : sesion.facturas.index].V_TOTAL_MOD
        // Verifica que no modifique el valor total si tiene Cobros Múltiples
        if (vTotalMod !== vTotal) {
          Swal.fire(
            'NO PUEDE MODIFICAR (TODAVIA) EL VALOR TOTAL DE LA FACTURA EN LA APLICACION WEB',
            'Si requiere hacerlo, por ahora lo debe hacer en el aplicativo de módulos',
            'info'
          )
          return false

          /*  Validación real (cuando ya este funcionando las FPs en Web)
                    const res = await getTieneMultFPs(noFacturaMod)
                    if (res === -1) return false
                    else if (res) {
                        Swal.fire(
                            `NO PUEDE MODIFICAR EL VALOR TOTAL DE LA FACTURA PORQUE TIENE COBROS MULTIPLES`,
                            `La factura ya esta cancelada y no puede modificar su valor total`,
                            'info',
                        );
                        return false
                    }
                    */
        }
        // Verifica que no modifique el cliente si tiene Cobros Múltiples
        if (noClienteMod !== noCliente) {
          const res = await getTieneMultFPs(noFacturaMod)
          if (res === -1) return false
          else if (res) {
            Swal.fire(
              'NO PUEDE MODIFICAR EL CLIENTE DE LA FACTURA PORQUE TIENE COBROS MULTIPLES',
              'Si no anula el cobro múltiple, no va a poder modificar el cliente del documento',
              'info'
            )
            return false
          }
        }
        // SOLO NUEVAS
      } else {
        // Verifica que no grabe items con stock negativo, en caso de que el usuario no tenga el nivel de acceso respectivo
        if (!accSinStock) {
          setEnEspera(true)
          const res = await factConStockNegativo(poolName, sesion.orcl_usuarioVSAM, sesion.facturas.clave, noBodega)
          setEnEspera(false)
          // Hubo error en el API
          if (res === -1) return false
          // Existen registros con valor negativo
          else if (res.length > 0) {
            Swal.fire(
              'NO TIENE ACCESO A FACTURAR CON STOCK NEGATIVO',
              'La factura crearía stock negativo',
              'info'
            )
            return false
          }
        }
        // Verifica que la factura no grabe items con Utilidad menor al % Mínimo permitido,
        // en caso de que no tenga el nivel de acceso correcto
        if (paramsEmp.CTRL_MANEJO_P_MIN_UTIL_X_ITEM > 0 && !accCambiaPMinUtil) {
          const res = await itemsConPUtilBajoPMin()
          // Hubo error en el API
          if (res === -1) return false
          // Existen registros con valor negativo
          else if (res.length > 0) {
            Swal.fire(
              'NO TIENE ACCESO A FACTURAR ITEMS CON PCTJE. DE UTILIDAD MENOR AL MINIMO REQUERIDO',
              `el item No. ${res[0].NO_ITEM_USER} (${res[0].CODIGO}) ${res[0].DESCRIP} del registro No. ${res[0].NO_REG_FACT}
                , tiene una utilidad menor al valor mínimo permitido ${res[0].P_MIN_UTIL}% 
                (costo+utilidad > PV-dsctos --> ${numberFormat(res[0].V_COST_PROM * (1 + (res[0].P_MIN_UTIL / 100)), 2)}
                > ${numberFormat(res[0].VALOR_UNIT * (1 - (datTotales.pDsctoAdi / 100)), 2)})`,
              'info'
            )
            return false
          }
        }
        // Verifica el control de Número Máximo mensual de Ventas por Cliente con Dscto.
        if (maxFactsMenXCliDscto > 0 && datTotales.subtotal > maxValSubTot &&
                        (datTotales.pDsctoAdi > 0 || datTotales.vDsctoAdi > 0)) {
          const res = await getFactsXCliMenMayorA()
          // Hubo error en el API
          if (res === -1) return false
          else if (res.length > 0) {
            // Existen facturas con subtotal > maxValSubTot en el mes respectivo
            if (res[0].CUANTAS > maxFactsMenXCliDscto) {
              Swal.fire(
                'NO PUEDE APLICAR NINGUN DESCUENTO A ESTA FACTURA',
                                `El cliente sobrepasa la cantidad mensual de facturas permitidas (${res[0].CUANTAS} >= ${maxFactsMenXCliDscto})
                                    , con valor mayor o igual a $${maxValSubTot}. El cliente ya no tiene acceso a más dsctos. este mes
                                    , para facturas con subtotal > $${maxValSubTot}`,
                                'info'
              )
              return false
            }
          }
        }
        // Verifica que el Total sea igual al valor ingresado en Formas de Pago
        const totFPs = datosFPs.vEfectivo - datosFPs.vCambio + datosFPs.vCredito + datosFPs.vCheque + datosFPs.vTarjeta
        if (!datosFPs.sinAsignar && numberFormat(totFPs, 2, true) !== numberFormat(vTotal, 2, true)) {
          Swal.fire(
            'LA SUMA DE FORMAS DE PAGO ES DIFERENTE AL TOTAL DE LA FACTURA',
                        `El total debe ser ${numberFormat(vTotal, 2)}, en lugar de ${numberFormat(totFPs, 2)}. ${JSON.stringify(datosFPs)}`,
                        'info'
          )
          return false
        }
        // Verifica que haya escogido una Tarjeta de Crédito si colocó un valor en Tarjeta
        if (!datosFPs.sinAsignar && datosFPs.vTarjeta > 0 && datosFPs.tarjNoInstit === 0) {
          Swal.fire(
            'DEBE ESCOGER UNA TARJETA DE CREDITO PARA EL VALOR INGRESADO',
            '',
            'info'
          )
          return false
        }
      }
      // ** FALTAN RESERVAS DE PROFORMAS Y FACTURAS INDEPENDIENTES DE EGRESOS DE BODEGA
    }
    return true
  }
  async function cargaFactExistentes (where, orden, estaOrdenando) {
    setCargando(true)
    const noFactTmp = datFact[sesion.facturas.index].NO_FACTURA
    const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CARGAR LAS FACTURAS',
      {
        method: 'post',
        url: '/facturas/existentes',
        data: { poolName, orclUserVSAM: sesion.orcl_usuarioVSAM, noEmp: sesion.no_empresaVSAM, where, orden }
      }
    )
    if (respuestaAPI.status === 200) {
      // No existen Facturas con esas condiciones.  Se queda en buscar
      if (respuestaAPI.data.length === 0) {
        Swal.fire(
          'NO EXISTEN FACTURAS CON ESAS CONDICIONES',
          'Cambie las condiciones e intente nuevamente',
          'info'
        )
      } else {
        setListando(existentes && respuestaAPI.data.length > noMinRegsListado && !estaOrdenando)
        setDatFact(respuestaAPI.data)
        datExistentes.current = respuestaAPI.data

        // Verifica que el índice de sesión sea válido
        const indexFactTmp = respuestaAPI.data.findIndex((reg) => reg.NO_FACTURA === noFactTmp)
        const indexFact = estaOrdenando ? (indexFactTmp === -1 ? 0 : indexFactTmp) : 0
        const claveFact = respuestaAPI.data[indexFact].NO_FACTURA
        seteaVarState(respuestaAPI.data[indexFact])

        const nSesion = nuevaSesion(false, sesion,
          {
            facturas: { clave: claveFact, index: indexFact, modificando: false, filtrando: false }
          })
        setSesion(nSesion)
      }
    }
    setCargando(false)
  }
  async function seteaVarState (registro) {
    // Verifica que existan datos en el registro para asignar las variables de estado
    if (registro) {
      setNoBodega(registro.NO_BODEGA)
      setEsNC(registro.NO_FACTURA.substr(0, 1) === 'B')
      const diasCreTmp = fechaRestaFechas(registro.F_VCMTO, registro.FECHA)
      setDiasCre(filtrando ? '' : diasCreTmp < 0 ? 0 : diasCreTmp)
      // Pinta con amarillo el No. de Proforma si no existe en la BD
      async function existeProf () {
        const existe = await existeNoProf(registro.NO_PROFORMA, poolName, sesion.orcl_usuarioVSAM, sesion.no_empresaVSAM)
        setFmtoWarningNoProf(!existe)
      }
      if (!existentes && registro.NO_PROFORMA !== null && registro.NO_PROFORMA !== '') { await existeProf() }
      // Verifica que existan datos en los parametros para asignar las variables
      if (paramsEmp) {
        // Es NC
        if (registro.NO_FACTURA.substr(0, 1) === 'B') {
          esElect.current = (paramsEmp.SRI_SERIES_NC_ELECT.indexOf(registro.NO_FACTURA.substr(1, 7)) >= 0)
        // Es Factura
        } else esElect.current = (paramsEmp.SRI_SERIES_FACT_ELECT.indexOf(registro.NO_FACTURA.substr(1, 7)) >= 0)
      }
    }
  }

  // FUNCIONES DE CONTROL (EVENTOS) DE ELEMENTOS DEL COMPONENTE
  function ctrlClickConsultar (e) {
    function whereRangoNo (campoCond, soloNo, esNoFact) {
      soloNo = soloNo.toString()
      if (soloNo !== '') {
        const pos = soloNo.indexOf('-')
        if (pos === 0) {
          if (esNoFact) {
            return ` and ${campoCond} <= '${soloNo.substring(pos + 1).padStart(9, '0')}'`
          } else return ` and ${campoCond} <= ${soloNo.substring(pos + 1)}`
        } else if (pos === (soloNo.length - 1)) {
          if (esNoFact) {
            return ` and ${campoCond} >= '${soloNo.substring(0, pos).padStart(9, '0')}'`
          } else return ` and ${campoCond} >= ${soloNo.substring(0, pos)}`
        } else if (pos > 0) {
          if (soloNo.substring(pos).length > 1) {
            if (esNoFact) {
              return ` and ${campoCond} >= '${soloNo.substring(0, pos).padStart(9, '0')}'` +
                     ` and ${campoCond} <= '${soloNo.substring(pos + 1).padStart(9, '0')}'`
            } else {
              return ` and ${campoCond} >= ${soloNo.substring(0, pos)}` +
                     ` and ${campoCond} <= ${soloNo.substring(pos + 1)}`
            }
          } else
            if (esNoFact) {
              return ` and ${campoCond} >= '${soloNo.substring(0, pos).padStart(9, '0')}'`
            } else return ` and ${campoCond} >= ${soloNo.substring(0, pos)}`
        } else
          if (esNoFact) {
            return ` and ${campoCond} = '${soloNo.padStart(9, '0')}'`
          } else return ` and ${campoCond} = ${soloNo}`
      }
    }
    // Crea el WHERE con las condiciones establecidas por el usuario
    function condicion (datos) {
      const noFactura = datos.NO_FACTURA
      let where = ''
      if (tDocFiltrando === 'fact') where = where + ' and A.T_COMPROB = \'18\''
      if (tDocFiltrando === 'nc') where = where + ' and A.T_COMPROB = \'04\''
      if (noFactura.substr(1) !== '') {
        // Solo No. sin Serie
        if (noFactura.substr(1, 7) === '000-000') {
          if (noFactura.substr(9)) {
            where = where + whereRangoNo('substr(A.NO_FACTURA, 10)', noFactura.substr(9), true)
          } else where = where + ''
        // Sin No. solo Serie
        } else if (noFactura.substr(9) === '') {
          where = where + ` and substr(A.NO_FACTURA, 2, 7) = '${noFactura.substr(1, 7)}'`
        } else {
          where = where + ` and substr(A.NO_FACTURA, 2, 7) = '${noFactura.substr(1, 7)}'` +
                            whereRangoNo('substr(A.NO_FACTURA, 10)', noFactura.substr(9), true)
        }
      }
      if (datos.NO_PTOVENTA > 0) where = where + ' and A.NO_PTOVENTA = ' + datos.NO_PTOVENTA
      if (noBodega > 0) where = where + ' and A.NO_BODEGA = ' + noBodega
      if (datos.CLI_NOMBRE !== null && datos.CLI_NOMBRE !== '') {
        where = where + ` and B.NOMBRE like '${datos.CLI_NOMBRE.replaceAll('*', '%')}%'`
      }
      if (datos.NO_VENDEDOR !== null && datos.NO_VENDEDOR !== '') {
        where = where + ` and A.NO_VENDEDOR = ${datos.NO_VENDEDOR}`
      }
      if (datos.OBS !== null && datos.OBS !== '') {
        if (datos.OBS === '*') {
          where = where + ' and not A.OBS is null'
          where = where + " and replace(replace(A.OBS, chr(13), '|'), chr(10), '|') <> '||'"
        } else where = where + ` and A.OBS like '${datos.OBS.replaceAll('*', '%')}%'`
      }
      if (datos.NO_PROFORMA !== '') where = where + whereRangoNo('A.NO_PROFORMA', datos.NO_PROFORMA, false)
      if (datos.NO_FACTURA_NC !== '') {
        // Sin Serie solo No.
        if (datos.NO_FACTURA_NC.substr(0, 7) === '000-000') {
          where = where + whereRangoNo('substr(EXTRA8_TXT, 9)', datos.NO_FACTURA_NC.substr(8), true)
        // Sin No. solo Serie
        } else if (datos.NO_FACTURA_NC.substr(8) === '') {
          where = where + ` and substr(EXTRA8_TXT, 2, 7) = '${datos.NO_FACTURA_NC.substr(0, 7)}'`
        } else where = where + whereRangoNo('substr(EXTRA8_TXT, 9)', datos.NO_FACTURA_NC.substr(8), true)
      }
      if (datos.FECHA !== '') {
        where = where + ` and to_char(A.FECHA, 'yyyy-mm-dd') ${fechaCond} '${datos.FECHA}'`
      }
      if (datos.F_VCMTO !== '') {
        where = where + ` and to_char(A.F_DEVENGA, 'yyyy-mm-dd') ${fechaCond} '${datos.F_VCMTO}'`
      }
      if (datos.F_KARDEX !== '') {
        where = where + ` and to_char(A.F_KARDEX, 'yyyy-mm-dd') ${fechaCond} '${datos.F_KARDEX}'`
      }
      if (diasCre !== '') {
        where = where + whereRangoNo('ceil(A.F_DEVENGA - A.FECHA)', diasCre, false)
      }
      if (datos.FECHA !== '') {
        where = where + ` and to_char(A.FECHA, 'yyyy-mm-dd') ${fechaCond} '${datos.FECHA}'`
      }
      return where
    }
    const tmp = condicion(datFact[0])
    setWhereExist(tmp)
    cargaFactExistentes(tmp, ordenExist, false)
  }
  async function ctrlChangeNC (e) {
    // ** No usar e.preventDefault() porque causa efectos no deseados al presionar en el check
    setEnEspera(true)
    // Hay que guardar el valor original, porque se pierde luego de enviar al API
    const nuevoEsNC = e.target.checked
    let nuevoNoFact = ''
    // Filtrando
    if (existentes) {
      nuevoNoFact = nuevoEsNC ? 'B' : 'A' + datFact[filtrando ? 0 : sesion.facturas.index].NO_FACTURA.substring(1)
      setEsNC(nuevoEsNC)
      datFact[filtrando ? 0 : sesion.facturas.index].NO_FACTURA = nuevoNoFact
      setEnEspera(false)
    } else {
      const nuevasSeries = cargaSeries(nuevoEsNC ? gcTiposDoc.nc : gcTiposDoc.fact, paramsEmp, userEmpConfig.series)[0]
      procesaCheckNC((nuevoEsNC ? 'B' : 'A') + nuevasSeries[0])
    }
    async function procesaCheckNC (serie) {
      // datFact[filtrando?0:sesion.facturas.index].NO_FACTURA=nuevoNoFact
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ACTUALIZAR LOS CAMPOS NECESARIOS EN LA BD',
        {
          method: 'patch',
          url: '/facturas/nueva/checkNC',
          data: {
            poolName,
            orclUserVSAM: sesion.orcl_usuarioVSAM,
            noEmp: sesion.no_empresaVSAM,
            serie,
            sesion
          }
        }
      )
      setEnEspera(false)
      if (respuestaAPI.status === 200) {
        setEsNC(nuevoEsNC)
      }
    }
  }
  function ctrlDblClickDiasCre (e) {
    // e.preventDefault()
    dblClickOrdenar('DIAS_CRE')
  }

  // FUNCIONES DE CONTROL (EVENTOS) DEVUELTOS POR LOS COMPONENTES
  function ctrlCambioEmp () {
    ctrlNuevoDatFact([], false, true)
  }
  function clickAnterior () {
    seteaVarState(datFact[sesion.facturas.index - 1])
    const nSesion = nuevaSesion(false, sesion,
      {
        facturas: {
          clave: existentes
            ? datFact[sesion.facturas.index - 1].NO_FACTURA
            : datFact[sesion.facturas.index - 1].NO_REG,
          index: sesion.facturas.index - 1,
          modificando: sesion.facturas.modificando,
          filtrando: sesion.facturas.filtrando
        }
      })
    setSesion(nSesion)
  }
  function click10Ant () {
    seteaVarState(datFact[sesion.facturas.index - 10])
    const nSesion = nuevaSesion(false, sesion,
      {
        facturas: {
          clave: existentes
            ? datFact[sesion.facturas.index - 10].NO_FACTURA
            : datFact[sesion.facturas.index - 10].NO_REG,
          index: sesion.facturas.index - 10,
          modificando: sesion.facturas.modificando,
          filtrando: sesion.facturas.filtrando
        }
      })
    setSesion(nSesion)
  }
  function clickPrimera () {
    seteaVarState(datFact[0])
    const nSesion = nuevaSesion(false, sesion,
      {
        facturas: {
          clave: existentes
            ? datFact[0].NO_FACTURA
            : datFact[0].NO_REG,
          index: 0,
          modificando: sesion.facturas.modificando,
          filtrando: sesion.facturas.filtrando
        }
      })
    setSesion(nSesion)
  }

  function clickSiguiente () {
    seteaVarState(datFact[sesion.facturas.index + 1])
    const nSesion = nuevaSesion(false, sesion,
      {
        facturas: {
          clave: existentes
            ? datFact[sesion.facturas.index + 1].NO_FACTURA
            : datFact[sesion.facturas.index + 1].NO_REG,
          index: sesion.facturas.index + 1,
          modificando: sesion.facturas.modificando,
          filtrando: sesion.facturas.filtrando
        }
      })
    setSesion(nSesion)
  }
  function click10Sig () {
    seteaVarState(datFact[sesion.facturas.index + 10])
    const nSesion = nuevaSesion(false, sesion,
      {
        facturas: {
          clave: existentes
            ? datFact[sesion.facturas.index + 10].NO_FACTURA
            : datFact[sesion.facturas.index + 10].NO_REG,
          index: sesion.facturas.index + 10,
          modificando: sesion.facturas.modificando,
          filtrando: sesion.facturas.filtrando
        }
      })
    setSesion(nSesion)
  }
  function clickUltima () {
    seteaVarState(datFact[datFact.length - 1])
    const nSesion = nuevaSesion(false, sesion,
      {
        facturas: {
          clave: existentes
            ? datFact[datFact.length - 1].NO_FACTURA
            : datFact[datFact.length - 1].NO_REG,
          index: datFact.length - 1,
          modificando: sesion.facturas.modificando,
          filtrando: sesion.facturas.filtrando
        },
        auth: sesion.auth
      })
    setSesion(nSesion)
  }

  function clickListado (index) {
    setListando(false)
    seteaVarState(datFact[index])
    const nSesion = nuevaSesion(false, sesion,
      {
        facturas: {
          clave: existentes
            ? datFact[index].NO_FACTURA
            : datFact[index].NO_REG,
          index,
          modificando: sesion.facturas.modificando,
          filtrando: sesion.facturas.filtrando
        }
      })
    setSesion(nSesion)
  }
  function ctrlNuevoFiltroLista () {
    datExistentes.current = []
    setEsNC(false)
    setFechaCond('=')
    setListando(false)
    const nSesion = nuevaSesion(false, sesion,
      {
        facturas: {
          clave: sesion.facturas.clave,
          index: sesion.facturas.index,
          modificando: sesion.facturas.modificando,
          filtrando: true
        }
      })
    setSesion(nSesion)
    navigate('/facturas/existentes')
  }
  function dblClickOrdenar (campo) {
    if (existentes && !filtrando) {
      const orden = campo === undefined ? ordenExist : campo
      setOrdenExist(orden)
      cargaFactExistentes(whereExist, orden, true)
    }
  }
  function asignaFechaCond (cond) {
    switch (cond) {
      case '1': setFechaCond('>'); break
      case '2': setFechaCond('>='); break
      case '3': setFechaCond('<'); break
      case '4': setFechaCond('<='); break
      default: setFechaCond('=')
    }
  }
  function ctrlPtoVtaValor ({ nuevoVal, index, descrip }) {
    datFact[filtrando ? 0 : sesion.facturas.index].NO_PTOVENTA = nuevoVal
    if (!existentes && !filtrando) {
      setNoBodega(nuevoVal)
      datFact[filtrando ? 0 : sesion.facturas.index].NO_BODEGA = nuevoVal
    }
  }
  function ctrlDiasCre (idName, valor) {
    async function actFechaVcmto (fVcmtoBD, fVcmto, dias) {
      setEnEspera(true)
      const infoTablaAct = setInfoCampoFactura('F_VCMTO', fVcmtoBD)
      const defProps = {
        poolName,
        tabla: infoTablaAct.tabla,
        campo: infoTablaAct.campo,
        valor: infoTablaAct.valor,
        cond: infoTablaAct.campoClave + '=' + sesion.facturas.clave,
        ceroEsNulo: false,
        dias
      }
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ACTUALIZAR LA FECHA DE VENCIMIENTO EN LA BD',
        {
          method: 'patch',
          url: infoTablaAct.url,
          data: defProps
        }, true
      )
      setEnEspera(false)
      if (respuestaAPI.status === 200) {
        datFact[filtrando ? 0 : sesion.facturas.index].F_VCMTO = fVcmto
        setDiasCre(valor)
        setRecargar(!recargar)
        setOcultaFP(true)
        setDatosFPs(datosFPVacia)
      }
    }
    switch (idName) {
      case 'fechaFact':
        datFact[filtrando ? 0 : sesion.facturas.index].FECHA = valor
        if (!existentes) {
          const nuevaFVcmto = fechaSumaDias(valor, diasCre, '-')
          datFact[filtrando ? 0 : sesion.facturas.index].F_KARDEX = valor
          datFact[filtrando ? 0 : sesion.facturas.index].F_VCMTO = nuevaFVcmto[1]
          setRecargar(!recargar)
        }
        break
      case 'diasCre':
        // Actualiza F_VCMTO en la BD
        if (existentes) setDiasCre(valor)
        else {
          const fFact = datFact[filtrando ? 0 : sesion.facturas.index].FECHA
          const fVcmtoSinFmto = fechaSumaDias(fFact, (valor === '' ? 0 : valor), '-')
          const valFVcmto = `to_date('${fVcmtoSinFmto[1]}', 'yyyy-mm-dd')`
          datFact[filtrando ? 0 : sesion.facturas.index].F_VCMTO = fVcmtoSinFmto[1]
          actFechaVcmto(valFVcmto, fVcmtoSinFmto[1], (valor === '' ? 0 : valor))
        }
        break
      case 'fVcmto':
        if (!existentes || filtrando) {
          if (valor !== '' && valor < datFact[filtrando ? 0 : sesion.facturas.index].FECHA.substr(0, 10)) {
            Swal.fire(
              'LA FECHA DE VENCIMIENTO NO PUEDE SER MENOR A LA FECHA DE LA FACTURA',
              'El sistema no cambiará la fecha de vencimiento',
              'info'
            )
            // Vuelve a colocar la fecha de vcmto. original
            const fVcmtoSinFmto = datFact[filtrando ? 0 : sesion.facturas.index].F_VCMTO.substr(0, 10)
            const valFVcmto = `to_date('${fVcmtoSinFmto}', 'yyyy-mm-dd')`
            actFechaVcmto(valFVcmto, fVcmtoSinFmto, diasCre)
          } else if (valor !== '') {
            datFact[filtrando ? 0 : sesion.facturas.index].F_VCMTO = valor
            const nuevoDiasCre = fechaRestaFechas(valor, datFact[filtrando ? 0 : sesion.facturas.index].FECHA)
            setDiasCre(nuevoDiasCre)
          }
        }
        break
      default:
    }
  }
  function ctrlNuevoFiltroBotones () {
    if (existentes) datExistentes.current = []
    const nSesion = nuevaSesion(false, sesion,
      {
        facturas: {
          clave: sesion.facturas.clave,
          index: sesion.facturas.index,
          modificando: sesion.facturas.modificando,
          filtrando: true
        }
      })
    setSesion(nSesion)
    navigate('/facturas/existentes')
  }
  function ctrlNuevoDatFact (nuevoDatFact, hagaSetDatFact, asigneExistentes) {
    // Viene de una modificación (ya esta en existentes)
    if (hagaSetDatFact) {
      setDatFact(nuevoDatFact)
    }
    // Creo una nueva factura y quiere guardar los datos para cuando pase a existentes
    // o cambió de empresa
    if (asigneExistentes) {
      datExistentes.current = nuevoDatFact
      setRecargar(!recargar)
    }
  }
  function ctrlCliente (txtCampoTabla, filtro, regCliente) {
    // Viene de una Modificacion o Creacion (undefined cuando solo se asigna un cliente)
    if (regCliente.NO_CLIENTE !== undefined) {
      datFact[filtrando ? 0 : sesion.facturas.index].NO_CLIENTE = regCliente.NO_CLIENTE
      datFact[filtrando ? 0 : sesion.facturas.index].CLI_NOMBRE = regCliente.NOMBRE
      ctrlDiasCre('diasCre', regCliente.V_INI_X_COB)
    }
  }
  async function clickRepIndividual (formato, conEmail) {
    console.log('🚀 ~ file: Facturas.jsx:1044 ~ clickRepIndividual ~ formato, conEmail:', formato, conEmail, datFact[sesion.facturas.index], datDetalles)
    let url = '/facturas/reportes/rodillo'
    switch (formato) {
      case 'RODILLO': url = '/facturas/reportes/rodillo'
        break
      case 'CLIENTE': url = '/facturas/reportes/individual'
        break
      case 'INTERNO': url = '/facturas/reportes/interno'
        break
      default: url = '/facturas/reportes/individual'
        break
    }
    const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CREAR EL REPORTE SOLICITADO DE LA FACTURA',
      {
        method: 'post',
        url,
        data: {
          cabeceras: datFact[sesion.facturas.index],
          detalles: datDetalles,
          rutaCarpetas: userEmpConfig.ruta_carpetas,
          saveFile: conEmail
        },
        responseType: 'blob'
      }
    )
    if (respuestaAPI.status === 200) {
      console.log('🚀 ~ file: Facturas.jsx:1072 ~ clickRepIndividual ~ respuestaAPI:', respuestaAPI.data, respuestaAPI.headers)
      // Envía el Email con el attach del PDF
      if (conEmail) {
        let fileName = 'factura.pdf'
        if (respuestaAPI.headers['content-disposition']) {
          fileName = respuestaAPI.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
        }

        setCargando(true)
        const resAPIEmail = await restAPIMySQL('ERROR AL ENVIAR AL CLIENTE LA FACTURA POR CORREO ELECTRONICO',
          {
            method: 'post',
            url: '/sendEmail',
            data: {
              emailConfig: sesion.email_config,
              datos: {
                from: `${regActual.EMP_NOMBRE} - ${sesion.userNombre}`,
                to: `${regActual.CLI_EMAIL}`,
                subject: `Factura No. ${regActual.NO_FACTURA.substring(1)}`,
                text: `Estimado ${regActual.CLI_NOMBRE}`,
                html: `<p style="text-align: center">${regActual.EMP_NOMBRE} le envía adjunto la <b>Factura No. ${regActual.NO_FACTURA.substring(1)}</b></p>`
              },
              configAttach: {
                filename: `Factura No. ${regActual.NO_FACTURA.substring(1)}.pdf`,
                path: rutaReps(userEmpConfig.ruta_carpetas, gcModulos.vtas) + fileName
              }
            }
          }
        )
        setCargando(false)
        if (resAPIEmail.status === 200) {
          Swal.fire(
            `LA FACTURA SE ENVIO CON EXITO A "${regActual.CLI_NOMBRE}"`,
            `Email ${regActual.CLI_EMAIL}`,
            'info'
          )
        }
      } else {
        let pdf = new Blob([respuestaAPI.data], { type: 'application/pdf' })
        pdf = window.URL.createObjectURL(pdf)
        window.open(pdf)
      }
    }
  }

  // Evento que controla la presion de teclas
  // function colocaFoco(e){
  //     //** No se puede usar porque deja de funcionar el teclado
  //     if (e.altKey && e.ctrlKey && (e.key === 'c' || e.key === 'C')) {
  //         // Coloca el foco en el Código de Grupo
  //         //const itemCant = document.getElementById('itemCant')
  //         const itemCod = document.getElementById('tablaFiltro-ITEMS-CODIGO')
  //         itemCod.focus()
  //     }
  // }

  const pantallaNC = (filtrando && tDocFiltrando === 'nc') || (!filtrando && esNC)
  const fmtoOrden = existentes && !filtrando ? 'bg-primary text-white' : ''
  if (!sesion.auth || datFact.length === 0 || cargando || sesion.facturas.index > datFact.length - 1) {
    return (
      <div className='m-5 h3 text-center'>
        <Spinner animation='grow' />Cargando datos ...
      </div>
    )
  }
  let regActual
  if (datFact.length === 0) regActual = []
  else regActual = datFact[filtrando ? 0 : sesion.facturas.index]

  return (
    <Card className='border-light'>
      <Encabezado
        existentes={existentes} modificando={modificando} sinEncabezado={false}
        esHeader noDocMod={modificando ? regActual.NO_FACTURA_MOD : ''}
      />
      {listando
        ? <ListaExistentes
            registros={datFact} devuelveClickListado={clickListado}
            devuelveClickNuevoFiltro={ctrlNuevoFiltroLista} orden={ordenExist}
          />
        : grabando.enProceso
          ? <div className='mt-5 mb-5 pt-5 pb-5 h3 text-center'>
              <Spinner animation='grow' />{grabando.msjPantalla}
            </div>
          : <Card.Body className={'p-0' + (existentes || modificando ? ' bg-dark-subtle' : '')}>
              {/* EMPRESAS Y BOTONES */}
              <Card.Title className=''>
                <Container className='p-0'>
                  <Row>
                    <EmpSel existentes={existentes} devuelveCambiaEmp={ctrlCambioEmp} esHeader />
                  </Row>
                  <Row>
                    {filtrando
                      ? <Container className=''>
                          <Row className='h6 p-0 m-0 text-secondary justify-content-center bg-dark-subtle'>
                            <span className='text-center p-1'>Si desea establezca valores en los campos (un filtro):&nbsp;&nbsp;</span>
                            <Button
                              className='' size='sm' style={{ width: 100 }} onClick={ctrlClickConsultar} disabled={cargando}
                            >Consultar
                            </Button>
                          </Row>
                        </Container>
                      : <Botones
                          totalRegs={datFact.length} factValida={factValida} esElect={esElect.current}
                          estaGrabando={(valor) => setGrabando(valor)} devuelveListar={() => setListando(true)}
                          datosReg={regActual} existentes={existentes} diasCre={diasCre}
                          devuelveAnterior={clickAnterior} devuelveSiguiente={clickSiguiente}
                          devuelve10Ant={click10Ant} devuelve10Sig={click10Sig} editando={editando}
                          devuelvePrimera={clickPrimera} devuelveUltima={clickUltima}
                          recargarCab={() => setRecargar(!recargar)} datosFPs={datosFPs}
                          devuelveDatDoc={ctrlNuevoDatFact} paramsEmp={paramsEmp}
                          devuelveNuevoFiltro={ctrlNuevoFiltroBotones}
                          devuelveRepIndividual={clickRepIndividual}
                          devuelveFP={() => setOcultaFP(!ocultaFP)}
                        >{regActual.NO_FACTURA}
                        </Botones>}
                  </Row>
                </Container>
              </Card.Title>

              {/* CABECERA SIN TOTALES */}
              {/* Datos Autorización SRI */}
              {(existentes && !filtrando) || modificando
                ? <BarraSRI
                    sriAutoriz={regActual.SRI_AUTORIZACION}
                    sriClave={regActual.SRI_CLAVE}
                    sriMensaje={regActual.SRI_MENSAJE}
                  />
                : null}

              {/* Ptos.Venta, Bodega, Clientes, Estado, Tipo de Documento, No. Factura y Proforma o No.Factura NC */}
              <Row className='ms-1 me-1 justify-content-between'>
                {/* Ptos.Venta, Bodega y Clientes */}
                <Col className='' xs={null} lg={10} xl={6} xxl={7}>
                  {/* Ptos.Venta y Bodega */}
                  <Row className='justify-content-between'>
                    <Col className=''>
                      <TablaOpcion
                        valTabla={regActual.NO_PTOVENTA}
                        infoTablaActualiza={existentes
                          ? undefined
                          : setInfoCampoFactura('NO_PTOVENTA', regActual.NO_PTOVENTA)}
                        infoTablaLista={infoPtoVtaSel(['mb-1', ordenExist === 'PTO_VENTA' ? fmtoOrden : '', ''])}
                        devuelveDatos={ctrlPtoVtaValor}
                        deshabilitado={existentes && !filtrando}
                        devuelveDblClick={(campoOrden) => dblClickOrdenar('PTO_VENTA')}
                        devuelveEditando={(valor) => setEditando(valor)}
                      />
                    </Col>
                    <Col className=''>
                      <TablaOpcion
                        valTabla={noBodega}
                        infoTablaActualiza={existentes
                          ? undefined
                          : setInfoCampoFactura('NO_BODEGA', noBodega)}
                        infoTablaLista={infoBodegaSel(['mb-1', ordenExist === 'BODEGA' ? fmtoOrden : '', ''])}
                        devuelveDatos={({ nuevoVal, index, descrip }) => setNoBodega(nuevoVal)}
                        deshabilitado={existentes && !filtrando}
                        devuelveDblClick={(campoOrden) => dblClickOrdenar('BODEGA')}
                        devuelveEditando={(valor) => setEditando(valor)}
                      />
                    </Col>
                  </Row>
                  {/* Clientes */}
                  <Clientes
                    noClienteIni={regActual.NO_CLIENTE} opcMenuCall={gcTablasLogicas.fact}
                    filtrando={filtrando} existentes={existentes} updateTablaDoc
                    devuelveFiltro={(valor) => { datFact[filtrando ? 0 : sesion.facturas.index].CLI_NOMBRE = valor }}
                    devuelveRegistro={ctrlCliente}
                    devuelveDblClick={() => dblClickOrdenar('CLI_NOMBRE')}
                  >{regActual.CLI_NOMBRE}
                  </Clientes>
                  {/* <TablaFiltro
                    infoTablaActualiza={existentes
                      ? undefined
                      : setInfoCampoFactura('NO_CLIENTE', regActual.NO_CLIENTE)}
                    infoTablaLista={infoClienteFiltro(existentes && !filtrando, ''
                      , ['mb-1', ordenExist === 'CLI_NOMBRE' ? fmtoOrden : '',
                        regActual.NO_CLIENTE === null ? 'bg-warning-subtle' : ''])}
                    devuelveFiltro={(valor) => { datFact[filtrando ? 0 : sesion.facturas.index].CLI_NOMBRE = valor }}
                    devuelveRegistro={(txtCampoTabla, filtro, regCliente) => {
                      datFact[filtrando ? 0 : sesion.facturas.index].NO_CLIENTE = regCliente.KEY_TAB
                    }}
                    devuelveDblClick={(campoOrden) => dblClickOrdenar('CLI_NOMBRE')}
                    filtrando={filtrando}
                  >{regActual.CLI_NOMBRE}
                  </TablaFiltro> */}
                </Col>
                {/* Estado y Tipo de Documento */}
                <Col className='ps-1 me-1' xs={5} lg={4} xl={2} style={{ minWidth: 170 }}>
                  <div className={wAncho1200 ? 'd-flex' : ''}>
                    {/* Estado */}
                    <Row
                      className='rounded border border-primary-subtle bg-info-subtle mt-1 mb-2 ms-2 me-2'
                      hidden={!existentes || filtrando}
                    ><span className='text-center fw-bold'>{regActual.ESTADO === 'A'
                      ? 'ANULADA'
                      : regActual.ESTADO === 'S'
                        ? 'CON SALDO'
                        : regActual.ESTADO === 'C'
                          ? 'CANCELADA'
                          : 'PENDIENTE'}
                     </span>
                    </Row>
                    {/* Tipo Doc Nuevas */}
                    <Row className='mb-2' hidden={existentes || filtrando}>
                      <Form.Check
                        className='text-center'
                        style={{ width: 150 }}
                        type='checkbox'
                        label='Nota Crédito:'
                        reverse
                        checked={esNC}
                        onChange={ctrlChangeNC}
                      />
                      <Spinner animation='grow' hidden={!enEspera} />
                    </Row>
                    {/* Tipo Doc Filtrando */}
                    <Row className='' hidden={!filtrando}>
                      <Form.Select
                        className='text-center' style={{ maxWidth: 140 }}
                        size='sm'
                        value={tDocFiltrando}
                        onChange={(e) => { setTDocFiltrando(e.target.value) }}
                      >
                        <option value='todos'>Todos Docs.</option>
                        <option value='nc'>Solo Ntas.Cré.</option>
                        <option value='fact'>Solo Facturas</option>
                      </Form.Select>
                    </Row>
                    {/* Tipo Doc Existentes */}
                    <Row
                      className='ms-2 me-2 mb-1 pb-1'
                      hidden={!existentes || filtrando || !esNC}
                      style={{ minWidth: 150 }}
                    >
                      <span className='rounded border border-primary bg-primary-subtle text-center'>NOTA CREDITO
                      </span>
                    </Row>
                  </div>
                </Col>
                {/* No. Factura y Proforma o No.Factura NC */}
                <Col className='p-0' xs={9} lg={7} xl={2} style={{ minWidth: 320 }}>
                  <div className={wAncho1200 ? 'd-flex' : ''}>
                    <NoFactura
                      infoCampo={
                                  {
                                    fmto: ['bg-primary-subtle fw-bold', ordenExist === 'NO_FACTURA' ? fmtoOrden : 'text-end', 'text-end bg-primary-subtle fw-bold'],
                                    id: 'noFact',
                                    label: 'No.',
                                    required: true,
                                    modificando,
                                    filtrando,
                                    disabled: ((!accModNo || existentes) && !filtrando)
                                  }
                              }
                      valTabla={regActual.NO_FACTURA}
                      paramsEmp={paramsEmp}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoFactura('NO_FACTURA', regActual.NO_FACTURA)}
                      devuelveVal={(valor) => { datFact[filtrando ? 0 : sesion.facturas.index].NO_FACTURA = valor }}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('NO_FACTURA')}
                      devuelveEditando={(valor) => setEditando(valor)}
                      devuelveCond={(valor) => asignaFechaCond(valor)}
                    />
                    <TablaCampo
                      infoCampo={
                                  {
                                    fmto: ['mb-1', ordenExist === 'NO_PROFORMA, NO_FACTURA_NC' ? fmtoOrden : '',
                                      (existentes && filtrando ? 'text-end' : 'text-end') + (fmtoWarningNoProf && !filtrando ? ' bg-warning-subtle' : '')],
                                    type: existentes ? 'text' : 'number',
                                    hidden: pantallaNC,
                                    id: 'noProf',
                                    label: 'No. Proforma:',
                                    txtDespues: '',
                                    min: 1,
                                    max: 999999999,
                                    required: true,
                                    disabled: (existentes && !filtrando),
                                    ceroEsNulo: false
                                  }
                              }
                      valTabla={regActual.NO_PROFORMA}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoFactura('NO_PROFORMA', regActual.NO_PROFORMA)}
                      devuelveVal={(valor) => {
                        datFact[filtrando ? 0 : sesion.facturas.index].NO_PROFORMA = (!filtrando && valor === '' ? null : valor)
                        // Pinta con amarillo el No. de Proforma si no existe en la BD
                        async function existeProf () {
                          const existe = await existeNoProf(valor, poolName, sesion.orcl_usuarioVSAM, sesion.no_empresaVSAM)
                          setFmtoWarningNoProf(!existe)
                        }
                        if (!filtrando && valor !== null && valor !== '') existeProf()
                      }}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('NO_PROFORMA, NO_FACTURA_NC')}
                      devuelveEditando={(valor) => setEditando(valor)}
                    />
                    <NoFactura
                      infoCampo={
                                  {
                                    fmto: ['', ordenExist === 'NO_FACTURA_NC, NO_PROFORMA' ? fmtoOrden : 'text-end', 'text-end'],
                                    id: 'noFactNC',
                                    label: 'Factura:',
                                    required: true,
                                    hidden: (!pantallaNC),
                                    disabled: (existentes && !filtrando),
                                    filtrando,
                                    modificando
                                  }
                                }
                      valTabla={regActual.NO_FACTURA_NC}
                      paramsEmp={paramsEmp}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoFactura('NO_FACTURA_NC', regActual.NO_FACTURA_NC)}
                      devuelveVal={(valor) => { datFact[filtrando ? 0 : sesion.facturas.index].NO_FACTURA_NC = valor }}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('NO_FACTURA_NC, NO_PROFORMA')}
                      devuelveEditando={(valor) => setEditando(valor)}
                      devuelveCond={(valor) => asignaFechaCond(valor)}
                    />
                  </div>
                </Col>
              </Row>

              {/* Vendedor, Fecha, Días crédito y Vencimiento */}
              <Row className='ms-1 me-1 justify-content-between'>
                {/* Vendedor */}
                <Col
                  xs={9}
                  lg={pantallaNC ? 5 : existentes || filtrando ? 4 : 5}
                  xl={pantallaNC ? 5 : existentes || filtrando ? 5 : 6}
                  xxl={pantallaNC ? 6 : existentes || filtrando ? 6 : 8}
                >
                  <TablaOpcion
                    valTabla={regActual.NO_VENDEDOR}
                    infoTablaActualiza={existentes
                      ? undefined
                      : setInfoCampoFactura('NO_VENDEDOR',
                        regActual.NO_VENDEDOR
                      )}
                    infoTablaLista={infoVendedorSel(['mb-1', ordenExist === 'VENDEDOR' ? fmtoOrden : '', ''])}
                    devuelveDatos={({ nuevoVal, index, descrip }) => {
                      datFact[filtrando ? 0 : sesion.facturas.index].NO_VENDEDOR = nuevoVal
                    }}
                    deshabilitado={existentes && !filtrando}
                    devuelveDblClick={(campoOrden) => dblClickOrdenar('VENDEDOR')}
                    devuelveEditando={(valor) => setEditando(valor)}
                  />
                </Col>
                {/* Fecha */}
                <Col
                  xs={pantallaNC ? 7 : 4} lg={existentes || filtrando ? 3 : 2} xl={existentes || filtrando ? 3 : 2}
                  style={{ minWidth: existentes || filtrando ? 330 : 200 }}
                >
                  <TablaCampoFecha
                    infoCampo={
                                  {
                                    fmto: ['mb-1 me-2', ordenExist === 'FECHA' ? fmtoOrden : '', ''],
                                    id: 'fechaFact',
                                    label: 'Fecha:',
                                    required: true,
                                    disabled: ((!accModFecha || existentes) && !filtrando),
                                    conCondFiltro: filtrando,
                                    showHora: existentes && !filtrando,
                                    dias: diasCre
                                  }
                              }
                    valTabla={regActual.FECHA}
                    infoTablaActualiza={existentes
                      ? undefined
                      : setInfoCampoFactura('FECHA', regActual.FECHA)}
                    devuelveVal={(valor) => { ctrlDiasCre('fechaFact', valor) }}
                    devuelveDblClick={(campoOrden) => dblClickOrdenar('FECHA')}
                    devuelveEditando={(valor) => setEditando(valor)}
                    devuelveCond={(valor) => asignaFechaCond(valor)}
                  />
                </Col>
                {/* Días crédito */}
                <Col xs={3} md={1} hidden={pantallaNC} style={{ minWidth: 120 }}>
                  <InputGroup
                    className=''
                    size='sm'
                  >
                    <InputGroup.Text
                      id='DIAS_CRE' className={'mb-1 ' + (ordenExist === 'DIAS_CRE' ? fmtoOrden : '')}
                      onDoubleClick={ctrlDblClickDiasCre}
                    >Días:
                    </InputGroup.Text>
                    <CampoNumerico
                      idName='diasCre' fmtoInput='mb-1' devuelveVal={ctrlDiasCre}
                      decimales={0} obligatorio deshabilitado={(existentes && !filtrando) || !accDiasCre}
                      soloPositivo={false} filtrando={filtrando} style={{ minWidth: 40, maxWidth: 50 }}
                    >{diasCre}
                    </CampoNumerico>
                  </InputGroup>
                </Col>
                {/* Fecha Vencimiento */}
                <Col
                  xs='auto' hidden={pantallaNC}
                  style={{ minWidth: existentes || filtrando ? 230 : 200 }}
                ><TablaCampoFecha
                  infoCampo={
                              {
                                fmto: ['mb-1', ordenExist === 'F_VCMTO' ? fmtoOrden : '', ''],
                                id: 'fVcmto',
                                label: 'Vence:',
                                required: true,
                                disabled: ((!accDiasCre || existentes) && !filtrando),
                                hidden: ((filtrando && tDocFiltrando === 'nc') || (!filtrando && esNC)),
                                conCondFiltro: filtrando,
                                showHora: false,
                                dias: diasCre
                              }
                            }
                  valTabla={regActual.F_VCMTO}
                  infoTablaActualiza={existentes
                    ? undefined
                    : setInfoCampoFactura('F_VCMTO', regActual.F_VCMTO)}
                  devuelveVal={(valor) => { ctrlDiasCre('fVcmto', valor) }}
                  devuelveDblClick={(campoOrden) => dblClickOrdenar('F_VCMTO')}
                  devuelveEditando={(valor) => setEditando(valor)}
                  devuelveCond={(valor) => asignaFechaCond(valor)}
                 />
                </Col>
                {/* Fecha Kardex */}
                <Col xs='auto' hidden={!pantallaNC}>
                  <TablaCampoFecha
                    infoCampo={
                                {
                                  fmto: ['mb-1', ordenExist === 'F_KARDEX' ? fmtoOrden : '', ''],
                                  id: 'fKardex',
                                  label: 'F. Kardex:',
                                  required: true,
                                  disabled: ((!accModFKardex || existentes) && !filtrando),
                                  conCondFiltro: filtrando,
                                  showHora: false,
                                  dias: diasCre
                                }
                              }
                    valTabla={regActual.F_KARDEX}
                    infoTablaActualiza={existentes
                      ? undefined
                      : setInfoCampoFactura('F_KARDEX', regActual.F_KARDEX)}
                    devuelveVal={(valor) => { datFact[filtrando ? 0 : sesion.facturas.index].F_KARDEX = valor }}
                    devuelveDblClick={(campoOrden) => dblClickOrdenar('F_KARDEX')}
                    devuelveEditando={(valor) => setEditando(valor)}
                    devuelveCond={(valor) => asignaFechaCond(valor)}
                  />
                </Col>
              </Row>

              {/* Observación y Fecha Kardex */}
              <Row className='ms-1 me-1 mb-1 justify-content-between'>
                {/* Observación */}
                <Col
                  xs={null} md={pantallaNC ? null : 7} lg={pantallaNC ? null : 8} xl={pantallaNC ? null : 9}
                  xxl={pantallaNC ? null : filtrando ? 9 : 10}
                >
                  <TablaCampo
                    infoCampo={
                                {
                                  fmto: ['', ordenExist === 'OBS' ? fmtoOrden : '', ''],
                                  type: 'text',
                                  id: 'obsFact',
                                  label: 'Observaciones:',
                                  txtDespues: '',
                                  min: null,
                                  max: 250,
                                  required: false,
                                  disabled: (existentes && !filtrando),
                                  ceroEsNulo: true
                                }
                              }
                    valTabla={regActual.OBS}
                    infoTablaActualiza={existentes
                      ? undefined
                      : setInfoCampoFactura('OBS', regActual.OBS)}
                    devuelveVal={(valor) => { datFact[filtrando ? 0 : sesion.facturas.index].OBS = valor }}
                    devuelveDblClick={(campoOrden) => dblClickOrdenar('OBS')}
                    devuelveEditando={(valor) => setEditando(valor)}
                  />
                </Col>
                {/* Fecha Kardex */}
                <Col xs='auto' hidden={pantallaNC}>
                  <TablaCampoFecha
                    infoCampo={
                                {
                                  fmto: ['mb-1', ordenExist === 'F_KARDEX' ? fmtoOrden : '', ''],
                                  id: 'fKardex',
                                  label: 'F. Kardex:',
                                  required: true,
                                  disabled: ((!accModFKardex || existentes) && !filtrando),
                                  conCondFiltro: filtrando,
                                  showHora: false,
                                  dias: diasCre
                                }
                              }
                    valTabla={regActual.F_KARDEX}
                    infoTablaActualiza={existentes
                      ? undefined
                      : setInfoCampoFactura('F_KARDEX', regActual.F_KARDEX)}
                    devuelveVal={(valor) => { datFact[filtrando ? 0 : sesion.facturas.index].F_KARDEX = valor }}
                    devuelveDblClick={(campoOrden) => dblClickOrdenar('F_KARDEX')}
                    devuelveEditando={(valor) => setEditando(valor)}
                    devuelveCond={(valor) => asignaFechaCond(valor)}
                  />
                </Col>
              </Row>

              {/* FORMAS DE PAGO */}
              {filtrando || !accFormasPago || ocultaFP || esNC
                ? null
                : <FPsFactSimple
                    devuelveOcultaFP={() => setOcultaFP(true)}
                    vTotal={datTotales.totNeto} diasCre={diasCre}
                    datos={datosFPs}
                    devuelveValores={(valores) => setDatosFPs(valores)}
                  />}

              {filtrando
                ? null
                : <ItemsTransacciones
                    paramsEmp={paramsEmp} existentes={existentes} orden={ordenExist}
                    datosAPI={{ urlBase: '/facturas', msjErrorDet: ' DE LA FACTURA' }}
                    devuelveDets={(detalles) => { setDatDetalles(detalles); setDatosFPs(datosFPVacia); setOcultaFP(true) }}
                    devuelveTots={(totales) => { setDatTotales(totales); setDatosFPs(datosFPVacia); setOcultaFP(true) }}
                    noCliente={regActual.NO_CLIENTE} fechaDoc={regActual.FECHA} esNC={esNC}
                    devuelveDblClick={(campoOrden) => dblClickOrdenar(campoOrden)}
                  >{noBodega}
                  </ItemsTransacciones>}
              {/* BARRA DE AUDITORIA */}
              {existentes && !filtrando
                ? <BarraAuditoria
                    userNo={datFact[sesion.facturas.index].NO_USUARIO}
                    userNombre={datFact[sesion.facturas.index].USER_NOMBRE}
                    fModifica={datFact[sesion.facturas.index].F_MODIFICA}
                  />
                : null}

              {/* EMPRESAS Y BOTONES */}
              {filtrando || grabando.enProceso
                ? null
                : <Card.Footer className='mt-1 pb-0 pt-0 border-0'>
                    <Container className='p-0'>
                      <Row>
                        <Botones
                          totalRegs={datFact.length} factValida={factValida} esElect={esElect.current}
                          estaGrabando={(valor) => setGrabando(valor)} devuelveListar={() => setListando(true)}
                          datosReg={regActual} existentes={existentes} diasCre={diasCre}
                          devuelveAnterior={clickAnterior} devuelveSiguiente={clickSiguiente}
                          devuelve10Ant={click10Ant} devuelve10Sig={click10Sig} editando={editando}
                          devuelvePrimera={clickPrimera} devuelveUltima={clickUltima}
                          recargarCab={() => setRecargar(!recargar)} datosFPs={datosFPs}
                          devuelveDatDoc={ctrlNuevoDatFact} paramsEmp={paramsEmp}
                          devuelveNuevoFiltro={ctrlNuevoFiltroBotones}
                          devuelveRepIndividual={clickRepIndividual}
                          devuelveFP={() => setOcultaFP(!ocultaFP)}
                        >{regActual.NO_FACTURA}
                        </Botones>
                      </Row>
                      <Row>
                        <EmpSel existentes={existentes} devuelveCambiaEmp={ctrlCambioEmp} esHeader={false} />
                      </Row>
                    </Container>
                  </Card.Footer>}
            </Card.Body>}
      <Encabezado
        existentes={existentes} modificando={modificando} sinEncabezado={filtrando}
        esHeader={false} noDocMod={modificando ? regActual.NO_FACTURA_MOD : ''}
      />
    </Card>
  )
}

export default Facturas
