import { memo } from 'react'

import Card from 'react-bootstrap/Card'

console.log('Backend MySQL: ' + process.env.REACT_APP_URL_BACKEND_MYSQL,
  'Backend Oracle: ' + process.env.REACT_APP_URL_BACKEND_ORCL)
const Header = () => {
  return (
    <header>
      <Card className='border-primary'>
        <Card.Header
          className='d-flex justify-content-between align-items-center bg-primary p-0'
        ><span className='fst-italic text-white'>&ensp;&ensp;Ing. Javier Araque D.</span>
          <span className='d-flex align-items-center h3 mb-0' style={{ fontWeight: 700 }}>Visual SAM<span className='fst-italic text-white' style={{ fontWeight: 600 }}>&ensp;Web</span></span>
          <span className='fst-italic text-white'>jaraque9264@gmail.com&ensp;&ensp;</span>
        </Card.Header>
      </Card>
    </header>
  )
}

export default memo(Header)
