import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import { Dropdown, SplitButton } from 'react-bootstrap'

import encryptStorage from '../../utils/encryptStorage'

const MnuClientes = () => {
  const navigate = useNavigate()
  const userVSAM = encryptStorage.getItem('userVSAM')
  if (!userVSAM) return null
  // Asigna si tiene acceso a todo el botón (menú Facturación y opción Ventas)
  const accMnuFact = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_FACTURACION) === -1
  const accMnuClientes = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_CLIENTES) === -1
  // const accCreaMod = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_CLI_CREA_MOD) === -1

  const ctrlClickClientes = (e) => {
    if (accMnuFact && accMnuClientes) {
      navigate('/clientes')
    } else {
      Swal.fire(
        'NO TIENE ACCESO AL MENU DE CLIENTES',
        'Elija otra opción de menú',
        'info'
      )
    }
  }

  return (
    <SplitButton
      className='bg-secondary-subtle me-3'
      size='sm'
      variant='outline-secondary text-black'
      title='Clientes'
      disabled={!accMnuFact || !accMnuClientes}
      onClick={ctrlClickClientes}
    >
      <Dropdown.Item
        className='p-0'
        size='sm'
        disabled={!accMnuFact || !accMnuClientes}
      >
        <Link
          className={'dropdown-item' + (accMnuFact && accMnuClientes ? '' : ' disabled')}
          to='/clientes' onClick={ctrlClickClientes}
        >Clientes
        </Link>
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        className='p-0'
        size='sm'
      >
        <Link
          className='dropdown-item'
          to='/clientes/reporte'
        >Reporte
        </Link>
      </Dropdown.Item>
    </SplitButton>
  )
}

export default MnuClientes
