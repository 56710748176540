import restAPIAxios from './axios'
import encryptStorage from './encryptStorage'

function verificaAuth (sesion) {
  // VERIFICA SI EL USUARIO ESTA AUTENTICADO O NO
  // Primera entrada del usuario (no hay ninguna información en sessionStorage)
  // if (Object.entries(sessionStorage).length === 0) {
  if (encryptStorage.length === 0) {
    return ({ ok: false, opcion: 4, msj: 'SIN STORAGE (enrutar a Login)' })
  // Verifica token en las siguientes entradas o al refrescar pantalla
  } else {
    // const datSesion = JSON.parse(sessionStorage.sesion)
    const datSesion = encryptStorage.getItem('sesion')
    if (sesion.auth && datSesion.token) {
      const verificaToken = async () => {
        const respuestaAPI = await restAPIAxios('',
          {
            method: 'get',
            url: '/verificaToken',
            headers: { Authorization: `Bearer ${sesion.token}` }
          }
        )
        // console.log(respuestaAPI.status, respuestaAPI)
        if (respuestaAPI.status === 200) {
          const verifica = respuestaAPI.data
          if (verifica.esValido) {
            // Actualiza solo si no estaba autenticado para evitar el bucle infinito
            if (sesion.auth) {
              return ({ ok: true, opcion: 1, msj: verifica.msj })
            } else {
              return ({ ok: true, opcion: 2, msj: 'TOKEN VALIDO PERO SIN AUTH (reasignar y continuar)' })
            }
          } else {
            if (verifica.expiro) {
              return ({ ok: false, opcion: 3, msj: 'EL TOKEN EXPIRO' })
            } else {
              return ({ ok: false, opcion: 4, msj: 'TOKEN INVALIDO (enrutar a Login)' })
            }
          }
        } else {
          return ({ ok: false, opcion: 0, msj: respuestaAPI })
        }
      }
      return verificaToken()
    } else {
      return ({ ok: false, opcion: 4, msj: 'SIN AUTH O SIN STORAGE (enrutar a Login)' })
    };
  }
}

export default verificaAuth
