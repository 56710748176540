const itemsCamposTabla = ['NO_ITEM_USER', 'CODIGO', 'DESCRIP', 'V_PVENTA', 'EXIST', 'DESCRIP_AUX', 'OTROS_COD',
  'GRUPO_COD', 'GRUPO_DESCRIP', 'ABREV', 'GRUPO_L_SERVICIO', 'UBICACION', 'NO_UND_MED']
const itemsTxtColsTabla = ['No.', 'Código', 'Descripción', 'PV 1', 'Exist.', 'Auxiliar', 'Otros códigos',
  'Cod.', 'Grupo', 'UM', 'Servicio', 'Ubicación', '']

// CONSTANTES GLOBALES
export const gcModulos = {
  admin: 'ADMIN', vtas: 'VENTAS', cpras: 'COMPRAS', inv: 'INVENTARIOS', transp: 'TRANSPORTE', bcos: 'BANCOS', prods: 'PRODUCTOS', cont: 'CONTABILIDAD', gen: 'GENERALES'
}
export const gcTiposDoc = {
  prof: 'PROF', fact: 'FACT', nc: 'NC', guia: 'GUIA', ret: 'RET', liqComp: 'LIQCOMP', ing: 'ING', egr: 'EGR'
}
export const gcTablasLogicas = {
  fact: 'FACTURAS',
  prof: 'PROFORMAS',
  ptoVta: 'PUNTOS DE VENTA',
  bodega: 'BODEGAS',
  vendedor: 'VENDEDORES',
  undMedXItem: 'UND.MED. X ITEM',
  tarjCre: 'TARJETAS DE CREDITO',
  cliente: 'CLIENTES',
  grupoItem: 'GRUPOS-ITEMS',
  item: 'ITEMS'
}
export const gcFmtosBotones = {
  conImagen: 'me-1 bg-transparent border-0',
  conImagenMenu: 'me-1 bg-primary-subtle border-0',
  dropDownItem: '',
  enTabla: 'd-flex align-items-center bg-success-subtle',
  styleTabla: { height: 20, maxWidth: 35 },
  imgTabla: 'mx-auto d-block',
  imgWidth: 22,
  imgWidthSM: 16,
  filtro: 'align-middle bg-secondary-subtle border border-secondary ps-1 pe-1'
}
export const gcFmtosTablas = (opcMenuCall) => {
  return {
    row: opcMenuCall ? 'mb-1' : 'm-3 justify-content-center',
    inputGroup: '',
    inputGStyle: opcMenuCall ? {} : { maxWidth: 800 },
    labelFiltro: opcMenuCall ? '' : 'fw-bold',
    filtro: '',
    rowEmp: 'ms-5 me-5'
  }
}
export const gcFmtoMaxRows = (rows, maxRows) => {
  if (!maxRows) maxRows = process.env.REACT_APP_MAX_REGS
  if (rows > 0) return rows >= maxRows ? ' bg-warning-subtle' : ''
  else return ''
}
export const gcTiposCliente = {
  O: 'Otros', TC: 'T.Crédito'
}
export const gcCliCrePeriodoMensual = {
  0: 'Sin período', 1: '1 mes', 6: '6 meses', 12: '1 año'
}
export const gcCliCreIniciaDiaMes = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
  11: '11',
  12: '12',
  13: '13',
  14: '14',
  15: '15',
  16: '16',
  17: '17',
  18: '18',
  19: '19',
  20: '20',
  21: '21',
  22: '22',
  23: '23',
  24: '24',
  25: '25',
  26: '26',
  27: '27',
  28: '28',
  29: '29',
  30: 'Ult.día'
}
// Obtiene el ancho disponible de la pantalla a partir del inicio de un elemento
export function anchoPantDisponible (idElemento) {
  const elemento = document.getElementById(idElemento)
  let posLeft = elemento === null ? 10 : elemento.getBoundingClientRect().left
  posLeft = posLeft < 0 ? 0 : posLeft
  return window.innerWidth - posLeft - 30
}
// Coloca el foco en el elemento con idName
export function colocaFocoDirecto (idName) {
  const campo = document.getElementById(idName)
  if (campo !== null) campo.focus()
}
// Asigna las series dependiendo del tipo de Documento
export function cargaSeries (tipoDoc, paramsEmp, series) {
  console.log('🚀 ~ file: generales.js:98 ~ cargaSeries ~ tipoDoc, paramsEmp, series:', tipoDoc, paramsEmp, series)
  // Por si acaso todavía no esté cargado paramsEmp
  if (!paramsEmp || !series) return [['001-001'], [], ['001-001']]

  let seriesEmp = ''
  let seriesUser = ''
  switch (tipoDoc) {
    case gcTiposDoc.fact: {
      seriesEmp = series.fact
      seriesUser = paramsEmp.SRI_SERIES_FACT_ELECT
      break
    } case gcTiposDoc.nc: {
      seriesEmp = series.ntaCre
      seriesUser = paramsEmp.SRI_SERIES_NC_ELECT
      break
    } case gcTiposDoc.guia: {
      seriesEmp = series.guia
      seriesUser = paramsEmp.SRI_SERIES_GUIA_ELECT
      break
    } case gcTiposDoc.ret: {
      seriesEmp = series.ret
      seriesUser = paramsEmp.SRI_SERIES_RET_ELECT
      break
    } case gcTiposDoc.liqComp: {
      seriesEmp = series.liqComp
      seriesUser = paramsEmp.SRI_SERIES_LIQCOMP_ELECT
      break
    } case gcTiposDoc.ing: {
      seriesEmp = series.ing
      seriesUser = ''
      break
    } case gcTiposDoc.egr: {
      seriesEmp = series.egr
      seriesUser = ''
      break
    } default:
  }
  console.log('🚀 ~ file: generales.js:135 ~ cargaSeries ~ seriesEmp, seriesUser:', seriesEmp, seriesUser)
  const seriesEUser = seriesUser.split(',')
  const seriesElect = []
  if (paramsEmp.L_FACT_ELECTRONICA === -1) {
    // Incluye las series definidas en el usuario, que estén dentro del listado de series de la empresa
    seriesEUser.forEach(elemento => {
      if (seriesEmp.includes(elemento)) seriesElect.push(elemento)
    })
  }
  const seriesFisicas = ['001-001']
  return [seriesElect.concat(seriesFisicas), seriesElect, seriesFisicas]
}
// Determina si el Documento Electrónico esta en Produccion o no
export function sriDatosDoc (pClaveDoc) {
  let esProducc = false; let estaAutoriz = false
  if (pClaveDoc) { if (pClaveDoc.substring(23, 24) === '2') esProducc = true }
  if (!/\D/.test(pClaveDoc) && pClaveDoc.length === 49) estaAutoriz = true
  return { esProducc, estaAutoriz }
}
export function rutaReps (rutaCarpetas, modulo) {
  let ruta = rutaCarpetas + `reportes/${modulo}/`
  if (rutaCarpetas.indexOf('\\') >= 0) {
    ruta = rutaCarpetas + `reportes\\${modulo}\\`
  }
  return ruta
}
export function rutaSRI (rutaCarpetas) {
  let rutaMain = rutaCarpetas + 'sri/main/'
  let rutaPDFXML = rutaCarpetas + 'sri/PDF_XML/'
  if (rutaCarpetas.indexOf('\\') >= 0) {
    rutaMain = rutaCarpetas + 'reportes\\main\\'
    rutaPDFXML = rutaCarpetas + 'reportes\\PDF_XML\\'
  }
  return { main: rutaMain, pdfxml: rutaPDFXML }
}

// FORMATOS
export function fechaLocalNueva (valor) {
  let fecha
  if (valor) fecha = new Date(valor)
  else fecha = new Date()
  // getTimezoneOffset(): Obtiene la diferencia en minutos entre la hora UTC y la hora local.
  const offset = fecha.getTimezoneOffset() * 60000 // Offset en milisegundos
  const fechaLocal = new Date(fecha.getTime() - offset)
  return fechaLocal
}
export function numberFormat (valor, decimales, sinFmto) {
  let valorFmto = 0
  if (!sinFmto) {
    valorFmto = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimales,
      maximumFractionDigits: decimales
    }).format(valor)
  } else {
    valorFmto = parseFloat(new Intl.NumberFormat('en-US', {
      minimumFractionDigits: decimales,
      useGrouping: false,
      maximumFractionDigits: decimales
    }).format(valor))
  }
  return valorFmto
}
export function fechaFormat (fecha, tipo, separacion) {
  if (!separacion) separacion = '/'
  const fResultado = fechaLocalNueva(fecha)
  const dia = fResultado.getDate()
  const mes = fResultado.getMonth()
  const anio4 = fResultado.getFullYear()
  const posMes = (dia < 10 ? 7 : 8)
  let res
  switch (tipo) {
    case 'AMD': {
      // getMonth() devuelven 1 mes o día menos porque el cálculo es 0-indexado
      const amd = anio4 + separacion +
        (mes + 1).toString().padStart(2, '0') + separacion +
        dia.toString().padStart(2, '0')
      return amd
    }
    case 'DMA': {
      const dma = dia.toString().padStart(2, '0') + separacion +
        (mes + 1).toString().padStart(2, '0') + separacion + anio4
      return dma
    }
    case 'MEDIA LARGA':
      res = fResultado.toLocaleDateString('es'
        , { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' })
      res = res.charAt(0).toUpperCase() + res.substring(1)
      res = res.substring(0, posMes) + res.charAt(posMes).toUpperCase() +
        res.substring(posMes + 1, posMes + 3) + separacion + res.substring(mes === 8 ? posMes + 5 : posMes + 4)
      return res
    case 'MEDIA LARGA HORA':
      res = fResultado.toLocaleDateString('es'
        , { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'UTC' })
      res = res.charAt(0).toUpperCase() + res.substring(1)
      // mes===8?posMes+5:posMes+4 porque toLocaleDateString() pone 4 caracteres para Septiembre (sept)
      res = res.substring(0, posMes) + res.charAt(posMes).toUpperCase() +
        res.substring(posMes + 1, posMes + 3) + separacion + res.substring(mes === 8 ? posMes + 5 : posMes + 4)
      return res
    case 'LARGA':
      res = fResultado.toLocaleDateString('es'
        , { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' })
      res = res.toUpperCase()
      return res
    case 'LARGA HORA':
      res = fResultado.toLocaleDateString('es'
        , { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'UTC' })
      res = res.toUpperCase()
      return res
    case 'SOLO HORA':
      res = fResultado.toLocaleDateString('es'
        , { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' })
      return res.slice(-5)
    default:
  }
}
export function fechaSumaDias (fecha, dias, separacion) {
  const fResultado = fechaLocalNueva(fecha)
  fResultado.setDate(fResultado.getDate() + dias)
  // timeZone: 'UTC' (Coordinated Universal Time) para que no ajuste a la zona horaria local
  const opciones = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' }
  const fmtoAMD = fResultado.toLocaleDateString('en-CA', opciones)
  // Si no pasa un caracter de separación se envia con el default '-'
  if (separacion) return [fResultado, fmtoAMD.replaceAll('-', separacion)]
  else return [fResultado, fmtoAMD]
}
export function fechaRestaFechas (f1, f2) {
  const diasEnMilisegundos = (1000 * 60 * 60 * 24)
  const f1Ok = fechaLocalNueva(f1)
  const f2Ok = fechaLocalNueva(f2)
  const dias = Math.ceil((f1Ok - f2Ok) / diasEnMilisegundos)
  return dias
}
// Para obtener el formato que se presenta sobre una columna
export function fmtosTablaFiltroCampo (tabla, campo, valor, decimales) {
  let ancho = 'auto'
  let fmto
  let texto
  const decMoneda = 2
  switch (typeof valor) {
    case 'string':
      fmto = ''
      texto = valor
      switch (campo.toUpperCase()) {
        case 'ID': ancho = 90; break
        case 'NOMBRE': ancho = 200; break
        case 'DIRECC': ancho = 200; break
        case 'TELEF': ancho = 150; break
        case 'FAX': ancho = 100; break
        case 'CATEGORIA': fmto = 'text-center'; break
        case 'EMAIL': ancho = 250; break
        case 'GRUPO_COD': fmto = 'text-center'; ancho = 40; break
        case 'GRUPO_DESCRIP': ancho = 150; break
        case 'CODIGO': if (tabla === gcTablasLogicas.item) ancho = 100; break
        case 'DESCRIP': ancho = 180; break
        case 'DESCRIP_AUX': ancho = 180; break
        case 'OTROS_COD': ancho = 250; break
        case 'TIPO':
          if (tabla === gcTablasLogicas.cliente) {
            fmto = 'text-center'
            texto = gcTiposCliente[valor]
          }
          break
        case 'ATTPERSONA': ancho = 150; break
        case 'VEND_NOMBRE': ancho = 150; break
        case 'OTROS_DATOS': ancho = 300; break
        case 'USER_NOMBRE': ancho = 130; break
        case 'USER_ABREV': fmto = 'text-center'; break
        case 'F_DESDE':
          ancho = 100
          if (valor) texto = fechaFormat(valor, 'MEDIA LARGA')
          break
        case 'F_MODIFICA':
          fmto = 'text-center'
          ancho = 130
          if (valor) texto = fechaFormat(valor, 'MEDIA LARGA HORA')
          break
        default:
          if (campo.toUpperCase().substr(0, 6) === 'DIRECC') ancho = 200
          if (campo.toUpperCase().substr(0, 7) === 'DESCRIP') ancho = 200
      }

      break
    case 'number':
      ancho = 90
      fmto = 'text-end'
      switch (campo.toUpperCase()) {
        case 'NO_ITEM_USER':
          ancho = 60
          texto = valor
          break
        case 'V_UNIT': texto = numberFormat(valor, decimales); break
        case 'V_PVENTA': texto = numberFormat(valor, decimales); break
        case 'EXIST':
          texto = numberFormat(valor, decimales)
          break
        case 'GRUPO_L_SERVICIO':
          ancho = 'auto'
          fmto = 'text-center'
          texto = valor === -1 ? '✔' : ''
          break
        case 'P_DSCTO':
          ancho = 60
          fmto = 'text-center'
          texto = valor
          if (tabla !== gcTablasLogicas.cliente) texto = valor * 100
          texto = numberFormat(texto, decMoneda)
          break
        case 'NO_PV':
          ancho = 'auto'
          fmto = 'text-center'
          texto = valor
          break
        case 'V_INI_X_COB':
          if (tabla === gcTablasLogicas.cliente) {
            ancho = 'auto'
            fmto = 'text-center'
            texto = valor
          } else texto = numberFormat(valor, decMoneda)
          break
        case 'VCRE_PERIODO_MESES':
          if (tabla === gcTablasLogicas.cliente) {
            ancho = 70
            fmto = 'text-center'
            texto = valor === null ? '' : gcCliCrePeriodoMensual[valor]
          } else texto = numberFormat(valor, decMoneda)
          break
        case 'VCRE_DIA_INI':
          if (tabla === gcTablasLogicas.cliente) {
            ancho = 70
            fmto = 'text-center'
            texto = valor === null ? '' : gcCliCreIniciaDiaMes[valor]
          } else texto = numberFormat(valor, decMoneda)
          break
        default:
          texto = valor
          if (campo.toUpperCase().substr(0, 2) === 'V_') {
            texto = numberFormat(valor, decMoneda)
          }
          if (campo.toUpperCase().substr(0, 5) === 'EXIST') {
            texto = numberFormat(valor, decimales)
          }
          if (campo.toUpperCase().substr(0, 2) === 'L_') {
            ancho = 'auto'
            fmto = 'text-center'
            texto = valor === -1 ? '✔' : ''
          }
      }
      break
    // case 'bigint':
    // case 'boolean':
    // case 'symbol':
    // case 'undefined':
    case 'object':
      fmto = ''
      texto = valor
      switch (campo.toUpperCase()) {
        case 'OTROS_COD' : ancho = 200; break
        case 'VCRE_PERIODO_MESES':
          if (tabla === gcTablasLogicas.cliente) {
            ancho = 70
            fmto = 'text-center'
            texto = valor === null ? '' : gcCliCrePeriodoMensual[valor]
          }
          break
        case 'VCRE_DIA_INI':
          if (tabla === gcTablasLogicas.cliente) {
            ancho = 70
            fmto = 'text-center'
            texto = valor === null ? '' : gcCliCreIniciaDiaMes[valor]
          }
          break
        default:
      }
      break
    default:
      fmto = ''
      texto = valor
  }
  return ({ ancho, fmto, texto })
}

// Información de BD para paso de propiedades en componentes que actualizan un campo de NUEVAS PROFORMAS
export function setInfoCampoProforma (campo, valor) {
  return {
    url: '/proformas/nuevas',
    tabla: 'T_PROFORMA_TMP',
    campo,
    valor,
    campoClave: 'NO_REG'
  }
}
export function setInfoCampoFactura (campo, valor) {
  return {
    url: '/facturas/nuevas',
    tabla: 'T_FACTURA_TMP',
    campo,
    valor,
    campoClave: 'NO_REG'
  }
}
// Información de BD para el paso de propiedades a componentes SELECT-OPTION
// PUNTOS DE VENTA7
export const infoPtoVtaSel = (fmto) => {
  return {
    url: '/ptosventa',
    maxRegs: 100,
    txtTabla: gcTablasLogicas.ptoVta,
    tabCampos: ['KEY_TAB', 'DESCRIP', 'TXT_TIPO', 'DIRECC', 'TELEF', 'EMAIL', 'NO_PV'],
    txtLblTabla: 'Pto. Venta:',
    txtCampos: ['No.', 'Descripción', 'Tipo', 'Dirección', 'Teléfono', 'Email', 'PV'],
    campoAdicional: 'KEY_TAB',
    ceroEsNulo: false,
    ancho: 0,
    fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
  }
}
// BODEGAS
export const infoBodegaSel = (fmto) => {
  return {
    url: '/bodegas',
    maxRegs: 100,
    txtTabla: gcTablasLogicas.bodega,
    tabCampos: ['KEY_TAB', 'DESCRIP', 'TXT_TIPO', 'DIRECC', 'TELEF', 'EMAIL', 'NO_PV'],
    txtLblTabla: 'Bodega:',
    txtCampos: ['No.', 'Descripción', 'Tipo', 'Dirección', 'Teléfono', 'Email', 'PV'],
    campoAdicional: 'KEY_TAB',
    ceroEsNulo: false,
    ancho: 0,
    fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
  }
}
// VENDEDORES
export const infoVendedorSel = (fmto, label) => {
  return {
    url: '/vendedores',
    maxRegs: process.env.REACT_APP_MAX_REGS,
    txtTabla: gcTablasLogicas.vendedor,
    tabCampos: ['CI', 'NOMBRE', 'DIRECC', 'TELEF', 'EMAIL'],
    txtLblTabla: (label ?? 'Vendedor') + ':',
    txtCampos: ['CI', 'Nombre', 'Dirección', 'Teléfono', 'Email'],
    campoAdicional: '',
    ceroEsNulo: true,
    ancho: 0,
    fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
  }
}
// UNIDADES DE MEDIDA X ITEM
export const infoUndMedXItemSel = (fmto) => {
  return {
    url: '/undmedxitem',
    maxRegs: 100,
    txtTabla: gcTablasLogicas.undMedXItem,
    tabCampos: ['KEY_TAB', 'ABREV', 'DESCRIP', 'NO_PVENTA', 'V_PVENTA', 'L_UNDMED_1', 'FACTOR_REL'],
    txtLblTabla: '',
    txtCampos: ['No.', 'Abrev.', 'Descripción', '#PV', 'P.V.', 'Básica', 'Factor Rel.'],
    campoAdicional: '',
    ceroEsNulo: false,
    ancho: 100,
    fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
  }
}
// TARJETAS DE CREDITO
export const infoTarjsCreSel = (fmto) => {
  return {
    url: '/tarjetasCre',
    maxRegs: process.env.REACT_APP_MAX_REGS,
    txtTabla: gcTablasLogicas.tarjCre,
    tabCampos: ['RUC', 'DESCRIP', 'DIRECC', 'TELEF', 'EMAIL'],
    txtLblTabla: 'Tarjetas Crédito:',
    txtCampos: ['RUC', 'Descripción', 'Dirección', 'Teléfono', 'Email'],
    campoAdicional: '',
    ceroEsNulo: true,
    ancho: 350,
    fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
  }
}

// Información de BD para el paso de propiedades a componentes TABLA-FILTRO
// // CLIENTES
// export const infoClienteFiltro = (deshabilitado, condBodega, fmto) => {
//   return {
//     url: '/clientes',
//     maxRegs: process.env.REACT_APP_MAX_REGS,
//     txtTabla: gcTablasLogicas.cliente,
//     tabCampos: ['ID', 'NOMBRE', 'DIRECC', 'TELEF', 'EMAIL', 'CATEGORIA'],
//     txtLblTabla: 'Cliente:',
//     txtCampos: ['ID', 'Nombre', 'Dirección', 'Teléfono', 'Email', 'Categoría'],
//     campoFiltro: { enTabla: 'NOMBRE', enSql: 'NOMBRE' },
//     condFija: '',
//     condBodega,
//     obligatorio: true,
//     deshabilitado,
//     sinPlaceHolder: false,
//     fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
//   }
// }
// GRUPOS DE ITEM - CODIGO      tipos de grupos 'CM','CV','SC','SM','SV','T','VM'
export const infoGItemCodFiltro = (deshabilitado, fmto) => {
  return {
    url: '/grupos-items',
    maxRegs: process.env.REACT_APP_MAX_REGS,
    txtTabla: gcTablasLogicas.grupoItem,
    tabCampos: ['CODIGO', 'DESCRIP'],
    txtLblTabla: 'Código:',
    txtCampos: ['Código', 'Descripción'],
    campoFiltro: { enTabla: 'CODIGO', enSql: 'CODIGO' },
    condFija: 'and TIPO IN (\'CV\',\'SV\',\'T\',\'VM\')',
    obligatorio: false,
    deshabilitado,
    sinPlaceHolder: true,
    fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
  }
}
// GRUPOS DE ITEM - DESCRIP
export const infoGItemDescripFiltro = (deshabilitado, fmto) => {
  return {
    url: '/grupos-items',
    maxRegs: process.env.REACT_APP_MAX_REGS,
    txtTabla: gcTablasLogicas.grupoItem,
    tabCampos: ['CODIGO', 'DESCRIP'],
    txtLblTabla: 'Descripción:',
    txtCampos: ['Código', 'Descripción'],
    campoFiltro: { enTabla: 'DESCRIP', enSql: 'DESCRIP' },
    condFija: 'and TIPO IN (\'CV\',\'SV\',\'T\',\'VM\')',
    obligatorio: false,
    deshabilitado,
    sinPlaceHolder: true,
    fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
  }
}
// ITEMS - NO_ITEM_USER
export const infoItemNoItemUserFiltro = (deshabilitado, condBodega, condGrupo, fmto) => {
  return {
    url: '/items',
    maxRegs: process.env.REACT_APP_MAX_REGS,
    txtTabla: gcTablasLogicas.item,
    txtLblTabla: 'No.:',
    tabCampos: itemsCamposTabla,
    txtCampos: itemsTxtColsTabla,
    campoFiltro: { enTabla: 'NO_ITEM_USER', enSql: 'A.NO_ITEM_USER' },
    condFija: 'and A.TIPO IN (\'CV\',\'SV\',\'T\',\'VM\')',
    condBodega,
    condGrupo,
    obligatorio: true,
    deshabilitado,
    sinPlaceHolder: true,
    fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
  }
}
// ITEMS - CODIGO
export const infoItemCodigoFiltro = (deshabilitado, condBodega, condGrupo, fmto) => {
  return {
    url: '/items',
    maxRegs: process.env.REACT_APP_MAX_REGS,
    txtTabla: gcTablasLogicas.item,
    txtLblTabla: <span><u>C</u>ódigo:</span>,
    tabCampos: itemsCamposTabla,
    txtCampos: itemsTxtColsTabla,
    campoFiltro: { enTabla: 'CODIGO', enSql: 'A.CODIGO' },
    condFija: 'and A.TIPO IN (\'CV\',\'SV\',\'T\',\'VM\')',
    condBodega,
    condGrupo,
    obligatorio: true,
    deshabilitado,
    sinPlaceHolder: true,
    fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
  }
}
// ITEMS - DESCRIP
export const infoItemDescripFiltro = (deshabilitado, condBodega, condGrupo, fmto) => {
  return {
    url: '/items',
    maxRegs: process.env.REACT_APP_MAX_REGS,
    txtTabla: gcTablasLogicas.item,
    txtLblTabla: 'Descripción:',
    tabCampos: itemsCamposTabla,
    txtCampos: itemsTxtColsTabla,
    campoFiltro: { enTabla: 'DESCRIP', enSql: 'A.DESCRIP' },
    condFija: 'and A.TIPO IN (\'CV\',\'SV\',\'T\',\'VM\')',
    condBodega,
    condGrupo,
    obligatorio: true,
    deshabilitado,
    sinPlaceHolder: true,
    fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
  }
}
// ITEMS - DESCRIP_AUX
export const infoItemDescripAuxFiltro = (deshabilitado, condBodega, condGrupo, fmto) => {
  return {
    url: '/items',
    maxRegs: process.env.REACT_APP_MAX_REGS,
    txtTabla: gcTablasLogicas.item,
    txtLblTabla: 'Auxiliar:',
    tabCampos: itemsCamposTabla,
    txtCampos: itemsTxtColsTabla,
    campoFiltro: { enTabla: 'DESCRIP_AUX', enSql: 'A.DESCRIP_AUX' },
    condFija: 'and A.TIPO IN (\'CV\',\'SV\',\'T\',\'VM\')',
    condBodega,
    condGrupo,
    obligatorio: false,
    deshabilitado,
    sinPlaceHolder: true,
    fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
  }
}
// ITEMS - OTROS_COD
export const infoItemOtrosCodFiltro = (deshabilitado, condBodega, condGrupo, fmto) => {
  return {
    url: '/items',
    maxRegs: process.env.REACT_APP_MAX_REGS,
    txtTabla: gcTablasLogicas.item,
    txtLblTabla: 'Otros códigos:',
    tabCampos: itemsCamposTabla,
    txtCampos: itemsTxtColsTabla,
    campoFiltro: { enTabla: 'OTROS_COD', enSql: 'A.OTROS_COD' },
    condFija: 'and A.TIPO IN (\'CV\',\'SV\',\'T\',\'VM\')',
    condBodega,
    condGrupo,
    obligatorio: false,
    deshabilitado,
    sinPlaceHolder: true,
    fmto: (fmto === undefined || fmto === '' ? ['', '', ''] : fmto)
  }
}
