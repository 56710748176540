import { useContext, useEffect, useState, useRef } from 'react'
import Swal from 'sweetalert2'

import { Form, InputGroup, Spinner } from 'react-bootstrap'

import restAPIAxios from '../../utils/axios'
import { SesionContext } from '../../context/SesionContext'

function TablaCampo (props) {
  // console.log('infoCampo', props.infoCampo.id)
  const [sesion] = useContext(SesionContext)
  const valTablaIni = props.infoCampo.type === 'date'
    ? props.valTabla.substr(0, 10)
    : (props.valTabla == null ? '' : props.valTabla)
  const [campo, setCampo] = useState(valTablaIni)
  const [cargando, setCargando] = useState(false)

  const campoFocus = useRef('')

  const infoTActualiza = props.infoTablaActualiza
  const claveReg = () => {
    // console.log(infoTActualiza, sesion.facturas)
    switch (infoTActualiza.tabla) {
      case 'T_PROFORMA_TMP': return sesion.proformas.clave
      case 'T_FACTURA_TMP': return sesion.facturas.clave
      default: return 0
    }
  }

  const poolName = sesion.orcl_host

  useEffect(() => {
    // console.log(txtFecha)
    setCampo(valTablaIni)
  }, [sesion, valTablaIni, props.infoCampo.type])

  if (!sesion.auth) return null

  function ctrlChangeCampo (e) {
    // e.preventDefault()
    setCampo(e.target.value)
    props.devuelveEditando(true)
  }

  function ctrlDblClick (e) {
    // e.preventDefault()
    if (props.devuelveDblClick === undefined) {
      Swal.fire(
        'NO ESTA ACTIVA LA OPCION DE ORDENADO PARA ESTE CAMPO',
        '',
        'info'
      )
    } else props.devuelveDblClick()
  }

  async function ctrlBlurCampo (e) {
    // e.preventDefault()
    props.devuelveEditando(false)
    let valCampoOK = e.target.value
    if (props.infoCampo.type === 'number') {
      valCampoOK = parseInt(isNaN(e.target.value) ? '0' : e.target.value)
      // Para evitar que aparezca en la consola
      // **Warning: Received NaN for the `value` attribute. If this is expected, cast the value to a string.
      if (isNaN(valCampoOK)) valCampoOK = ''
    }
    // ** Es necesario enviar siempre a procesar la actualización del campo cuando es del tipo DATE
    // ** porque si llama al calendario el foco ya no toma el valor inicial para su comparación
    if (valCampoOK !== campoFocus.current || props.infoCampo.type === 'date') {
      // No se actualiza el campo
      if (infoTActualiza === undefined) {
        if (props.devuelveVal !== undefined) props.devuelveVal(valCampoOK)
      // Actualiza el campo en la tabla
      } else {
        // Validaciones
        if (props.infoCampo.type !== 'text') {
          // console.log('e.target.max', e.target.max)
          if (e.target.max) {
            if (valCampoOK && valCampoOK > e.target.max) {
              Swal.fire(
                `EL VALOR NO PUEDE SER MAYOR ${e.target.max}`,
                'Se asignó el valor mayor permitido',
                'info'
              )
              // updateCampo = false
              // setNoProf(e.target.max)
              valCampoOK = e.target.max
            }
          } else if (valCampoOK && valCampoOK < e.target.min) {
            Swal.fire(
                `EL VALOR NO PUEDE SER MENOR A ${e.target.min}`,
                'Se asignó el valor menor permitido',
                'info'
            )
            // updateCampo = false
            // setNoProf(1)
            valCampoOK = e.target.min
          } else if (!props.infoCampo.ceroEsNulo && valCampoOK === '') {
            Swal.fire(
              'EL VALOR NO PUEDE SER NULO (VACIO)',
              'Se asignó el valor inicial',
              'info'
            )
            valCampoOK = valTablaIni
          }
        }
        // console.log(valCampoOK)
        // actualiza el Campo en la Tabla
        setCargando(true)
        const defProps = {
          poolName,
          tabla: infoTActualiza.tabla,
          campo: infoTActualiza.campo,
          valor: props.infoCampo.type === 'date' ? `to_date('${valCampoOK}', 'yyyy-mm-dd')` : valCampoOK,
          cond: infoTActualiza.campoClave + '=' + claveReg(),
          ceroEsNulo: props.infoCampo.ceroEsNulo
        }
        // console.log(defProps, props.infoCampo.type, valCampoOK)
        const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ACTUALIZAR EL CAMPO EN LA BD',
          {
            method: 'patch',
            url: infoTActualiza.url,
            data: defProps
          }
        )
        // console.log(respuestaAPI)
        if (respuestaAPI.status === 200) {
          setCargando(false)
          if (respuestaAPI.data[0] === 0) {
            setCampo(valTablaIni)
            if (props.devuelveVal !== undefined) props.devuelveVal(valTablaIni)
            Swal.fire(
              'EL VALOR NO SE ACTUALIZO EN LA BD',
              'consulte a su técnico',
              'info'
            )
          } else {
            setCampo(valCampoOK)
            // console.log(props.devuelveVal === undefined, valCampoOK)
            if (props.devuelveVal !== undefined) props.devuelveVal(valCampoOK)
          }
        } else {
          setCargando(false)
          // console.log(valTablaIni)
          setCampo(valTablaIni)
          if (props.devuelveVal !== undefined) props.devuelveVal(valTablaIni)
        }
      }
    }
  }

  const fmtoCampo = props.infoCampo.fmto === undefined ? '' : props.infoCampo.fmto[0]
  const fmtoLabel = props.infoCampo.fmto === undefined ? '' : props.infoCampo.fmto[1]
  const fmtoTexto = props.infoCampo.fmto === undefined ? '' : props.infoCampo.fmto[2]
  const fontSize = props.infoCampo.id === 'noProf'
    ? { minWidth: 105, maxWidth: 105 }
    : props.infoCampo.id === 'obsFact'
      ? { fontSize: '11px' }
      : {}
  // console.log(props.infoCampo, props.infoCampo.fmto, 'fmtoCampo', fmtoCampo, 'fmtoLabel', fmtoLabel, 'fmtoTexto', fmtoTexto)
  return (
    <InputGroup
      className={fmtoCampo}
      size='sm'
      hidden={props.infoCampo.hidden === undefined ? false : props.infoCampo.hidden}
    >
      <InputGroup.Text className={fmtoLabel} onDoubleClick={ctrlDblClick}>{props.infoCampo.label}</InputGroup.Text>
      <Spinner animation='grow' size='sm' hidden={!cargando} />
      <Form.Control
        className={fmtoTexto}
        size='sm'
        id={props.infoCampo.id}
        type={props.infoCampo.type}
        aria-describedby={props.infoCampo.id}
        min={props.infoCampo.type === 'text' ? null : props.infoCampo.min}
        max={props.infoCampo.type === 'text' ? null : props.infoCampo.max}
        minLength={props.infoCampo.type === 'text' ? props.infoCampo.min : null}
        maxLength={props.infoCampo.type === 'text' ? props.infoCampo.max : null}
        value={campo}
        style={fontSize}
        required={props.infoCampo.required}
        disabled={props.infoCampo.disabled}
        onChange={ctrlChangeCampo}
        onBlur={ctrlBlurCampo}
        onFocus={(e) => { campoFocus.current = campo }}
      />
      <InputGroup.Text id='txtDespues' hidden={props.infoCampo.txtDespues === ''}>{props.infoCampo.txtDespues}</InputGroup.Text>
    </InputGroup>
  )
}

export default TablaCampo
