/* eslint-disable react/jsx-indent */
import { useContext, useEffect, useState, useRef } from 'react'
import { Row, Col, InputGroup } from 'react-bootstrap'
import Swal from 'sweetalert2'

import { SesionContext } from '../../../context/SesionContext'
import restAPIAxios from '../../../utils/axios'
import encryptStorage from '../../../utils/encryptStorage'

import CampoNumerico from '../../generales/CampoNumerico'
import { numberFormat } from '../../../utils/generales'

function ItemsTransaccTotales (props) {
  const [sesion] = useContext(SesionContext)
  const [cargando, setCargando] = useState(false)

  const datosAPI = props.datosAPI
  const existentes = props.existentes
  const orden = props.orden
  const sesionAPI = () => {
    switch (datosAPI.urlBase) {
      case '/proformas': return sesion.proformas
      case '/facturas': return sesion.facturas
      default: return {}
    }
  }
  const userVSAM = encryptStorage.getItem('userVSAM')
  // Extrae los datos de la empresa (mysql). Parámetros de la empresa y usuario
  const userEmpConfig = encryptStorage.getItem('config')
  const poolName = sesion.orcl_host
  const decMoneda = 2
  const decimales = userEmpConfig.decimales
  const pDsctoMax = userEmpConfig.p_dscto_max

  const [totFijos, setTotFijos] = useState({ subtotal: 0, totDsctoItems: 0, totIVA0: 0 })
  const [pDsctoAdi, setPDsctoAdi] = useState(0)
  const [vDsctoAdi, setVDsctoAdi] = useState(0)
  const [vImponible, setVImponible] = useState(0)
  const [pIVA, setPIVA] = useState(0)
  const [vIVA, setVIVA] = useState(0)
  const [vIVA0, setVIVA0] = useState(0)
  const [totNeto, setTotNeto] = useState(0)

  const campoFoco = useRef(null)

  // Variables de Acceso
  const accDsctoAdi = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_VTAS_DSCTO_MOD) === -1
  const accModImp = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_MOD_IMP) === -1

  useEffect(() => {
    console.log('🚀 ~ file: ItemsTransaccTotales.jsx ~ useEffect')
    async function cargaTotales () {
      setCargando(true)
      const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE CARGAR LOS TOTALES${datosAPI.msjErrorDet}`,
        {
          method: 'get',
          url: existentes
            ? `${datosAPI.urlBase}/existente/totales/${poolName}/${sesion.orcl_usuarioVSAM}/${sesion.no_empresaVSAM}/${sesionAPI().clave}`
            : `${datosAPI.urlBase}/nueva/totales/${poolName}/${sesionAPI().clave}`
        }
      )
      setCargando(false)
      if (respuestaAPI.status === 200 && respuestaAPI.data.length > 0) {
        const res = respuestaAPI.data[0]
        setTotFijos({ subtotal: res.SUBTOTAL, totDsctoItems: res.TOT_DSCTO_ITEMS, totIVA0: res.TOT_IVA0 })
        setPDsctoAdi(numberFormat(res.P_DSCTO * 100, decimales, true))
        setVDsctoAdi(numberFormat(res.V_DSCTO, decMoneda, true))
        const tmpVIVA0 = numberFormat(res.V_IVA0, decMoneda, true)
        setVIVA0(tmpVIVA0)
        setVImponible(numberFormat(res.SUBTOTAL - tmpVIVA0 - res.V_DSCTO, decMoneda, true))
        setPIVA(numberFormat(res.P_IMP * 100, decimales, true))
        setVIVA(numberFormat(res.V_IMP, decMoneda, true))
        setTotNeto(numberFormat(res.SUBTOTAL - res.V_DSCTO + res.V_IMP, decMoneda, true))
        if (props.devuelveTots !== undefined) {
          props.devuelveTots({
            subtotal: res.SUBTOTAL,
            totDsctoItems: res.TOT_DSCTO_ITEMS,
            totIVA0: tmpVIVA0,
            pDsctoAdi: numberFormat(res.P_DSCTO * 100, decimales, true),
            vDsctoAdi: numberFormat(res.V_DSCTO, decMoneda, true),
            vImponible: numberFormat(res.SUBTOTAL - tmpVIVA0 - res.V_DSCTO, decMoneda, true),
            pIVA: numberFormat(res.P_IMP * 100, decimales, true),
            vIVA: numberFormat(res.V_IMP, decMoneda, true),
            totNeto: numberFormat(res.SUBTOTAL - res.V_DSCTO + res.V_IMP, decMoneda, true)
          })
        }
      // En caso de que la API devuelva error
      } else {
        setTotFijos({ subtotal: 0, totDsctoItems: 0, totIVA0: 0 })
        setPDsctoAdi(0)
        setVDsctoAdi(0)
        setVImponible(0)
        setPIVA(0)
        setVIVA(0)
        setTotNeto(0)
      }
    }
    cargaTotales()
    campoFoco.current = null
  }, [props.children, existentes, sesion])
  //* * No se puede incluir sesionAPI en las dependencias del useEffect porque es una función fuera del useEffect
  //* * No se puede incluir props en las dependencias del useEffect porque se queda en un bucle recursivo infinito

  // FUNCIONES PRIVADAS DEL COMPONENTE
  async function actualizaTots (pDsctoAdi, vDsctoAdi, pIVA, vIVA, vTotIVA0, vTotal) {
    const body = () => {
      switch (datosAPI.urlBase) {
        case '/proformas':
          return { poolName, noReg: sesionAPI().clave, pDsctoAdi, vDsctoAdi, pIVA, vIVA }
        case '/facturas':
          return { poolName, noReg: sesionAPI().clave, pDsctoAdi, vDsctoAdi, pIVA, vIVA, vTotIVA0, vTotal }
        default: return {}
      }
    }
    setCargando(true)
    const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ACTUALIZAR LOS TOTALES DEL DOCUMENTO',
      {
        method: 'patch',
        url: `${datosAPI.urlBase}/nueva/totales`,
        data: body()
      }
    )
    setCargando(false)
    if (respuestaAPI.status === 200) {
      return true
    // En caso de que la API devuelva error
    } else {
      return false
    }
  }
  // FUNCIONES DE CONTROL (EVENTOS) DE ELEMENTOS DEL COMPONENTE
  function ctrlDblClickDiasCre (e) {
    // e.preventDefault()
    let camposSinOrden = []
    switch (datosAPI.urlBase) {
      case '/proformas':
        camposSinOrden = ['V_IVA0', 'TOT_DSCTO_ITEMS', 'V_IMPONIBLE', 'V_TOTAL']
        break
      case '/facturas':
        camposSinOrden = ['TOT_DSCTO_ITEMS', 'V_IMPONIBLE']
        break
      default:
        break
    }
    if (props.devuelveDblClick === undefined || camposSinOrden.includes(e.target.id)) {
      Swal.fire(
        'NO ESTA ACTIVA LA OPCION DE ORDENADO PARA ESTE CAMPO',
        '',
        'info'
      )
    } else props.devuelveDblClick(e.target.id)
  }

  // FUNCIONES DE CONTROL (EVENTOS) DEVUELTOS POR LOS COMPONENTE
  function recalculaTotales (campo, valor) {
    let tmpPDscto = pDsctoAdi
    let tmpVDscto = vDsctoAdi
    let tmpVImponible = vImponible
    let tmpPIVA = pIVA
    let tmpVIVA = vIVA
    let tmpTotNeto = totNeto
    const subtotal = totFijos.subtotal
    let tmpIVA0 = vIVA0
    const maxTotNeto = numberFormat(subtotal + (subtotal - totFijos.totIVA0) * pIVA / 100, decMoneda, true)
    switch (campo) {
      case 'pDsctoAdi':
        tmpPDscto = numberFormat(valor, decimales, true)
        // Verifica que el %DSCTO no sea mayor al permitido
        if (tmpPDscto > pDsctoMax) {
          tmpPDscto = pDsctoMax
          Swal.fire(
              `EL % DSCTO. NO PUEDE SER MAYOR A ${pDsctoMax}`,
              'El usuario no tiene nivel de acceso para utilizar un mayor descuento',
              'info'
          )
        }
        tmpVDscto = numberFormat(subtotal * tmpPDscto / 100, decMoneda, true)
        tmpIVA0 = numberFormat(totFijos.totIVA0 * (1 - tmpPDscto / 100), decMoneda, true)
        tmpVImponible = numberFormat(subtotal - tmpIVA0 - tmpVDscto, decMoneda, true)
        // ** No se porque a veces se graba con -0
        if (tmpVImponible < 0 && tmpVImponible > -0.005) tmpVImponible = 0
        tmpVIVA = numberFormat(tmpVImponible * pIVA / 100, decMoneda, true)
        tmpTotNeto = numberFormat(subtotal - tmpVDscto + tmpVIVA, decMoneda, true)
        setPDsctoAdi(tmpPDscto)
        setVDsctoAdi(tmpVDscto)
        setVImponible(tmpVImponible)
        setVIVA(tmpVIVA)
        setVIVA0(tmpIVA0)
        setTotNeto(tmpTotNeto)
        break
      case 'vDsctoAdi':
        tmpVDscto = numberFormat(valor, decMoneda, true)
        tmpPDscto = subtotal === 0 ? 0 : numberFormat(tmpVDscto / subtotal * 100, decimales, true)
        // Verifica que el %DSCTO no sea mayor al permitido
        if (tmpPDscto > pDsctoMax) {
          tmpPDscto = pDsctoMax
          tmpVDscto = numberFormat(subtotal * tmpPDscto / 100, decMoneda, true)
          Swal.fire(
              `EL % DSCTO. NO PUEDE SER MAYOR A ${pDsctoMax}`,
              'El usuario no tiene nivel de acceso para utilizar un mayor descuento',
              'info'
          )
        }
        tmpIVA0 = numberFormat(totFijos.totIVA0 * (1 - tmpPDscto / 100), decMoneda, true)
        tmpVImponible = numberFormat(subtotal - tmpIVA0 - tmpVDscto, decMoneda, true)
        // ** No se porque a veces se graba con -0
        if (tmpVImponible < 0 && tmpVImponible > 0.005) tmpVImponible = 0
        tmpVIVA = numberFormat(tmpVImponible * pIVA / 100, decMoneda, true)
        tmpTotNeto = numberFormat(subtotal - tmpVDscto + tmpVIVA, decMoneda, true)
        setVDsctoAdi(tmpVDscto)
        setPDsctoAdi(tmpPDscto)
        setVImponible(tmpVImponible)
        setVIVA(tmpVIVA)
        setVIVA0(tmpIVA0)
        setTotNeto(tmpTotNeto)
        break
      case 'pIVA':
        tmpPIVA = numberFormat(valor, decimales, true)
        tmpVIVA = numberFormat(vImponible * tmpPIVA / 100, decMoneda, true)
        tmpTotNeto = numberFormat(subtotal - vDsctoAdi + tmpVIVA, decMoneda, true)
        setPIVA(tmpPIVA)
        setVIVA(tmpVIVA)
        setTotNeto(tmpTotNeto)
        break
      case 'vIVA':
        tmpVIVA = numberFormat(valor, decMoneda, true)
        tmpPIVA = vImponible === 0 ? 0 : numberFormat(tmpVIVA / vImponible * 100, decimales, true)
        tmpTotNeto = numberFormat(subtotal - vDsctoAdi + tmpVIVA, decMoneda, true)
        setPIVA(tmpPIVA)
        setVIVA(tmpVIVA)
        setTotNeto(tmpTotNeto)
        break
      case 'totNeto': {
        let cambioPDscto = false
        tmpTotNeto = numberFormat(valor, decMoneda, true)
        if (tmpTotNeto > maxTotNeto) {
          tmpTotNeto = maxTotNeto
          Swal.fire(
            'EL TOTAL NETO INGRESADO PRODUCIRIA UN DESCUENTO NEGATIVO',
            `El total neto no puede ser mayor a ${numberFormat(maxTotNeto, decMoneda, false)}`,
            'info'
          )
        }
        // Tomado de la formula en excel (FormulaVN.xlsx)
        tmpVDscto = numberFormat((subtotal * tmpTotNeto) /
          (totFijos.totIVA0 * pIVA / 100 - subtotal * (1 + pIVA / 100)) + subtotal, decMoneda, true)
        if (tmpVDscto < 0 && tmpVDscto > 0.005) tmpVDscto = 0
        tmpPDscto = numberFormat(subtotal === 0 ? 0 : tmpVDscto / subtotal * 100, decimales, true)
        // Verifica que el %DSCTO no sea mayor al permitido
        if (tmpPDscto > pDsctoMax) {
          tmpPDscto = pDsctoMax
          tmpVDscto = numberFormat(subtotal * tmpPDscto / 100, decMoneda, true)
          cambioPDscto = true
          Swal.fire(
              `EL % DSCTO. NO PUEDE SER MAYOR A ${pDsctoMax}`,
              'El usuario no tiene nivel de acceso para utilizar un mayor descuento',
              'info'
          )
        }
        tmpIVA0 = numberFormat(totFijos.totIVA0 * (1 - tmpPDscto / 100), decMoneda, true)
        tmpVImponible = numberFormat(subtotal - tmpIVA0 - tmpVDscto, decMoneda, true)
        // ** No se porque a veces se graba con -0
        if (tmpVImponible < 0 && tmpVImponible > -0.005) tmpVImponible = 0
        tmpVIVA = numberFormat(tmpVImponible * pIVA / 100, decMoneda, true)
        // Solo por si acaso haya cambiado el
        if (cambioPDscto) tmpTotNeto = numberFormat(subtotal - tmpVDscto + tmpVIVA, decMoneda, true)
        setTotNeto(tmpTotNeto)
        setPDsctoAdi(tmpPDscto)
        setVDsctoAdi(tmpVDscto)
        setVImponible(tmpVImponible)
        setVIVA(tmpVIVA)
        setVIVA0(tmpIVA0)
        break
      }
      default:
    }
    if (props.devuelveTots !== undefined) {
      props.devuelveTots({
        subtotal,
        pDsctoAdi: tmpPDscto,
        vDsctoAdi: tmpVDscto,
        vImponible: tmpVImponible,
        pIVA: tmpPIVA,
        vIVA: tmpVIVA,
        vIVA0: tmpIVA0,
        totNeto: tmpTotNeto
      })
    }

    actualizaTots(tmpPDscto, tmpVDscto, tmpPIVA, tmpVIVA, tmpIVA0, tmpTotNeto)
  }
  function asignaFocoTots (campo, e) {
    campoFoco.current = ''
    switch (campo) {
      case 'pDsctoAdi':
        if (e.key === 'Tab') campoFoco.current = e.shiftKey ? '' : 'vDsctoAdi'
        break
      case 'vDsctoAdi':
        if (e.key === 'Tab') campoFoco.current = e.shiftKey ? 'pDsctoAdi' : 'pIVA'
        break
      case 'pIVA':
        if (e.key === 'Tab') campoFoco.current = e.shiftKey ? 'vDsctoAdi' : 'vIVA'
        break
      case 'vIVA':
        if (e.key === 'Tab') campoFoco.current = e.shiftKey ? 'pIVA' : 'totNeto'
        break
      case 'totNeto':
        if (e.key === 'Tab') campoFoco.current = e.shiftKey ? 'vIVA' : ''
        break
      default:
    }
  }

  const fmtoTxtTotales = 'border border-primary text-primary fw-bold pt-0 pb-0'
  const fmtoTotTxt = 'ms-2 mb-1 pt-0'
  const fmtoTotValor = 'mb-1 pt-0 text-end'
  const fmtoOrden = existentes ? ' bg-primary text-white' : ''
  return (
    <>
      <div className='border border-primary mt-1' />
      {cargando
        ? <Row className='mt-2'>
            <div className='mt-2 text-center'>Cargando totales ...</div>
          </Row>
        : <Row className='mt-2 me-1 justify-content-center'>
            {/* Titulo y Subtotal */}
            <Col className='ms-2' xs={5} sm={6} md={3} lg={4} xl={2} xxl={2}>
              <InputGroup.Text className={fmtoTxtTotales} style={{ width: 95 }}>TOTALES</InputGroup.Text>
              <InputGroup className='mt-1' size='sm' style={{ minWidth: 200, maxWidth: 220 }}>
                <InputGroup.Text
                  id='SUBTOTAL' className={fmtoTotTxt + ' fw-bold' + (orden === 'SUBTOTAL' ? fmtoOrden : '')}
                  onDoubleClick={ctrlDblClickDiasCre}
                >Subtotal:
                </InputGroup.Text>
                <CampoNumerico
                  idName='subtotal' fmtoInput={fmtoTotValor + ' fw-bold'}
                  decimales={decMoneda} obligatorio={false} deshabilitado soloPositivo={false}
                >{totFijos.subtotal}
                </CampoNumerico>
              </InputGroup>
            </Col>
            {/* Total IVA 0% y Total Dscto. Items */}
            <Col xs={5} sm={5} md={4} lg={4} xl={2} style={{ minWidth: 220 }}>
              <InputGroup className='' size='sm' style={{ minWidth: 200, maxWidth: 220 }}>
                <InputGroup.Text
                  id='V_IVA0' className={fmtoTotTxt + (orden === 'V_IVA0' ? fmtoOrden : '')}
                  onDoubleClick={ctrlDblClickDiasCre}
                >IVA 0%:
                </InputGroup.Text>
                <CampoNumerico
                  idName='totIVA0' fmtoInput={fmtoTotValor}
                  decimales={decMoneda} obligatorio={false} deshabilitado soloPositivo={false}
                >{vIVA0}
                </CampoNumerico>
              </InputGroup>
              <InputGroup className='' size='sm' style={{ minWidth: 210, maxWidth: 230 }}>
                <InputGroup.Text
                  id='TOT_DSCTO_ITEMS' className={fmtoTotTxt + (orden === 'TOT_DSCTO_ITEMS' ? fmtoOrden : '')}
                  onDoubleClick={ctrlDblClickDiasCre}
                >Dscto. Items:
                </InputGroup.Text>
                <CampoNumerico
                  idName='totDsctoItems' fmtoInput={fmtoTotValor}
                  decimales={decMoneda} obligatorio={false} deshabilitado soloPositivo={false}
                >{totFijos.totDsctoItems}
                </CampoNumerico>
              </InputGroup>
            </Col>
            {/* Descuentos % y Valor */}
            <Col xs={6} sm={6} md={3} lg={3} xl={2} style={{ minWidth: 250 }}>
              <InputGroup className='' size='sm' style={{ minWidth: 240, maxWidth: 250 }}>
                <InputGroup.Text
                  id='P_DSCTO' className={fmtoTotTxt + (orden === 'P_DSCTO' ? fmtoOrden : '')}
                  onDoubleClick={ctrlDblClickDiasCre}
                >Dscto. Adic.:
                </InputGroup.Text>
                <CampoNumerico
                  idName='pDsctoAdi' devuelveVal={recalculaTotales} fmtoInput={fmtoTotValor}
                  decimales={decimales} obligatorio deshabilitado={existentes || !accDsctoAdi}
                  soloPositivo max={100} devuelveKeyUp={asignaFocoTots}
                >{pDsctoAdi}
                </CampoNumerico>
                <InputGroup.Text id='' className='me-2 pt-0 mb-1'>%</InputGroup.Text>
              </InputGroup>
              <InputGroup className='' size='sm' style={{ minWidth: 240, maxWidth: 250 }}>
                <InputGroup.Text
                  id='V_DSCTO' className={fmtoTotTxt + (orden === 'V_DSCTO' ? fmtoOrden : '')}
                  onDoubleClick={ctrlDblClickDiasCre}
                >V. Dscto. Adic.:
                </InputGroup.Text>
                <CampoNumerico
                  idName='vDsctoAdi' devuelveVal={recalculaTotales} fmtoInput={fmtoTotValor}
                  decimales={decMoneda} obligatorio deshabilitado={existentes || !accDsctoAdi}
                  soloPositivo devuelveKeyUp={asignaFocoTots}
                >{vDsctoAdi}
                </CampoNumerico>
              </InputGroup>
            </Col>
            {/* Imponible y Pctje. IVA */}
            <Col xs={6} sm={5} md={5} lg={6} xl={2} style={{ minWidth: 230 }}>
              <InputGroup className='' size='sm' style={{ minWidth: 220, maxWidth: 230 }}>
                <InputGroup.Text
                  id='V_IMPONIBLE' className={fmtoTotTxt + (orden === 'V_IMPONIBLE' ? fmtoOrden : '')}
                  onDoubleClick={ctrlDblClickDiasCre}
                >Imponible:
                </InputGroup.Text>
                <CampoNumerico
                  idName='vImponible' fmtoInput={fmtoTotValor} decimales={decMoneda}
                  obligatorio={false} deshabilitado soloPositivo={false} devuelveKeyUp={asignaFocoTots}
                >{vImponible}
                </CampoNumerico>
              </InputGroup>
              <InputGroup className='' size='sm' style={{ minWidth: 200, maxWidth: 210 }}>
                <InputGroup.Text
                  id='P_IMP' className={fmtoTotTxt + (orden === 'P_IMP' ? fmtoOrden : '')}
                  onDoubleClick={ctrlDblClickDiasCre}
                >IVA:
                </InputGroup.Text>
                <CampoNumerico
                  idName='pIVA' devuelveVal={recalculaTotales}
                  fmtoInput={fmtoTotValor} decimales={decimales} obligatorio
                  deshabilitado={!accModImp || existentes} soloPositivo devuelveKeyUp={asignaFocoTots}
                >{pIVA}
                </CampoNumerico>
                <InputGroup.Text id='' className='me-2 pt-0 mb-1'>%</InputGroup.Text>
              </InputGroup>
            </Col>
            {/* Valor IVA y Valor Total */}
            <Col xs={6} sm={6} md={4} xl={2}>
              <InputGroup className='' size='sm' style={{ minWidth: 210, maxWidth: 230 }}>
                <InputGroup.Text
                  id='V_IMP' className={fmtoTotTxt + (orden === 'V_IMP' ? fmtoOrden : '')}
                  onDoubleClick={ctrlDblClickDiasCre}
                >Valor IVA:
                </InputGroup.Text>
                <CampoNumerico
                  idName='vIVA' devuelveVal={recalculaTotales}
                  fmtoInput={fmtoTotValor} decimales={decMoneda} obligatorio
                  deshabilitado={!accModImp || existentes} soloPositivo devuelveKeyUp={asignaFocoTots}
                >{vIVA}
                </CampoNumerico>
              </InputGroup>
              <InputGroup className='' size='sm' style={{ minWidth: 220, maxWidth: 230 }}>
                <InputGroup.Text
                  id='V_TOTAL' className={fmtoTotTxt + ' fw-bold' + (orden === 'V_TOTAL' ? fmtoOrden : '')}
                  onDoubleClick={ctrlDblClickDiasCre}
                >Total:
                </InputGroup.Text>
                <CampoNumerico
                  idName='totNeto' devuelveVal={recalculaTotales}
                  fmtoInput={fmtoTotValor + ' bg-primary-subtle fw-bold'} decimales={decMoneda} obligatorio
                  deshabilitado={existentes} soloPositivo devuelveKeyUp={asignaFocoTots}
                >{totNeto}
                </CampoNumerico>
              </InputGroup>
            </Col>
          </Row>}
      <div className='border border-primary mt-1' />
    </>
  )
}

export default ItemsTransaccTotales
