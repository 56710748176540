/* eslint-disable react/jsx-indent */
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import { Button, Card, Col, Container, Row, Spinner } from 'react-bootstrap'

import { nuevaSesion, useSesion } from '../../../context/SesionContext.jsx'
import restAPIAxios, { restAPIMySQL } from '../../../utils/axios.js'
import encryptStorage from '../../../utils/encryptStorage.js'

import Encabezado from './ProfEncabezado.jsx'
import EmpSel from '../../admin/EmpSel.jsx'
import Clientes from '../clientes/Clientes.jsx'
import Botones from './ProfBotones.jsx'
import TablaOpcion from '../../generales/TablaOpcion.jsx'
import TablaCampo from '../../generales/TablaCampo.jsx'
import TablaCampoFecha from '../../generales/TablaCampoFecha.jsx'
import ItemsTransacciones from '../../productos/items/ItemsTransacciones.jsx'
import ListaExistentes from './ProfListaExistentes.jsx'
import BarraAuditoria from '../../generales/BarraAuditoria.jsx'

import {
  infoPtoVtaSel, infoVendedorSel, setInfoCampoProforma, gcTiposDoc, gcTablasLogicas,
  rutaReps,
  gcModulos
} from '../../../utils/generales.js'

import { useGetParamsEmp, useVerificaToken } from '../../../hooks/admin.jsx'
import { useForzaFiltroEnRefresh } from '../../../hooks/generales.jsx'

function Proformas ({ existentes }) {
  const navigate = useNavigate()
  const [sesion, setSesion] = useSesion()

  const [datProf, setDatProf] = useState([])
  const [datDetalles, setDatDetalles] = useState([])
  const [noPtoVta, setNoPtoVta] = useState(null)
  const [whereExist, setWhereExist] = useState('')
  const [ordenExist, setOrdenExist] = useState('NO_PROFORMA')
  const [fechaCond, setFechaCond] = useState('=')

  const [listando, setListando] = useState(false)
  const [grabando, setGrabando] = useState(false)
  const [cargando, setCargando] = useState(false)
  const [editando, setEditando] = useState(false)
  const [recargar, setRecargar] = useState(false) // Bandera para recargar la cabecera

  const datExistentes = useRef([])
  const poolName = sesion.orcl_host
  const modificando = sesion.proformas.modificando
  const filtrando = sesion.proformas.filtrando

  const noMinRegsListado = 10

  // VERIFICA TOKEN
  // ** Debe ir antes de cualquier uso de sesion porque aqui se puede recargar los valores de sesion del local storage
  useVerificaToken()

  // Extrae los datos de la empresa (mysql)
  const userEmpConfig = encryptStorage.getItem('config')

  // Controla el nivel de acceso al menú de Proformas
  let accMnuProf = false
  let accMnuVtas = false
  let accCejaProf = false
  const userVSAM = encryptStorage.getItem('userVSAM')
  if (userVSAM) {
    // Asigna si tiene acceso a todo el botón (menú Facturación y opción Ventas)
    accMnuProf = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_FACTURACION) === -1
    accMnuVtas = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_VENTAS) === -1
    accCejaProf = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_PROF_CEJA) === -1
  }
  // Carga los parametros de la empresa
  const paramsEmp = useGetParamsEmp()

  // Solo para pasar al Filtrando cuando se refresca la pantalla en /proformas/existentes
  // ya que al refrescarla directamente desde windows se queda cargando datos indefinidamente
  // porque al refrescar la pantalla se pierde datProf. Por lo tanto hay que volver al filtrado de datos
  useForzaFiltroEnRefresh({ tipoDoc: gcTiposDoc.prof, setListando })

  // Carga las Proformas NUEVAS (del usuario y la empresa respectivos)
  // o Carga Cabecera Proformas vacio para filtro
  useEffect(() => {
    const noMaxProf = userEmpConfig.numeros_maximos ? userEmpConfig.numeros_maximos.proforma : undefined
    console.log('🚀 ~ file: Proformas.jsx ~ useEffect:', 'Cargando Proformas...', noMaxProf)
    async function cargaProfNuevas () {
      setListando(false)
      const respuestaAPI = await restAPIAxios(`NO SE PUDO CARGAR
                ${modificando ? ' LA PROFORMA PARA SU MODIFICACION' : ' LAS PROFORMAS NUEVAS'}`,
      {
        method: 'get',
        url: `/proformas/nuevas/${poolName}/${sesion.orcl_usuarioVSAM}/${sesion.no_usuarioVSAM}/${sesion.no_empresaVSAM}/${modificando ? 'SI' : 'NO'}/${noMaxProf}`
      }
      )
      if (respuestaAPI.status === 200) {
        // En caso de que la BD todavía no haya creado el registro
        setDatProf(respuestaAPI.data)
        if (respuestaAPI.data.length > 0) {
          let indexProf
          let claveProf
          if (modificando) {
            // Se coloca en la proforma que desea modificar (por si acaso haya mas registros para modificar)
            indexProf = respuestaAPI.data.findIndex(registro => {
              return (registro.NO_REG === sesion.proformas.clave)
            })
            if (indexProf === -1) indexProf = 0
            claveProf = respuestaAPI.data[indexProf].NO_REG
          } else {
            // Verifica que el índice de sesión sea válido
            indexProf = respuestaAPI.data.length - 1 < sesion.proformas.index ? 0 : sesion.proformas.index
            claveProf = respuestaAPI.data[indexProf].NO_REG
          }
          setNoPtoVta(respuestaAPI.data[indexProf].NO_PTOVENTA)
          if (sesion.proformas.index !== indexProf || sesion.proformas.clave !== claveProf) {
            const nSesion = nuevaSesion(false, sesion,
              {
                proformas: { clave: claveProf, index: indexProf, modificando, filtrando }
              })
            setSesion(nSesion)
          }
        } else {
          const nSesion = nuevaSesion(false, sesion,
            {
              proformas: { clave: 0, index: 0, modificando: false, filtrando: true }
            })
          setSesion(nSesion)
          Swal.fire(
            'NO EXISTEN PROFORMAS EN PROCESO DE MODIFICACION',
            'Seleccione una proforma para su modificación',
            'info'
          )
          navigate('/proformas/existentes')
        }
        // En caso de que la API devuelva error
      } else {
        navigate('/bienvenida')
      }
    }

    function setBuscarExistentes () {
      if (datExistentes.current.length > 0) {
        setDatProf(datExistentes.current)
        const nSesion = nuevaSesion(false, sesion,
          {
            proformas: {
              clave: datExistentes.current[0].NO_PROFORMA,
              index: 0,
              modificando: false,
              filtrando: false
            }
          })
        setSesion(nSesion)
        setListando(false)
      } else {
        setListando(false)
        setDatProf([{
          CLI_NOMBRE: '',
          NO_PTOVENTA: 0,
          NO_PROFORMA: '',
          NO_CLIENTE: '',
          NO_VENDEDOR: '',
          FECHA: '',
          VALIDEZ_OFERTA: '',
          TIEMPO_ENTREGA: '',
          CONDICION_OFERTA: '',
          ATENCION_PERSONA: '',
          OBS: '',
          NO_PROFORMA_MOD: 0
        }
        ])
        setNoPtoVta(0)
        if (sesion.proformas.index !== 0 || sesion.proformas.clave !== 0) {
          const nSesion = nuevaSesion(false, sesion,
            {
              proformas: { clave: 0, index: 0, modificando: false, filtrando: true }
            })
          setSesion(nSesion)
        }
      }
    }

    // Si no tiene acceso a la opción de menú Proformas
    if (!accMnuProf || !accMnuVtas || !accCejaProf) {
      Swal.fire(
        'NO TIENE ACCESO A PROFORMAR',
        'Intente acceder a otras páginas utilizando las opciones de menú disponibles',
        'info'
      )
      navigate('/bienvenida')
    } else if (filtrando) setBuscarExistentes()
    else if (!existentes || modificando) cargaProfNuevas()
  }, [existentes, filtrando, modificando, sesion, recargar])

  // Funciones privadas del componente
  function profValida () {
    const modificando = sesion.proformas.modificando
    const fCerradoHasta = paramsEmp.F_CERRADOHASTA === null ? '' : paramsEmp.F_CERRADOHASTA.substring(0, 10)
    if (fCerradoHasta >= datProf[sesion.proformas.index].FECHA.substring(0, 10)) {
      Swal.fire(
        `NO PUEDE ${modificando ? 'MODIFICAR' : 'CREAR'} PROFORMAS ANTERIORES A ` +
            paramsEmp.F_CERRADOHASTA.substring(0, 10),
        'Cambie la fecha actual por una superior a la indicada',
        'info'
      )
      return false
    }
    // Verifica que EXISTAN ITEMS
    if (datDetalles.length === 0) {
      Swal.fire(
        `NO PUEDE ${modificando ? 'MODIFICAR' : 'CREAR'} PROFORMAS SIN ITEMS`,
        'Cree por lo menos un item en la proforma antes de grabarla',
        'info'
      )
      return false
    }
    return true
  }
  async function cargaProfExistentes (where, orden, estaOrdenando) {
    setCargando(true)
    const noProfTmp = datProf[sesion.proformas.index].NO_PROFORMA
    const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CARGAR LAS PROFORMAS',
      {
        method: 'post',
        url: '/proformas/existentes',
        data: { poolName, orclUserVSAM: sesion.orcl_usuarioVSAM, noEmp: sesion.no_empresaVSAM, where, orden }
      }
    )
    if (respuestaAPI.status === 200) {
      // No existen Proformas con esas condiciones.  Se queda en buscar
      if (respuestaAPI.data.length === 0) {
        Swal.fire(
          'NO EXISTEN PROFORMAS CON ESAS CONDICIONES',
          'Cambie las condiciones e intente nuevamente',
          'info'
        )
      } else {
        setListando(existentes && respuestaAPI.data.length > noMinRegsListado && !estaOrdenando)
        setDatProf(respuestaAPI.data)
        datExistentes.current = respuestaAPI.data

        // Verifica que el índice de sesión sea válido
        const indexProfTmp = respuestaAPI.data.findIndex((reg) => reg.NO_PROFORMA === noProfTmp)
        const indexProf = estaOrdenando ? (indexProfTmp === -1 ? 0 : indexProfTmp) : 0
        const claveProf = respuestaAPI.data[indexProf].NO_PROFORMA
        setNoPtoVta(respuestaAPI.data[indexProf].NO_PTOVENTA)
        const nSesion = nuevaSesion(false, sesion,
          {
            proformas: { clave: claveProf, index: indexProf, modificando: false, filtrando: false }
          })
        setSesion(nSesion)
      }
      // En caso de que la API devuelva error
    } else {
      setRecargar(!recargar)
    }
    setCargando(false)
  }

  // FUNCIONES DE CONTROL (EVENTOS) DE ELEMENTOS DEL COMPONENTE
  function ctrlClickConsultar (e) {
    if (e !== undefined) e.preventDefault()
    // Crea el WHERE con las condiciones establecidas por el usuario
    function condicion (datos) {
      const noProforma = datos.NO_PROFORMA.toString()
      let where = ''
      if (noPtoVta > 0) where = ' and A.NO_PTOVENTA = ' + noPtoVta
      if (noProforma !== '') {
        const pos = noProforma.toString().indexOf('-')
        if (pos === 0) {
          where = where + ` and A.NO_PROFORMA <= ${noProforma.substring(pos + 1)}`
        } else if (pos === (noProforma.length - 1)) {
          where = where + ` and A.NO_PROFORMA >= ${noProforma.substring(0, pos)}`
        } else if (pos > 0) {
          noProforma.substring(pos).length > 1
            ? where = where + ` and A.NO_PROFORMA >= ${noProforma.substring(0, pos)}` +
                ` and A.NO_PROFORMA <= ${noProforma.substring(pos + 1)}`
            : where = where + ` and A.NO_PROFORMA >= ${noProforma.substring(0, pos)}`
        } else where = where + ` and A.NO_PROFORMA = ${noProforma}`
      }
      if (datos.CLI_NOMBRE !== null && datos.CLI_NOMBRE !== '') {
        where = where + ` and B.NOMBRE like '${datos.CLI_NOMBRE.replaceAll('*', '%')}%'`
      }
      if (datos.NO_VENDEDOR !== null && datos.NO_VENDEDOR !== '') {
        where = where + ` and A.NO_VENDEDOR = ${datos.NO_VENDEDOR}`
      }
      if (datos.FECHA !== '') {
        where = where + ` and to_char(A.FECHA, 'yyyy-mm-dd') ${fechaCond} '${datos.FECHA}'`
      }
      if (datos.VALIDEZ_OFERTA !== null && datos.VALIDEZ_OFERTA !== '') {
        where = where + ` and A.VALIDEZ_OFERTA = ${datos.VALIDEZ_OFERTA}`
      }
      if (datos.TIEMPO_ENTREGA !== null && datos.TIEMPO_ENTREGA !== '') {
        if (datos.TIEMPO_ENTREGA === '*') {
          where = where + ' and not A.TIEMPO_ENTREGA is null'
          where = where + " and replace(replace(A.TIEMPO_ENTREGA, chr(13), '|'), chr(10), '|') <> '||'"
        } else {
          where = where + ` and A.TIEMPO_ENTREGA like '${datos.TIEMPO_ENTREGA.replaceAll('*', '%')}%'`
        }
      }
      if (datos.CONDICION_OFERTA !== null && datos.CONDICION_OFERTA !== '') {
        if (datos.CONDICION_OFERTA === '*') {
          where = where + ' and not A.CONDICION_OFERTA is null'
          where = where + " and replace(replace(A.CONDICION_OFERTA, chr(13), '|'), chr(10), '|') <> '||'"
        } else {
          where = where + ` and A.CONDICION_OFERTA like '${datos.CONDICION_OFERTA.replaceAll('*', '%')}%'`
        }
      }
      if (datos.ATENCION_PERSONA !== null && datos.ATENCION_PERSONA !== '') {
        if (datos.ATENCION_PERSONA === '*') {
          where = where + ' and not A.ATENCION_PERSONA is null'
        } else {
          where = where + ` and A.ATENCION_PERSONA like '${datos.ATENCION_PERSONA.replaceAll('*', '%')}%'`
        }
      }
      if (datos.OBS !== null && datos.OBS !== '') {
        if (datos.OBS === '*') {
          where = where + ' and not A.OBS is null'
          where = where + " and replace(replace(A.OBS, chr(13), '|'), chr(10), '|') <> '||'"
        } else {
          where = where + ` and A.OBS like '${datos.OBS.replaceAll('*', '%')}%'`
        }
      }
      return where
    }

    const tmp = condicion(datProf[0])
    setWhereExist(tmp)
    cargaProfExistentes(tmp, ordenExist, false)
  }

  // FUNCIONES DE CONTROL (EVENTOS) DEVUELTOS POR LOS COMPONENTE
  function ctrlCambioEmp () {
    ctrlNuevoDatProf([], false, true)
  }
  function clickAnterior () {
    const nSesion = nuevaSesion(false, sesion,
      {
        proformas: {
          clave: existentes
            ? datProf[sesion.proformas.index - 1].NO_PROFORMA
            : datProf[sesion.proformas.index - 1].NO_REG,
          index: sesion.proformas.index - 1,
          modificando: sesion.proformas.modificando,
          filtrando: sesion.proformas.filtrando
        }
      })
    setSesion(nSesion)
  }
  function click10Ant () {
    const nSesion = nuevaSesion(false, sesion,
      {
        proformas: {
          clave: existentes
            ? datProf[sesion.proformas.index - 10].NO_PROFORMA
            : datProf[sesion.proformas.index - 10].NO_REG,
          index: sesion.proformas.index - 10,
          modificando: sesion.proformas.modificando,
          filtrando: sesion.proformas.filtrando
        }
      })
    setSesion(nSesion)
  }
  function clickPrimera () {
    const nSesion = nuevaSesion(false, sesion,
      {
        proformas: {
          clave: existentes
            ? datProf[0].NO_PROFORMA
            : datProf[0].NO_REG,
          index: 0,
          modificando: sesion.proformas.modificando,
          filtrando: sesion.proformas.filtrando
        }
      })
    setSesion(nSesion)
  }

  function clickSiguiente () {
    const nSesion = nuevaSesion(false, sesion,
      {
        proformas: {
          clave: existentes
            ? datProf[sesion.proformas.index + 1].NO_PROFORMA
            : datProf[sesion.proformas.index + 1].NO_REG,
          index: sesion.proformas.index + 1,
          modificando: sesion.proformas.modificando,
          filtrando: sesion.proformas.filtrando
        }
      })
    setSesion(nSesion)
  }
  function click10Sig () {
    const nSesion = nuevaSesion(false, sesion,
      {
        proformas: {
          clave: existentes
            ? datProf[sesion.proformas.index + 10].NO_PROFORMA
            : datProf[sesion.proformas.index + 10].NO_REG,
          index: sesion.proformas.index + 10,
          modificando: sesion.proformas.modificando,
          filtrando: sesion.proformas.filtrando
        }
      })
    setSesion(nSesion)
  }
  function clickUltima () {
    const nSesion = nuevaSesion(false, sesion,
      {
        proformas: {
          clave: existentes
            ? datProf[datProf.length - 1].NO_PROFORMA
            : datProf[datProf.length - 1].NO_REG,
          index: datProf.length - 1,
          modificando: sesion.proformas.modificando,
          filtrando: sesion.proformas.filtrando
        }
      })
    setSesion(nSesion)
  }
  function clickListado (index) {
    setListando(false)
    const nSesion = nuevaSesion(false, sesion,
      {
        proformas: {
          clave: existentes
            ? datProf[index].NO_PROFORMA
            : datProf[index].NO_REG,
          index,
          modificando: sesion.proformas.modificando,
          filtrando: sesion.proformas.filtrando
        }
      })
    setSesion(nSesion)
  }
  function ctrlNuevoFiltroLista () {
    datExistentes.current = []
    setFechaCond('=')
    setListando(false)
    const nSesion = nuevaSesion(false, sesion,
      {
        proformas: {
          clave: sesion.proformas.clave,
          index: sesion.proformas.index,
          modificando: sesion.proformas.modificando,
          filtrando: true
        }
      })
    setSesion(nSesion)
    navigate('/proformas/existentes')
  }
  function dblClickOrdenar (campo) {
    if (existentes && !filtrando) {
      const orden = campo === undefined ? ordenExist : campo
      setOrdenExist(orden)
      cargaProfExistentes(whereExist, orden, true)
    }
  }
  function asignaFechaCond (cond) {
    switch (cond) {
      case '1': setFechaCond('>'); break
      case '2': setFechaCond('>='); break
      case '3': setFechaCond('<'); break
      case '4': setFechaCond('<='); break
      default: setFechaCond('=')
    }
  }
  function ctrlNuevoFiltroBotones () {
    if (existentes) datExistentes.current = []
    const nSesion = nuevaSesion(false, sesion,
      {
        proformas: {
          clave: sesion.proformas.clave,
          index: sesion.proformas.index,
          modificando: sesion.proformas.modificando,
          filtrando: true
        }
      })
    setSesion(nSesion)
    navigate('/proformas/existentes')
  }
  function ctrlNuevoDatProf (nuevoDatProf, hagaSetDatProf, asigneExistentes) {
    // Viene de una modificación (ya esta en existentes)
    if (hagaSetDatProf) {
      setDatProf(nuevoDatProf)
    }
    // Creo una nueva factura y quiere guardar los datos para cuando pase a existentes
    // o cambió de empresa
    if (asigneExistentes) {
      datExistentes.current = nuevoDatProf
      setRecargar(!recargar)
    }
  }
  async function clickRepIndividual (formato, conEmail) {
    if (conEmail && !regActual.CLI_EMAIL) {
      Swal.fire(
        'EL CLIENTE NO TIENE NINGUN CORREO ELECTRONICO ASIGNADO PARA EL ENVIO DE LA PROFORMA',
        'La proforma no puede ser enviada por correo electrónico',
        'info'
      )
      return
    }

    let url = '/proformas/reportes/individual'
    if (formato === 'INTERNO') url = '/proformas/reportes/interno'
    console.log('🚀 ~ file: Proformas.jsx:506 ~ clickRepIndividual ~ url:', url, formato)

    const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CREAR EL REPORTE SOLICITADO DE LA PROFORMA',
      {
        method: 'post',
        url,
        data: {
          cabeceras: datProf[sesion.proformas.index],
          detalles: datDetalles,
          rutaCarpetas: userEmpConfig.ruta_carpetas,
          saveFile: conEmail
        },
        responseType: 'blob'
      }
    )
    if (respuestaAPI.status === 200) {
      // Envía el Email con el attach del PDF
      if (conEmail) {
        let fileName = 'proforma.pdf'
        if (respuestaAPI.headers['content-disposition']) {
          fileName = respuestaAPI.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
        }

        setCargando(true)
        const resAPIEmail = await restAPIMySQL('ERROR AL ENVIAR AL CLIENTE LA PROFORMA POR CORREO ELECTRONICO',
          {
            method: 'post',
            url: '/sendEmail',
            data: {
              emailConfig: sesion.email_config,
              datos: {
                from: `${regActual.EMP_NOMBRE} - ${sesion.userNombre}`,
                to: `${regActual.CLI_EMAIL}`,
                subject: `Proforma No. ${regActual.NO_PROFORMA}`,
                text: `Estimado ${regActual.CLI_NOMBRE}`,
                html: `<p style="text-align: center">${regActual.EMP_NOMBRE} le envía adjunto la <b>Proforma No. ${regActual.NO_PROFORMA}</b></p>`
              },
              configAttach: {
                filename: `Proforma No. ${regActual.NO_PROFORMA}.pdf`,
                path: rutaReps(userEmpConfig.ruta_carpetas, gcModulos.vtas) + fileName
              }
            }
          }
        )
        setCargando(false)
        if (resAPIEmail.status === 200) {
          Swal.fire(
            `LA PROFORMA SE ENVIO CON EXITO A "${regActual.CLI_NOMBRE}"`,
            `Email ${regActual.CLI_EMAIL}`,
            'info'
          )
        }
      } else {
        let pdf = new Blob([respuestaAPI.data], { type: 'application/pdf' })
        pdf = window.URL.createObjectURL(pdf)
        window.open(pdf)
      }
      // // Para obtener el nombre de archivo devuelto en las cabeceras
      // let fileName = 'proforma.pdf'
      // if (respuestaAPI.headers['content-disposition']) {
      //   fileName = respuestaAPI.headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
      // }
      // console.log('🚀 ~ file: Proformas.jsx:557 ~ clickRepIndividual ~ fileName:', fileName)
      // // Para bajarse el archivo (con el nombre devuelto fileName) automáticamente en Descargas
      // const link = document.createElement('a')
      // link.href = pdf
      // link.setAttribute('download', fileName)
      // document.body.appendChild(link)
      // link.click()
      // document.body.removeChild(link)
    }
  }

  // Evento que controla la presion de teclas
  // function colocaFoco(e){
  //     //** No se puede usar porque deja de funcionar el teclado
  //     //e.preventDefault()
  //     if (e.altKey && e.ctrlKey && (e.key === 'c' || e.key === 'C')) {
  //         // Coloca el foco en el Código de Grupo
  //         //const itemCant = document.getElementById('itemCant')
  //         const itemCod = document.getElementById('tablaFiltro-ITEMS-CODIGO')
  //         itemCod.focus()
  //     }
  // }

  const fmtoOrden = existentes && !filtrando ? 'bg-primary text-white' : ''
  if (!sesion.auth || datProf.length === 0 || cargando || sesion.proformas.index > datProf.length - 1) {
    return (
      <div className='m-5 h3 text-center'>
        <Spinner animation='grow' />Cargando datos ...
      </div>
    )
  }
  let regActual
  if (datProf.length === 0) regActual = []
  else regActual = datProf[filtrando ? 0 : sesion.proformas.index]
  return (
    <Card className='border-light'>
      <Encabezado
        existentes={existentes} modificando={modificando}
        noProfMod={modificando ? regActual.NO_PROFORMA_MOD : ''} sinEncabezado={false}
      />
      {listando
        ? <ListaExistentes
            registros={datProf} devuelveClickListado={clickListado}
            devuelveClickNuevoFiltro={ctrlNuevoFiltroLista} orden={ordenExist}
          />
        : grabando
          ? <div className='m-5 h3 text-center'>
              <Spinner animation='grow' />Grabando ...
            </div>
          : <Card.Body
              className={'p-0' + (existentes || modificando ? ' bg-dark-subtle' : '')}
            >
            {/* EMPRESAS Y BOTONES */}
              <Card.Title className=''>
                <Container className='p-0'>
                  <Row>
                    <EmpSel existentes={existentes} devuelveCambiaEmp={ctrlCambioEmp} esHeader />
                  </Row>
                  <Row>
                    {filtrando
                      ? <Container className=''>
                        <Row className='h6 p-0 m-0 text-secondary justify-content-center bg-dark-subtle'>
                          <span className='text-center p-1'>Si desea establezca valores en los campos (un filtro):&nbsp;&nbsp;</span>
                          <Button
                            className=''
                            size='sm'
                            style={{ width: 100 }}
                            onClick={ctrlClickConsultar}
                            disabled={cargando}
                          >Consultar
                          </Button>
                        </Row>
                        </Container>
                      : <Botones
                          totalRegs={datProf.length} profValida={profValida}
                          estaGrabando={(valor) => setGrabando(valor)} devuelveListar={() => setListando(true)}
                          datosReg={regActual} existentes={existentes} recargarCab={() => setRecargar(!recargar)}
                          devuelveAnterior={clickAnterior} devuelveSiguiente={clickSiguiente}
                          devuelve10Ant={click10Ant} devuelve10Sig={click10Sig} editando={editando}
                          devuelvePrimera={clickPrimera} devuelveUltima={clickUltima}
                          devuelveDatDoc={ctrlNuevoDatProf}
                          devuelveNuevoFiltro={ctrlNuevoFiltroBotones}
                          devuelveRepIndividual={clickRepIndividual}
                        >{regActual.NO_PROFORMA}
                        </Botones>}
                  </Row>
                </Container>
              </Card.Title>
              {/* CABECERA SIN TOTALES */}
              <>
                {/* Ptos.Venta, fecha, validez y No. Proforma */}
                <Row className='justify-content-between ms-2 me-2 mb-1' xs={1} sm={3} md={4} lg={4}>
                  <Col className='p-0' style={{ width: 420 }}>
                    <TablaOpcion
                      valTabla={noPtoVta}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoProforma('NO_PTOVENTA', noPtoVta)}
                      infoTablaLista={infoPtoVtaSel(['', ordenExist === 'PTO_VENTA' ? fmtoOrden : '', ''])}
                      devuelveDatos={({ nuevoVal, index, descrip }) => setNoPtoVta(nuevoVal)}
                      deshabilitado={existentes && !filtrando}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('PTO_VENTA')}
                      devuelveEditando={(valor) => setEditando(valor)}
                    />
                  </Col>
                  <Col
                    className='rounded border border-primary-subtle bg-info-subtle text-center fw-bold'
                    style={{ width: 120 }}
                    hidden={!existentes || filtrando || regActual.ESTADO !== 'A'}
                  >ANULADA
                  </Col>
                  <Col className='p-0' xs='auto' sm='auto' md='auto' lg='auto' xl='auto' xxl='auto'>
                    <TablaCampoFecha
                      infoCampo={
                        {
                          fmto: ['', ordenExist === 'FECHA' ? fmtoOrden : '', ''],
                          id: 'fechaProf',
                          label: 'Fecha:',
                          required: true,
                          disabled: (existentes && !filtrando),
                          conCondFiltro: filtrando,
                          showHora: existentes && !filtrando
                        }
                      }
                      valTabla={modificando ? regActual.FECHA_OK : regActual.FECHA}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoProforma('FECHA', regActual.FECHA)}
                      devuelveVal={(valor) => { datProf[filtrando ? 0 : sesion.proformas.index].FECHA = valor }}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('FECHA')}
                      devuelveEditando={(valor) => setEditando(valor)}
                      devuelveCond={(valor) => asignaFechaCond(valor)}
                    />
                  </Col>
                  <Col className='p-0' style={{ width: 195 }}>
                    <TablaCampo
                      infoCampo={
                        {
                          fmto: ['', ordenExist === 'VALIDEZ_OFERTA' ? fmtoOrden : '', 'text-end'],
                          type: 'number',
                          id: 'validezOferta',
                          label: 'Validez oferta:',
                          txtDespues: 'dias',
                          min: 0,
                          max: null,
                          required: false,
                          disabled: (existentes && !filtrando),
                          ceroEsNulo: true
                        }
                      }
                      valTabla={regActual.VALIDEZ_OFERTA}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoProforma('VALIDEZ_OFERTA', regActual.VALIDEZ_OFERTA)}
                      devuelveVal={(valor) => { datProf[filtrando ? 0 : sesion.proformas.index].VALIDEZ_OFERTA = valor }}
                      deshabilidado={existentes && !filtrando}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('VALIDEZ_OFERTA')}
                      devuelveEditando={(valor) => setEditando(valor)}
                    />
                  </Col>
                  <Col className='p-0' style={{ width: 150 }}>
                    <TablaCampo
                      infoCampo={
                                      {
                                        fmto: ['', ordenExist === 'NO_PROFORMA' ? fmtoOrden : '',
                                          'text-end bg-primary-subtle fw-bold'],
                                        type: existentes ? 'text' : 'number',
                                        id: 'noProf',
                                        label: 'No.',
                                        txtDespues: '',
                                        min: 1,
                                        max: 99999999,
                                        required: true,
                                        disabled: (existentes && !filtrando),
                                        ceroEsNulo: false
                                      }
                                  }
                      valTabla={regActual.NO_PROFORMA}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoProforma('NO_PROFORMA', regActual.NO_PROFORMA)}
                      devuelveVal={(valor) => { datProf[filtrando ? 0 : sesion.proformas.index].NO_PROFORMA = valor }}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('NO_PROFORMA')}
                      devuelveEditando={(valor) => setEditando(valor)}
                    />
                  </Col>
                </Row>
                {/* Cliente y vendedor */}
                <Row className='justify-content-between ms-2 me-2'>
                  <Col className='p-0 pe-2' xs={8}>
                  <Clientes
                    noClienteIni={regActual.NO_CLIENTE} opcMenuCall={gcTablasLogicas.prof}
                    filtrando={filtrando} existentes={existentes} updateTablaDoc
                    devuelveFiltro={(valor) => { datProf[filtrando ? 0 : sesion.proformas.index].CLI_NOMBRE = valor }}
                    devuelveRegistro={(txtCampoTabla, filtro, regCliente) => {
                      datProf[filtrando ? 0 : sesion.proformas.index].NO_CLIENTE = regCliente.KEY_TAB
                    }}
                    devuelveDblClick={() => dblClickOrdenar('CLI_NOMBRE')}
                  >{regActual.CLI_NOMBRE}
                  </Clientes>
                  {/* <TablaFiltro
                    infoTablaActualiza={existentes
                      ? undefined
                      : setInfoCampoProforma('NO_CLIENTE', regActual.NO_CLIENTE)}
                    infoTablaLista={infoClienteFiltro(existentes && !filtrando, ''
                      , ['', ordenExist === 'CLI_NOMBRE' ? fmtoOrden : '',
                        regActual.NO_CLIENTE === null ? 'bg-warning-subtle' : ''])}
                    devuelveFiltro={(valor) => { datProf[filtrando ? 0 : sesion.proformas.index].CLI_NOMBRE = valor }}
                    devuelveRegistro={(txtCampoTabla, fitro, regCliente) => {
                      datProf[filtrando ? 0 : sesion.proformas.index].NO_CLIENTE = regCliente.KEY_TAB
                    }}
                    devuelveDblClick={(campoOrden) => dblClickOrdenar('CLI_NOMBRE')}
                  >{regActual.CLI_NOMBRE}
                  </TablaFiltro> */}
                  </Col>
                  <Col className='p-0'>
                    <TablaOpcion
                      valTabla={regActual.NO_VENDEDOR}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoProforma('NO_VENDEDOR', regActual.NO_VENDEDOR)}
                      infoTablaLista={infoVendedorSel(['', ordenExist === 'VENDEDOR' ? fmtoOrden : '', ''])}
                      devuelveDatos={({ nuevoVal, index, descrip }) => {
                        datProf[filtrando ? 0 : sesion.proformas.index].NO_VENDEDOR = nuevoVal
                      }}
                      deshabilitado={existentes && !filtrando}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('VENDEDOR')}
                      devuelveEditando={(valor) => setEditando(valor)}
                    />
                  </Col>
                </Row>
                {/* Tiempo y condición entrega */}
                <Row className='justify-content-between ms-2 me-2 mb-1'>
                  <Col className='p-0 me-2'>
                    <TablaCampo
                      infoCampo={
                                      {
                                        fmto: ['', ordenExist === 'TIEMPO_ENTREGA' ? fmtoOrden : '', ''],
                                        type: 'text',
                                        id: 'tiempoEntrega',
                                        label: 'Tiempo de entrega:',
                                        txtDespues: '',
                                        min: null,
                                        max: 100,
                                        required: false,
                                        disabled: (existentes && !filtrando),
                                        ceroEsNulo: true
                                      }
                                  }
                      valTabla={regActual.TIEMPO_ENTREGA}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoProforma('TIEMPO_ENTREGA', regActual.TIEMPO_ENTREGA)}
                      devuelveVal={(valor) => { datProf[filtrando ? 0 : sesion.proformas.index].TIEMPO_ENTREGA = valor }}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('TIEMPO_ENTREGA')}
                      devuelveEditando={(valor) => setEditando(valor)}
                    />
                  </Col>
                  <Col className='p-0'>
                    <TablaCampo
                      infoCampo={
                        {
                          fmto: ['', ordenExist === 'CONDICION_OFERTA' ? fmtoOrden : '', ''],
                          type: 'text',
                          id: 'condicionEntrega',
                          label: userEmpConfig.textos_labels.profCondEntrega || 'Condición entrega:',
                          txtDespues: '',
                          min: null,
                          max: 100,
                          required: false,
                          disabled: (existentes && !filtrando),
                          ceroEsNulo: true
                        }
                      }
                      valTabla={regActual.CONDICION_OFERTA}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoProforma('CONDICION_OFERTA', regActual.CONDICION_OFERTA)}
                      devuelveVal={(valor) => { datProf[filtrando ? 0 : sesion.proformas.index].CONDICION_OFERTA = valor }}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('CONDICION_OFERTA')}
                      devuelveEditando={(valor) => setEditando(valor)}
                    />
                  </Col>
                </Row>
                {/* Atención persona y observaciones */}
                <Row className='justify-content-between ms-2 me-2 mb-2'>
                  <Col className='p-0 me-2' xs={4}>
                    <TablaCampo
                      infoCampo={
                        {
                          fmto: ['', ordenExist === 'ATENCION_PERSONA' ? fmtoOrden : '', ''],
                          type: 'text',
                          id: 'attPersona',
                          label: 'Atención persona:',
                          txtDespues: '',
                          min: null,
                          max: 30,
                          required: false,
                          disabled: (existentes && !filtrando),
                          ceroEsNulo: true
                        }
                      }
                      valTabla={regActual.ATENCION_PERSONA}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoProforma('ATENCION_PERSONA', regActual.ATENCION_PERSONA)}
                      devuelveVal={(valor) => { datProf[filtrando ? 0 : sesion.proformas.index].ATENCION_PERSONA = valor }}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('ATENCION_PERSONA')}
                      devuelveEditando={(valor) => setEditando(valor)}
                    />
                  </Col>
                  <Col className='p-0'>
                    <TablaCampo
                      infoCampo={
                        {
                          fmto: ['', ordenExist === 'OBS' ? fmtoOrden : '', ''],
                          type: 'text',
                          id: 'obsProf',
                          label: userEmpConfig.textos_labels.profObs || 'Observaciones:',
                          txtDespues: '',
                          min: null,
                          max: 250,
                          required: false,
                          disabled: (existentes && !filtrando),
                          ceroEsNulo: true
                        }
                      }
                      valTabla={regActual.OBS}
                      infoTablaActualiza={existentes
                        ? undefined
                        : setInfoCampoProforma('OBS', regActual.OBS)}
                      devuelveVal={(valor) => { datProf[filtrando ? 0 : sesion.proformas.index].OBS = valor }}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar('OBS')}
                      devuelveEditando={(valor) => setEditando(valor)}
                    />
                  </Col>
                </Row>
                {filtrando
                  ? null
                  : <ItemsTransacciones
                      paramsEmp={paramsEmp} existentes={existentes} orden={ordenExist}
                      datosAPI={{ urlBase: '/proformas', msjErrorDet: ' DE LA PROFORMA' }}
                      devuelveDets={(detalles) => setDatDetalles(detalles)}
                      noCliente={regActual.NO_CLIENTE} fechaDoc={regActual.FECHA}
                      devuelveDblClick={(campoOrden) => dblClickOrdenar(campoOrden)}
                    >{noPtoVta}
                    </ItemsTransacciones>}
              </>
              {/* BARRA DE AUDITORIA */}
              {existentes && !filtrando
                ? <BarraAuditoria
                    userNo={datProf[sesion.proformas.index].NO_USUARIO}
                    userNombre={datProf[sesion.proformas.index].USER_NOMBRE}
                    fModifica={datProf[sesion.proformas.index].F_MODIFICA}
                  />
                : null}
              {/* EMPRESAS Y BOTONES */}
              {filtrando || grabando
                ? null
                : <Card.Footer className='mt-1 pb-0 pt-0 border-0'>
                    <Container className='p-0'>
                      <Row>
                        <Botones
                          totalRegs={datProf.length} profValida={profValida}
                          estaGrabando={(valor) => setGrabando(valor)} devuelveListar={() => setListando(true)}
                          datosReg={regActual} existentes={existentes} recargarCab={() => setRecargar(!recargar)}
                          devuelveAnterior={clickAnterior} devuelveSiguiente={clickSiguiente}
                          devuelve10Ant={click10Ant} devuelve10Sig={click10Sig} editando={editando}
                          devuelvePrimera={clickPrimera} devuelveUltima={clickUltima}
                          devuelveDatDoc={ctrlNuevoDatProf}
                          devuelveNuevoFiltro={ctrlNuevoFiltroBotones}
                          devuelveRepIndividual={clickRepIndividual}
                        >{regActual.NO_PROFORMA}
                        </Botones>
                      </Row>
                      <Row><EmpSel existentes={existentes} devuelveCambiaEmp={ctrlCambioEmp} esHeader={false} /></Row>
                    </Container>
                  </Card.Footer>}
            </Card.Body>}
      <Encabezado
        existentes={existentes} modificando={modificando}
        noProfMod={modificando ? regActual.NO_PROFORMA_MOD : ''} sinEncabezado={filtrando}
      />
    </Card>
  )
}

export default Proformas
