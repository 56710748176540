import { useEffect, useState, useContext, useRef, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import { Form, Spinner } from 'react-bootstrap'

// Importa el cliente Axios
import restAPIAxios from '../../utils/axios'
import { SesionContext, nuevaSesion } from '../../context/SesionContext'
import encryptStorage from '../../utils/encryptStorage'

import { getParamsUserEmp } from '../../utils/bd'

function EmpSel ({ existentes, devuelveCambiaEmp, esHeader }) {
  const navigate = useNavigate()

  // Trabajar con el STATE de REACT
  const [empresas, setEmpresas] = useState([])

  // Utilizo CONTEXT para tomar los valores de AUTORIZACION (sesion)
  // y colocar o no el componente
  const [sesion, setSesion] = useContext(SesionContext)

  // Extrae los datos del usuario VSAM para capturar los niveles de acceso
  const userVSAM = encryptStorage.getItem('userVSAM')
  // Extrae los datos de la empresa (mysql). Parámetros de la empresa y usuario
  const userEmpConfig = encryptStorage.getItem('config')

  const accCambiarEmp = useRef(false)
  const poolName = sesion.orcl_host
  const noUser = userEmpConfig.id

  useEffect(() => {
    console.log('🚀 ~ file: EmpSel.jsx ~ useEffect', 'Cargando Empresas ...')
    // Query a la API usando Axios
    async function obtieneEmps () {
      const empList = await restAPIAxios('NO FUE POSIBLE CARGAR LAS EMPRESAS',
        {
          method: 'get',
          url: `/empresas/${poolName}/${sesion.orcl_usuarioVSAM}`
        }
      )
      if (empList.status === 200) {
        setEmpresas(empList.data)
        try {
          // Asigna la empresa default del usuario VSAM
          const indexEmpUserVSAM = empList.data.findIndex(empresa => {
            return empresa.NO_INSTIT === sesion.no_empresaVSAM
          })
          // Si no tiene acceso a cambiar empresas y la empresa asignada al usuario VSAM no existe
          // -- INCONSISTENCIA
          if (!accCambiarEmp.current && indexEmpUserVSAM === -1) {
            setSesion(nuevaSesion(true))
            Swal.fire({
              title: 'ERROR DE INCONSISTENCIA',
              text: 'Consulte a su técnico',
              footer: 'El usuario (VSAM) no tiene asignada correctamente una empresa disponible',
              icon: 'error'
            })
            navigate('/login')
          }
        } catch (error) {
          console.log(error)
        }
      } else {
        setSesion(nuevaSesion(true))
        navigate('/login')
      }
    };
    // Para que no llame a la función obtieneEmps() cuando todavía no hay un login válido
    // Y por si acaso todavía no se hayan asignado los datos en userVSAM
    if (sesion.auth && userVSAM) {
      if (userVSAM) {
        // Asigna si tiene acceso a cambiar empresas
        accCambiarEmp.current = (userVSAM.RESTRICC_ACC_PARAM.indexOf(process.env.REACT_APP_ACC_CAMBIAR_EMP) === -1)
        obtieneEmps()
      }
    };
  }, [poolName, sesion.orcl_usuarioVSAM, sesion.token])
  // Solo van las dependencias que se usan para llamar a la RestAPI que obtiene las empresas

  async function ctrlCambiaEmp (e) {
    // e.preventDefault()
    const nuevoNoEmp = e.target.value
    // Query a la API usando Axios
    const paramsUserEmp = await getParamsUserEmp(noUser, nuevoNoEmp)
    if (paramsUserEmp.status === 200) {
      if (paramsUserEmp.datos.length === 0) {
        // Si no esta creado el registro de la empresa respectiva para el usuario activo
        // -- INCONSISTENCIA
        Swal.fire({
          title: 'ERROR DE INCONSISTENCIA',
          text: 'Consulte a su técnico',
          footer: 'No existen parámetros creados para la empresa seleccionada',
          icon: 'info'
        })
      } else {
        const nSesion = nuevaSesion(false, sesion, {
          no_empresaVSAM: nuevoNoEmp,
          proformas: { clave: 0, index: 0, modificando: sesion.proformas.modificando, filtrando: existentes },
          facturas: { clave: 0, index: 0, modificando: sesion.facturas.modificando, filtrando: existentes }
        })
        setSesion(nSesion)
        encryptStorage.setItem('config', paramsUserEmp.datos[0])
        devuelveCambiaEmp()
      }
    } else {
      setSesion(nuevaSesion(true))
      navigate('/login')
    }
  }

  if (!sesion.auth) return null

  // const fmtoCardTitle = 'border border-primary-subtle rounded mb-0'
  const fmtoSelEmp = 'border border-primary bg-primary-subtle rounded text-uppercase text-center fw-bold' +
        (esHeader ? ' mt-1' : ' mb-1')
  const fmtoSelEmpDisabled = 'border border-primary bg-primary-subtle rounded text-uppercase text-center fw-light' +
    (esHeader ? ' mt-1' : ' mb-1')
  if (empresas.length === 0) {
    return (
      <>
        <Spinner animation='border' size='sm' />
        <Form.Select
          size='sm'
          className='bg-secondary-subtle rounded'
          disabled
        >
          <option>Cargando empresas...</option>
        </Form.Select>
      </>
    )
  } else {
    // Asigna la empresa default del usuario web
    const indexEmpUserVSAM = empresas.findIndex(empresa => {
      return empresa.NO_INSTIT === sesion.no_empresaVSAM
    })
    // Si no tiene acceso a cambiar empresas y si existe la empresa del usuario VSAM en la lista
    if (!accCambiarEmp.current && indexEmpUserVSAM >= 0) {
      return (
        <Form.Select
          name='selEmpresa'
          size='sm'
          className={fmtoSelEmpDisabled}
          disabled
        >
          <option
            id={empresas[indexEmpUserVSAM].NO_INSTIT}
            key={empresas[indexEmpUserVSAM].NO_INSTIT}
          >
            {empresas[indexEmpUserVSAM].DESCRIP + ' (' + empresas[indexEmpUserVSAM].NO_INSTIT + ')'}
          </option>
        </Form.Select>
      )
    }

    // Si tiene acceso a cambiar empresas (carga todas las empresas)
    return (
      <Form.Select
        className={fmtoSelEmp + ' fw-bold'}
        name='selEmpresa'
        size='sm'
        value={`${sesion.no_empresaVSAM}`}
        onChange={ctrlCambiaEmp}
      >
        {empresas.map(
          empresa => (
            <option
              value={`${empresa.NO_INSTIT}`}
              key={empresa.NO_INSTIT}
            >
              {empresa.DESCRIP + ' (' + empresa.NO_INSTIT + ')'}
            </option>
          )
        )}
      </Form.Select>
    )
  }
}

export default EmpSel
