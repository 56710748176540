import { EncryptStorage } from 'encrypt-storage'

const encryptStorage = new EncryptStorage(process.env.REACT_APP_PALABRA_ENCRYPT,
  {
    prefix: 'vsamWeb',
    storageType: 'sessionStorage'
  })

// export const asyncEncryptStorage = new AsyncEncryptStorage(process.env.REACT_APP_PALABRA_ENCRYPT,
//   {
//     prefix: 'vsamWeb',
//     storageType: 'sessionStorage'
//   });

// export async function getValDesencriptado(key) {
//   return await encryptStorage.getItem(key);
// }

export default encryptStorage
