import { Card } from 'react-bootstrap'

function Encabezado ({ existentes, modificando, noDocMod, sinEncabezado, esHeader }) {
  const fmto = existentes
    ? 'h5 bg-dark-subtle text-center fw-bold p-0'
    : modificando
      ? 'h5 bg-success-subtle text-center fw-bold p-0'
      : 'h5 bg-secondary-subtle text-center fw-bold p-0'
  const noDocModValido = noDocMod === undefined || noDocMod === null ? '' : noDocMod
  const modNC = noDocModValido.substring(0, 1) === 'B'
  const texto = existentes
    ? 'FACTURAS EXISTENTES'
    : modificando
      ? `MODIFICANDO ${(modNC ? 'LA NOTA DE CREDITO #' : 'LA FACTURA #') + noDocModValido.substring(1)}`
      : 'FACTURAS NUEVAS'

  if (sinEncabezado) return null
  return (
    <>
      {esHeader
        ? <Card.Header className={fmto}>{texto}</Card.Header>
        : <Card.Footer className={fmto}>{texto}</Card.Footer>}
    </>
  )
}

export default Encabezado
