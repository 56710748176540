import { fechaFormat } from '../../utils/generales'

function BarraAuditoria ({ userNo, userNombre, fModifica }) {
  // console.log(userNo, userNombre, fechaFormat(fModifica, 'LARGA HORA'))
  return (
    <div
      className='text-center bg-success-subtle rounded border border-primary'
    >Actualizado por: ({userNo}) {userNombre} - {fechaFormat(fModifica, 'LARGA HORA')}
    </div>
  )
}
export default BarraAuditoria
