import { Button, Row } from 'react-bootstrap'

import { fechaFormat, numberFormat, gcFmtoMaxRows } from '../../../utils/generales'

function ListExistentes ({ registros, devuelveClickNuevoFiltro, devuelveClickListado, orden }) {
  function ctrlClickTabla (e) {
    // e.preventDefault()
    let index = e.target.getAttribute('index')
    index = parseInt(index === null ? 0 : index, 10)
    devuelveClickListado(index)
  }
  function ctrlClickNuevoFiltro (e) {
    // e.preventDefault()
    devuelveClickNuevoFiltro()
  }

  const fmtoOrden = 'bg-primary text-white'
  return (
    <div className='bg-body-secondary'>
      <Row className='justify-content-center mt-2'>
        <Button className='' size='sm' style={{ width: 130 }} onClick={ctrlClickNuevoFiltro}>Nuevo Filtro</Button>
      </Row>
      <div className='overflow-scroll mt-2 ms-3 me-3 mb-2 border border-primary' style={{ maxHeight: 600, zIndex: '98' }}>
        <table
          className='table table-sm table-bordered table-hover caption-top'
          style={{ fontSize: '11px' }}
          onClick={ctrlClickTabla}
        >
          <caption className='bg-primary-subtle text-black fw-bold border-bottom border-primary ps-3'>LISTADO DE FACTURAS SELECCIONADAS
          </caption>
          <thead className='table-primary sticky-top' style={{ zIndex: '99' }}>
            <tr className=''>
              <th
                key={0}
                className={'text-center border border-primary ' + gcFmtoMaxRows(registros.length)}
                style={{ minWidth: 40, position: 'sticky', left: 0 }}
              >{registros.length}
              </th>
              <th
                key={1} className={'text-center ' + (orden === 'NO_FACTURA' ? fmtoOrden : '')}
                style={{ minWidth: 120, position: 'sticky', left: 40 }}
              >No. Factura
              </th>
              <th
                key={2} className={'text-center ' + (orden === 'PTO_VENTA' ? fmtoOrden : '')}
                style={{ minWidth: 150 }}
              >Punto de Venta
              </th>
              <th key={3} className={'text-center ' + (orden === 'CLI_NOMBRE' ? fmtoOrden : '')} style={{ minWidth: 300 }}>Cliente</th>
              <th key={4} className='text-center' style={{ minWidth: 40 }}>Es NC</th>
              <th key={5} className='text-center' style={{ minWidth: 50 }}>Anulada</th>
              <th
                key={7} className={'text-center ' + (orden === 'V_TOTAL' ? fmtoOrden : '')}
                style={{ minWidth: 100 }}
              >Total
              </th>
              <th
                key={6} className={'text-center ' + (orden === 'FECHA' ? fmtoOrden : '')}
                style={{ minWidth: 140 }}
              >Fecha
              </th>
              <th key={8} className={'text-center ' + (orden === 'DIAS_CRE' ? fmtoOrden : '')}>Días</th>
              <th
                key={9} className={'text-center ' + (orden === 'F_VCMTO' ? fmtoOrden : '')}
                style={{ minWidth: 80 }}
              >Vence
              </th>
              <th
                key={10} className={'text-center ' + (orden === 'F_KARDEX' ? fmtoOrden : '')}
                style={{ minWidth: 80 }}
              >F. Kardex
              </th>
              <th
                key={11} className={'text-center ' + (orden === 'VENDEDOR' ? fmtoOrden : '')}
                style={{ minWidth: 200 }}
              >Vendedor
              </th>
              <th
                key={12} className={'text-center ' + (orden.indexOf('NO_PROFORMA') >= 0 ? fmtoOrden : '')}
                style={{ minWidth: 120 }}
              >No. Prof./Fact.
              </th>
              <th
                key={13} className={'text-center ' + (orden === 'BODEGA' ? fmtoOrden : '')}
                style={{ minWidth: 150 }}
              >Bodega
              </th>
              <th
                key={14} className={'text-center ' + (orden === 'OBS' ? fmtoOrden : '')}
                style={{ minWidth: 400 }}
              >Observaciones
              </th>
            </tr>
          </thead>
          <tbody
            className=''
          >
            {registros.map((registro, indexReg) => (
              <tr key={registro.NO_FACTURA}>
                <th
                  key='INDEX' id={indexReg} index={indexReg}
                  className='text-center table-primary border-bottom border-secondary-subtle'
                  style={{ position: 'sticky', left: 0 }}
                >{indexReg + 1}
                </th>
                <td
                  key='NO_FACTURA' id={registro.NO_FACTURA} index={indexReg}
                  className='text-center table-primary border-bottom border-secondary-subtle'
                  style={{ position: 'sticky', left: 40 }}
                >{registro.NO_FACTURA.substr(1)}
                </td>
                <td
                  key='PTO_VENTA' className='' id={registro.PTO_VENTA} index={indexReg}
                >{registro.PTO_VENTA}
                </td>
                <td
                  key='CLI_NOMBRE' className={registro.NO_CLIENTE === null ? 'bg-warning-subtle' : ''}
                  id={registro.CLI_NOMBRE} index={indexReg}
                >{registro.CLI_NOMBRE}
                </td>
                <td
                  key='ES_NC' className='text-center' id={registro.NO_FACTURA.substr(0, 1)} index={indexReg}
                >{registro.NO_FACTURA.substr(0, 1) === 'B' ? '✓' : ''}
                </td>
                <td
                  key='ESTADO' className='text-center' id={registro.ESTADO} index={indexReg}
                >{registro.ESTADO === 'A' ? '✓' : ''}
                </td>
                <td
                  key='TOTAL' className='text-end' id={registro.V_TOTAL} index={indexReg}
                >{numberFormat(registro.V_TOTAL, 2)}
                </td>
                <td
                  key='FECHA' className='text-center' id={registro.FECHA} index={indexReg}
                >{fechaFormat(registro.FECHA, 'MEDIA LARGA HORA')}
                </td>
                <td
                  key='DIAS_CRE' className='text-center' id={registro.DIAS_CRE} index={indexReg}
                >{registro.DIAS_CRE}
                </td>
                <td
                  key='F_VCMTO' className='text-center' id={registro.F_VCMTO} index={indexReg}
                >{fechaFormat(registro.F_VCMTO, 'AMD')}
                </td>
                <td
                  key='F_KARDEX' className='text-center' id={registro.F_KARDEX} index={indexReg}
                >{fechaFormat(registro.F_KARDEX, 'AMD')}
                </td>
                <td
                  key='VENDEDOR' className='' id={registro.VENDEDOR} index={indexReg}
                >{registro.VENDEDOR}
                </td>
                <td
                  key='NO_PROFORMA' index={indexReg}
                  className='text-center' id={registro.NO_PROFORMA}
                >{registro.NO_FACTURA.substring(0, 1) === 'B' ? registro.EXTRA8_TXT : registro.NO_PROFORMA}
                </td>
                <td
                  key='BODEGA' className='' id={registro.BODEGA} index={indexReg}
                >{registro.BODEGA}
                </td>
                <td
                  key='OBS' className='' id={registro.OBS} index={indexReg}
                >{registro.OBS}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ListExistentes
