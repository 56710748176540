/* eslint-disable react/jsx-indent */
import { useContext, useEffect, useState } from 'react'
import { Button, Card, InputGroup, Form, Row, Col, OverlayTrigger, Tooltip, Nav, Spinner } from 'react-bootstrap'
import Swal from 'sweetalert2'

import { SesionContext } from '../../../context/SesionContext'
import restAPIAxios from '../../../utils/axios'
import encryptStorage from '../../../utils/encryptStorage'
import { numberFormat, fechaSumaDias } from '../../../utils/generales'

import imgAnterior from '../../../iconos/ANTERIOR.PNG'

function ItemDatos ({ solicita, datosItem, noItem, cliprovNo, fechaDoc, accItemOtrasEmp, devuelveSalirConsulta }) {
  const [sesion] = useContext(SesionContext)

  const [existencias, setExistencias] = useState([])
  const [noPV, setNoPV] = useState(1)
  const [enlaceXCod, setEnlaceXCod] = useState(false)
  const [carpeta, setCarpeta] = useState(solicita)
  const [cargando, setCargando] = useState(false)

  const [ultTransacc, setUltTransacc] = useState({
    nombre: '', numero: '', fecha: '', hora: '', pv: 0, cant: 0, vDscto: 0, vUnit: 0, ultimoAnio: '', cargando: false
  })

  // Extrae los datos de la empresa (mysql). Parámetros de la empresa y usuario
  const userEmpConfig = encryptStorage.getItem('config')
  const poolName = sesion.orcl_host
  const decMoneda = 2

  // CARGA EXISTENCIAS
  useEffect(() => {
    console.log('🚀 ~ file: ItemDatos.jsx ~ useEffect ~ solicita:', 'Cargando Existencias ...', solicita)
    async function cargaExistencias () {
      setCargando(true)
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CARGAR LAS EXISTENCIAS DEL ITEM ' + datosItem.NO_ITEM_USER,
        {
          method: 'get',
          url: accItemOtrasEmp
            ? `items/existencias?poolName=${poolName}&orclUserVSAM=${sesion.orcl_usuarioVSAM}&noItem=${noItem}&noEmp=${sesion.no_empresaVSAM}&noPV=${noPV}&otrasEmp=SI&tipoEnlace=${enlaceXCod ? 'CODIGO' : 'NUMERO'}&clave=${enlaceXCod ? datosItem.CODIGO : datosItem.NO_ITEM_USER}`
            : `items/existencias?poolName=${poolName}&orclUserVSAM=${sesion.orcl_usuarioVSAM}&noItem=${noItem}&noEmp=${sesion.no_empresaVSAM}&noPV=${noPV}&otrasEmp=NO&tipoEnlace=XXX&clave=XXX`
        }
      )
      setCargando(false)
      if (respuestaAPI.status === 200) {
        setExistencias(respuestaAPI.data)
        // En caso de que la API devuelva error
      } else {
        setExistencias([])
      }
    }
    cargaExistencias()
    setCarpeta(solicita)
  }, [accItemOtrasEmp, datosItem.CODIGO, datosItem.NO_ITEM_USER, enlaceXCod, noItem, noPV,
    poolName, sesion.no_empresaVSAM, sesion.orcl_usuarioVSAM, sesion.token, solicita])

  // CARGA ULTIMAS TRANSACCIONES
  useEffect(() => {
    console.log('🚀 ~ file: ItemDatos.jsx ~ useEffect ~ carpeta, obtieneURL(carpeta):', 'Cargando Ult. Transacciones ...', carpeta, obtieneURL(carpeta))
    function obtieneURL (carpeta) {
      switch (carpeta) {
        case 'ordenes': return `items/ultOrden/${poolName}/${sesion.orcl_usuarioVSAM}/${sesion.no_empresaVSAM}/${noItem}/${fechaDoc}`
        case 'compras': return `items/ultCompra/${poolName}/${sesion.orcl_usuarioVSAM}/${sesion.no_empresaVSAM}/${noItem}/${fechaDoc}`
        case 'proformas': return `items/ultProforma/${poolName}/${sesion.orcl_usuarioVSAM}/${sesion.no_empresaVSAM}/${noItem}/${fechaDoc}`
        case 'facturas': return `items/ultFactura/${poolName}/${sesion.orcl_usuarioVSAM}/${sesion.no_empresaVSAM}/${noItem}/${fechaDoc}`
        case 'extra1':
          if (solicita === 'ordenes' || solicita === 'compras') {
            return `items/ultOrdenProv/${poolName}/${sesion.orcl_usuarioVSAM}/${sesion.no_empresaVSAM}/${noItem}/${cliprovNo}/${fechaDoc}`
          } else return `items/ultProformaCli/${poolName}/${sesion.orcl_usuarioVSAM}/${sesion.no_empresaVSAM}/${noItem}/${cliprovNo}/${fechaDoc}`
        case 'extra2':
          if (solicita === 'ordenes' || solicita === 'compras') {
            return `items/ultCompraProv/${poolName}/${sesion.orcl_usuarioVSAM}/${sesion.no_empresaVSAM}/${noItem}/${cliprovNo}/${fechaDoc}`
          } else return `items/ultFacturaCli/${poolName}/${sesion.orcl_usuarioVSAM}/${sesion.no_empresaVSAM}/${noItem}/${cliprovNo}/${fechaDoc}`
        default: return ''
      }
    }
    function asignaCampos (registro, ultAnio) {
      let datosUltAnio
      if (ultAnio[0].TOT_CANT === null) {
        datosUltAnio = (
          <p>
            <span className={fmtoLabel}>{'Ultimo año (desde ' + fechaSumaDias(fechaDoc, -365, '/')[1] + '): '}</span>
            ** sin datos **
          </p>
        )
      } else {
        const cantTot = ultAnio[0].TOT_CANT === null ? 0 : ultAnio[0].TOT_CANT
        const vUnitTot = ultAnio[0].TOT_VUNIT === null ? 0 : ultAnio[0].TOT_VUNIT
        const dsctoTot = ultAnio[0].TOT_DSCTO === null ? 0 : ultAnio[0].TOT_DSCTO
        const pvTot = ultAnio[0].TOT_PV === null ? 0 : ultAnio[0].TOT_PV
        datosUltAnio = (
          <p>
            {/* Se usa new Date() porque dentro de fechaSumaDias ya se hace la corrección local */}
            <span className={fmtoLabel}>{'Ultimo año (desde ' + fechaSumaDias(fechaDoc, -365, '/')[1] + '): '}</span>
            {'Cantidad: ' + numberFormat(cantTot, decMoneda) + '.'}
            <span className='ms-3'>{'PV: ' + numberFormat(pvTot, decMoneda)}</span>
            <span className='ms-3'>{'Dscto.: ' + numberFormat(dsctoTot, decMoneda)}</span>
            {' (promedio: ' + numberFormat(pvTot === 0 ? 0 : dsctoTot / pvTot * 100, decMoneda) + '%).'}
            <span className='ms-3'>{'V.Unit.Promedio: ' + numberFormat(cantTot === 0 ? 0 : vUnitTot / cantTot, decMoneda) + '.'}</span>
            <span className={'ms-3 ' + fmtoLabel}>TOTAL:</span>{numberFormat(vUnitTot, decMoneda)}
          </p>
        )
      }
      if (registro.length === 0) {
        setUltTransacc({
          nombre: '** sin datos **',
          numero: '',
          fecha: '',
          hora: '',
          pv: 0,
          cant: 0,
          vDscto: 0,
          vUnit: 0,
          ultimoAnio: datosUltAnio,
          cargando: false
        })
      } else {
        setUltTransacc({
          nombre: registro[0].NOMBRE,
          numero: registro[0].NUMERO,
          fecha: registro[0].FECHA,
          hora: registro[0].HORA,
          pv: registro[0].PV,
          cant: registro[0].CANT,
          vDscto: registro[0].V_DSCTO,
          vUnit: registro[0].V_UNIT,
          ultimoAnio: datosUltAnio,
          cargando: false
        })
      }
    }

    async function cargaUltimaTransacc () {
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CARGAR LOS DATOS DE LA ULTIMA TRANSACCION DEL ITEM ' + datosItem.NO_ITEM_USER,
        {
          method: 'get',
          url: obtieneURL(carpeta)
        }
      )
      if (respuestaAPI.status === 200) {
        asignaCampos(respuestaAPI.data[0], respuestaAPI.data[1])
      // En caso de que la API devuelva error
      } else {
        setUltTransacc({
          nombre: '** error **',
          numero: '** error **',
          fecha: '** error **',
          hora: '',
          pv: 0,
          cant: 0,
          vDscto: 0,
          vUnit: 0,
          ultimoAnio: '',
          cargando: false
        })
      }
    }
    cargaUltimaTransacc()
  }, [carpeta, cliprovNo, datosItem.NO_ITEM_USER, fechaDoc, noItem, poolName, sesion.no_empresaVSAM,
    sesion.orcl_usuarioVSAM, sesion.token, solicita])

  // Funciones eventos input usuario
  function ctrlChangeEnlaceXCodigo (e) {
    // ** No usar e.preventDefault() porque causa efectos no deseados al presionar en el check
    setEnlaceXCod(e.target.checked)
  }
  function ctrlChangePV (e) {
    // ** No usar e.preventDefault() porque causa efectos no deseados al presionar en el check
    if (e.target.value !== '') {
      if (e.target.value < 1) {
        e.target.value = 1
        setNoPV(1)
        Swal.fire(
          'EL VALOR NO PUEDE SER MENOR A 1',
          '',
          'info'
        )
      } else {
        setNoPV(e.target.value)
      }
    }
  }
  function ctrlClickUltTransacc (e) {
    setCarpeta(e)
    setUltTransacc({
      nombre: ultTransacc.nombre,
      numero: ultTransacc.numero,
      fecha: ultTransacc.fecha,
      hora: ultTransacc.hora,
      pv: ultTransacc.pv,
      cant: ultTransacc.cant,
      vDscto: ultTransacc.vDscto,
      vUnit: ultTransacc.vUnit,
      ultimoAnio: ultTransacc.ultimoAnio,
      cargando: true
    })
  }

  if (!userEmpConfig) return null

  const fmtoLabel = 'fw-bold me-2'
  const widthBots = 22
  return (
    <>
      <Row className='mt-2 ms-2 me-2 mb-1 fw-bold bg-success-subtle border border-primary rounded'>
        <Col className='ms-2' sm={1}>
          <OverlayTrigger overlay={<Tooltip>Regresar</Tooltip>}>
            <Button
              className='bg-transparent border-0 p-0 text-left' id='REGRESAR' type='submit' size='sm' onClick={devuelveSalirConsulta}
            ><img src={imgAnterior} alt='regresar' width={widthBots} />
            </Button>
          </OverlayTrigger>
        </Col>
        <Col className='border text-center'>{'DATOS DEL ITEM No. ' + datosItem.NO_ITEM_USER}</Col>
      </Row>
      <Card className='ms-2 me-2'>
        {/* DATOS DEL ITEM */}
        <Card.Header id='ItemDatos'>
          {/* no trabaja bien los espacios con <Row> y <Col> */}
          <Row className=''>
            <Col className='' style={{ maxWidth: 300 }}><span className={fmtoLabel}>Código:</span>{datosItem.CODIGO}</Col>
            <Col className=''><span className={fmtoLabel}>Descripción:</span>{datosItem.DESCRIP}</Col>
            <Col><span className={fmtoLabel}>Auxiliar:</span>{datosItem.DESCRIP_AUX}</Col>
          </Row>
          <Row>
            <Col><span className={fmtoLabel}>Otros Códigos:</span>{datosItem.OTROS_COD}</Col>
          </Row>
          <Row>
            <Col><span className={fmtoLabel}>Und. Med.:</span>{datosItem.UM_DESCRIP}</Col>
            <Col><span className={fmtoLabel}>Costo Promedio:</span>{numberFormat(datosItem.V_COST_PROM, 2)}</Col>
            <Col>
              <Form.Check
                className=''
                type='checkbox'
                label={accItemOtrasEmp ? 'Enlazar por código' : ''}
                checked={enlaceXCod}
                reverse
                hidden={!accItemOtrasEmp}
                onChange={ctrlChangeEnlaceXCodigo}
              />
            </Col>
            <Col xs={2}>
              <InputGroup className='' size='sm'>
                <span className='me-2'>No. PV:</span>
                <Form.Control
                  className='text-center pt-0' size='sm' defaultValue={noPV} type='number' min={1}
                  style={{ width: 40 }} onChange={ctrlChangePV}
                />
              </InputGroup>
            </Col>
          </Row>
        </Card.Header>
        {/* TABLA EXISTENCIAS */}
        {cargando
          ? <div className='text-center'>Cargando datos...</div>
          : <>
            <Card.Body className='d-flex justify-content-center pt-2 pb-0'>
              <div className='overflow-scroll' style={{ maxHeight: 300, maxWidth: 1000 }}>
                <table className='table table-sm table-bordered table-hover caption-top'>
                  <caption className='bg-primary-subtle text-center text-black fw-bold border border-primary p-0'>Existencias</caption>
                  <thead className='table-primary'>
                    <tr key={0} className=''>
                      <th key={0} className='text-center border border-primary' style={{ minWidth: 40 }}>
                        {existencias.length}
                      </th>
                      {accItemOtrasEmp
                        ? <th key={1} className='text-center' style={{ minWidth: 250 }}>Empresa</th>
                        : ''}
                      <th key={2} className='text-center' style={{ minWidth: 150 }}>Bodega</th>
                      <th key={3} className='text-end' style={{ minWidth: 120 }}>Existencia</th>
                      <th key={4} className='text-center'>UM</th>
                      <th key={5} className='text-center' style={{ minWidth: 120 }}>{'PV ' + noPV}</th>
                    </tr>
                  </thead>
                  <tbody
                    className=''
                  >
                    {existencias.map((registro, indexReg) => (
                      <tr key={registro.NO_BODEGA}>
                        <th
                          key='INDEX' id={indexReg} index={indexReg}
                          className='text-center table-primary border-bottom border-secondary-subtle'
                        >{indexReg + 1}
                        </th>
                        {accItemOtrasEmp
                          ? <td
                              key='EMP_DESCRIP' id={indexReg} index={indexReg}
                              className='text-center table-primary border-bottom border-secondary-subtle'
                            // eslint-disable-next-line eqeqeq
                            >{registro.NO_INSTIT_SIST == sesion.no_empresaVSAM ? '' : registro.EMP_DESCRIP}
                            </td>
                          : ''}
                        <td
                          key='BOD_DESCRIP' className='text-center' id={indexReg} index={indexReg}
                        >{registro.BOD_DESCRIP}
                        </td>
                        <td
                          key='EXIST' className='text-end' id={indexReg} index={indexReg}
                        >{numberFormat(registro.EXIST, userEmpConfig.decimales)}
                        </td>
                        <td
                          key='ABREV' className='text-center' id={indexReg} index={indexReg}
                        >{datosItem.ABREV}
                        </td>
                        <td
                          key='V_PVENTA' className='text-end' id={indexReg} index={indexReg}
                        >{numberFormat(registro.V_PVENTA, userEmpConfig.decimales)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card.Body>
            {/* TOTALES EXISTENCIAS */}
            <Row className='text-center justify-content-evenly mb-2'>
              <Col xs={4}>
                <span className={fmtoLabel}>{accItemOtrasEmp ? 'Total existencias empresa:' : 'Total existencias'}</span>
                {existencias.length === 0
                  ? numberFormat(0, decMoneda)
                  : numberFormat(existencias.reduce((reg1, reg2) => {
                    // eslint-disable-next-line eqeqeq
                    if (reg2.NO_INSTIT_SIST == sesion.no_empresaVSAM) {
                      return (reg1 + reg2.EXIST)
                    } else return reg1
                  }, 0)
                  , decMoneda)}
              </Col>
              <Col xs={3} hidden={!accItemOtrasEmp}>
                <span className='me-2' hidden={!accItemOtrasEmp}>Total existencias:</span>
                {existencias.length === 0
                  ? numberFormat(0, decMoneda)
                  : numberFormat(existencias.reduce((reg1, reg2) => reg1 + reg2.EXIST, 0), decMoneda)}
              </Col>
            </Row>
            </>}
        {/* ULTIMAS TRANSACCIONES */}
        <Card.Text className='bg-light border-top border-secondary-subtle rounded-bottom'>
          <Nav
            id='ultmaTransacc' className='text-secondary mb-1' fill variant='tabs' defaultActiveKey={carpeta}
            onSelect={ctrlClickUltTransacc}
          >
            <Nav.Link eventKey='ordenes'>Ord.Compra</Nav.Link>
            <Nav.Link eventKey='compras'>Compra</Nav.Link>
            <Nav.Link eventKey='proformas'>Proforma</Nav.Link>
            <Nav.Link eventKey='facturas'>Factura</Nav.Link>
            <Nav.Link eventKey='extra1'>{solicita === 'ordenes' || solicita === 'compras' ? 'Orden Prov.' : 'Proforma Cliente'}</Nav.Link>
            <Nav.Link eventKey='extra2'>{solicita === 'ordenes' || solicita === 'compras' ? 'Compra Prov.' : 'Factura Cliente'}</Nav.Link>
          </Nav>
          {ultTransacc.cargando
            ? <div className='mt-2 mb-2 text-center'>
                <Spinner animation='grow' />Cargando ultima transacción...
              </div>
            : <div className='ps-3 pe-3'>
                {/* no trabaja bien los espacios con <Row> y <Col> */}
                <div className='d-flex justify-content-between'>
                  <div className='me-4'>
                    <span className='me-2'>{carpeta === 'compras' || carpeta === 'ordenes' ? 'Proveedor:' : 'Cliente:'}</span>
                    {ultTransacc.nombre}
                  </div>
                  <div className='me-4'>
                    <span className={fmtoLabel}>
                      {carpeta === 'compras'
                        ? 'Compra:'
                        : carpeta === 'ordenes'
                          ? 'Orden:'
                          : carpeta === 'proformas' ? 'Proforma:' : carpeta === 'facturas' ? 'Factura:' : 'No.:'}
                    </span>{ultTransacc.numero}
                  </div>
                  <div className='text-end'>
                    <span className='ms-5 me-2'>Fecha:</span>{ultTransacc.fecha}
                    <span className=''> </span>{ultTransacc.hora}
                  </div>
                </div>
                <Row className=''>
                  <Col><span className={fmtoLabel}>Cant:</span>
                    {numberFormat(ultTransacc.cant, decMoneda)}
                  </Col>
                  <Col><span className='me-2'>PV:</span>
                    {numberFormat(ultTransacc.pv, decMoneda)}
                  </Col>
                  <Col><span className='me-2'>P.Dscto.:</span>
                    {numberFormat(ultTransacc.pv === 0 ? 0 : ultTransacc.vDscto / ultTransacc.pv * 100, decMoneda) + '%'}
                  </Col>
                  <Col><span className='me-2'>Dscto.:</span>
                    {numberFormat(ultTransacc.vDscto, decMoneda)}
                  </Col>
                  <Col><span className={fmtoLabel}>V.Unit.:</span>
                    {numberFormat(ultTransacc.vUnit, decMoneda)}
                  </Col>
                  <Col><span className={fmtoLabel}>V.Total:</span>
                    {numberFormat(ultTransacc.cant * ultTransacc.vUnit, decMoneda)}
                  </Col>
                </Row>
                <Row className='mt-1 pt-2 border-top'>
                  <Col id='ultimoAnio'>{ultTransacc.ultimoAnio}</Col>
                </Row>
              </div>}
        </Card.Text>
      </Card>
    </>
  )
}

export default ItemDatos
