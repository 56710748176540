/* eslint-disable react/jsx-indent */
import { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import { Form, InputGroup, Spinner } from 'react-bootstrap'

// Importa el cliente Axios
import restAPIAxios from '../../utils/axios'
import { SesionContext } from '../../context/SesionContext'

function TablaOpcion (props) {
  // Las propiedades "maxRegs y txtCampos" no se utilizan, por ahora, en este componente
  const {
    url, txtTabla, tabCampos, txtLblTabla, campoAdicional, ceroEsNulo, ancho, fmto
  } = props.infoTablaLista
  const noActualiceTabla = (props.infoTablaActualiza === undefined)
  const deshabilitado = props.deshabilitado
  // console.log(fmto, txtLblTabla)
  const navigate = useNavigate()

  // Trabajar con el STATE de REACT
  const [datosTabla, setDatosTabla] = useState([])
  const [cargando, setCargando] = useState(false)

  // Utilizo CONTEXT para tomar los valores de AUTORIZACION (sesion)
  // y colocar o no el componente
  const [sesion] = useContext(SesionContext)

  const [valorSel, setValorSel] = useState(0)

  const infoTActualiza = props.infoTablaActualiza
  const claveReg = () => {
    // console.log(infoTActualiza, sesion.facturas)
    switch (infoTActualiza.tabla) {
      case 'T_PROFORMA_TMP': return sesion.proformas.clave
      case 'T_FACTURA_TMP': return sesion.facturas.clave
      default: return 0
    }
  }

  const poolName = sesion.orcl_host

  useEffect(() => {
    const valInicial = props.valTabla === null ? 0 : props.valTabla
    setValorSel(valInicial)
    // Query a la API usando Axios
    async function getDatosTabla () {
      setCargando(true)
      // console.log('TablaOpcion', sesion)
      const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE CARGAR ${txtTabla}`,
        {
          method: 'post',
          url,
          data: {
            poolName,
            orclUserVSAM: sesion.orcl_usuarioVSAM,
            noEmp: sesion.no_empresaVSAM,
            claveAdicional: props.claveAdicional
          }
        }
      )
      // console.log(respuestaAPI)
      if (respuestaAPI.status === 200) {
        setCargando(false)
        setDatosTabla(respuestaAPI.data)
      } else {
        setCargando(false)
        // console.log(sesion.no_empresaVSAM, url)
        navigate('/')
      }
    };
    getDatosTabla()
  }, [navigate, txtTabla, url, props.valTabla, props.claveAdicional, sesion.orcl_usuarioVSAM,
    sesion.no_empresaVSAM, sesion.token, poolName]
  )

  async function ctrlSetRegistro (e) {
    // e.preventDefault()
    const nuevoVal = e.target.value === '0' ? '' : e.target.value
    const index = e.target.options.selectedIndex
    const descrip = e.target.options[index].innerHTML
    if (props.devuelveDatos !== undefined) props.devuelveDatos({ nuevoVal, index, descrip })
    if (props.devuelveEditando !== undefined) props.devuelveEditando(true)

    if (noActualiceTabla) {
      setValorSel(nuevoVal)
    } else {
      setCargando(true)
      // actualiza el Campo en la Tabla
      const defProps = {
        poolName,
        tabla: props.infoTablaActualiza.tabla,
        campo: props.infoTablaActualiza.campo,
        valor: nuevoVal,
        cond: props.infoTablaActualiza.campoClave + '=' + claveReg(),
        ceroEsNulo
      }
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ACTUALIZAR EL CAMPO EN LA BD',
        {
          method: 'patch',
          url: props.infoTablaActualiza.url,
          data: defProps
        }
      )
      // console.log(respuestaAPI)
      if (respuestaAPI.status === 200) {
        setCargando(false)
        if (respuestaAPI.data[0] === 0) {
          Swal.fire({
            title: 'EL VALOR NO SE ACTUALIZO EN LA BD',
            text: 'Consulte a su técnico',
            footer: 'La respuesta de la API fue 0',
            icon: 'info'
          })
          // Recupera el valor original
          setValorSel(props.valTabla === null ? 0 : props.valTabla)
        } else {
          // console.log(nuevoVal)
          setValorSel(nuevoVal)
        }
      } else {
        setCargando(false)
        // Recupera el valor original
        setValorSel(props.valTabla === null ? 0 : props.valTabla)
      }
    }
  }
  function ctrlDblClick (e) {
    // e.preventDefault()
    if (props.devuelveDblClick === undefined) {
      Swal.fire(
        'NO ESTA ACTIVA LA OPCION DE ORDENADO PARA ESTE CAMPO',
        '',
        'info'
      )
    } else props.devuelveDblClick()
  }

  // console.log('TablaOpcion', txtTabla, txtLblTabla, ancho)
  if (!sesion.auth || datosTabla.length === 0) {
    return (
      <InputGroup className={fmto[0]} size='sm' style={ancho ? { width: ancho } : {}}>
        {txtLblTabla === ''
          ? ''
          : <InputGroup.Text id='lblTabla' className={fmto[1]}>{txtLblTabla}</InputGroup.Text>}
        <Spinner animation='grow' size='sm' hidden={!cargando} />
        <Form.Select
          className={fmto[2]}
          id={'select_' + url.substring(1)}
          aria-describedby='lblTabla'
          autoFocus={noActualiceTabla ? false : props.infoTablaActualiza.campo === 'NO_PTOVENTA'}
          disabled={deshabilitado}
        >
          <option>{`** Sin ${txtTabla.toLowerCase()} **`}</option>
        </Form.Select>
      </InputGroup>
    )
  }

  return (
    <InputGroup className={fmto[0]} size='sm' style={ancho ? { width: ancho } : {}}>
      {txtLblTabla === ''
        ? ''
        : <InputGroup.Text
            id='lblTabla' className={fmto[1]}
            onDoubleClick={ctrlDblClick}
          >{txtLblTabla}
          </InputGroup.Text>}
      <Form.Select
        className={fmto[2]}
        id={'select_' + url.substring(1)}
        value={valorSel}
        aria-describedby='lblTabla'
        autoFocus={noActualiceTabla ? false : props.infoTablaActualiza.campo === 'NO_PTOVENTA'}
        disabled={deshabilitado}
        // onFocus={(e)=>{e.preventDefault(); if(props.devuelveEditando!==undefined) props.devuelveEditando(true)}}
        onChange={ctrlSetRegistro}
        onBlur={(e) => { if (props.devuelveEditando !== undefined) props.devuelveEditando(false) }}
      >
        <option
          value={0}
          key={0}
          index={0}
          hidden={!ceroEsNulo}
        >{`** Sin ${txtTabla.toLowerCase()} **`}
        </option>
        {datosTabla.map((registro, index) => (
          <option
            value={registro.KEY_TAB}
            key={registro.KEY_TAB}
            index={index + 1}
          >{campoAdicional
            ? registro[tabCampos[1]] + ' (' + registro[campoAdicional] + ')'
            : registro[tabCampos[1]]}
          </option>
        )
        )}
      </Form.Select>
    </InputGroup>
  )
}

export default TablaOpcion
