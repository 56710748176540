/* eslint-disable react/jsx-indent */
import { useEffect, useReducer, useState } from 'react'
import { Row, InputGroup, Spinner, Form, Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Swal from 'sweetalert2'

import restAPIAxios from '../../../utils/axios.js'
import { useSesion } from '../../../context/SesionContext.jsx'
import encryptStorage from '../../../utils/encryptStorage.js'

import {
  gcFmtosBotones, gcFmtosTablas, gcTablasLogicas, setInfoCampoFactura, anchoPantDisponible
  , fmtosTablaFiltroCampo, setInfoCampoProforma, gcFmtoMaxRows, fechaFormat, fechaLocalNueva
} from '../../../utils/generales.js'

import EmpSel from '../../admin/EmpSel.jsx'
import Encabezado from './CliEncabezado.jsx'
import Cliente from './Cliente.jsx'

// import imgGrabar from '../../iconos/GRABAR.BMP'
import imgMas from '../../../iconos/MAS.ICO'
import imgMenos from '../../../iconos/MENOS.ICO'
import imgModificar from '../../../iconos/MODIFICAR.ICO'

const clienteIni = {
  NO_CLIENTE: null,
  ID: '',
  NOMBRE: '',
  TIPO: 'O',
  SIGLAS: '',
  CATEGORIA: '',
  DIRECC: '',
  TELEF: '',
  FAX: '',
  EMAIL: '',
  ATTPERSONA: '',
  NO_VENDEDOR: null,
  OTROS_DATOS: '',
  EXTRA1_TXT: '',
  P_DSCTO: 0,
  NO_PV: 0,
  V_INI_X_COB: 0,
  F_DESDE: fechaFormat(fechaLocalNueva(), 'AMD', '-'),
  L_CREDITO: 0,
  V_LIMITE_CRE: 0,
  VCRE_PERIODO_MESES: null,
  VCRE_DIA_INI: null,
  VEND_NOMBRE: null
}
const accionesCliente = {
  asigna: 'ASIGNAR', vacia: 'VACIAR', crea: 'CREAR', modifica: 'MODIFICAR', creado: 'CREADO', modificado: 'MODIFICADO'
}
const clienteReducer = (estado, accion) => {
  switch (accion.tipo) {
    case accionesCliente.asigna:
      return [accion.tipo, accion.valor]
    case accionesCliente.vacia:
      return [accion.tipo, clienteIni]
    case accionesCliente.crea:
      return [accion.tipo, clienteIni]
    case accionesCliente.modifica:
      return [accion.tipo, accion.valor]
    case accionesCliente.modificado:
      return [accion.tipo, accion.valor]
    case accionesCliente.creado:
      return [accion.tipo, accion.valor]
    default:
      throw new Error('No existe esa accion del Cliente')
  }
}

// COMPONENTE
function Clientes (
  {
    children,
    opcMenuCall,
    noClienteIni,
    filtrando,
    existentes,
    updateTablaDoc,
    devuelveFiltro,
    devuelveRegistro,
    devuelveDblClick
  }
) {
  // Solo porque el ESLINT STANDARD no deja colocar el valor TRUE en la propiedad
  // son propiedades booleanas que podrían llegan con valor UNDEFINED
  if (filtrando === undefined) filtrando = false
  if (existentes === undefined) existentes = false
  if (updateTablaDoc === undefined) updateTablaDoc = false

  const [sesion] = useSesion()
  const poolName = sesion.orcl_host

  let accMnuFact, accCejaProf, accMnuClientes, accCreaMod
  const userVSAM = encryptStorage.getItem('userVSAM')
  if (userVSAM) {
    accMnuFact = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_FACTURACION) === -1
    accCejaProf = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_PROF_CEJA) === -1
    accMnuClientes = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_CLIENTES) === -1
    accCreaMod = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_CLI_CREA_MOD) === -1
  } else {
    accMnuFact = false
    accCejaProf = false
    accMnuClientes = false
    accCreaMod = false
  }
  // Extrae los datos de la empresa (mysql)
  const userEmpConfig = encryptStorage.getItem('config')

  const infoTablaLista = {
    url: '/clientes',
    maxRegs: process.env.REACT_APP_MAX_REGS,
    txtTabla: gcTablasLogicas.cliente,
    tabCampos: ['ID', 'NOMBRE', 'TIPO', 'CATEGORIA', 'SIGLAS', 'DIRECC', 'P_DSCTO', 'NO_PV',
      'V_INI_X_COB', 'TELEF', 'EMAIL', 'ATTPERSONA', 'VEND_NOMBRE', 'FAX', 'OTROS_DATOS',
      'EXTRA1_TXT', 'F_DESDE', 'L_CREDITO', 'V_LIMITE_CRE', 'VCRE_PERIODO_MESES', 'VCRE_DIA_INI',
      'USER_NOMBRE', 'USER_ABREV', 'F_MODIFICA'],
    txtLblTabla: 'Cliente:',
    txtCampos: ['ID', 'Nombre', 'Tipo', 'Categoría', 'Siglas', 'Dirección', 'Dscto.%', 'No.PV',
      'Días cre.', 'Teléfono', userEmpConfig.textos_labels.cliEmail || 'Email',
      'Atención persona', userEmpConfig.textos_labels.cliVendedor || 'Vendedor',
      userEmpConfig.textos_labels.cliFax || 'Fax', 'Otros datos',
      userEmpConfig.textos_labels.cliExtra1 || 'Dato adicional',
      'Cliente desde', 'Controlar', 'Valor máximo', 'Periodo cada', 'Inicia día del mes',
      'Usuario', 'Abrev.', 'Fecha y hora'],
    campoFiltro: 'NOMBRE',
    condFija: 'and A.NO_PERSONA is null'
  }

  const [filtro, setFiltro] = useState('')
  const [datTabla, setDatTabla] = useState([])
  const [cargando, setCargando] = useState(false)
  const [reiniciar, setReiniciar] = useState(false)
  const [ordenadoX, setOrdenadoX] = useState({ campo: infoTablaLista.campoFiltro, index: 1 })

  const [datCliente, dispatch] = useReducer(clienteReducer, clienteIni)

  const [fmtoNoExiste, setFmtoNoExiste] = useState('')
  const FMTO_NO_EXISTE = ' bg-warning-subtle'

  // FUNCIONES PARA EL useEffect()

  useEffect(() => {
    console.log('🚀 ~ file: Clientes.jsx:150 ~ useEffect ~ children, reiniciar:', children, reiniciar)
    // Para que oculte la tabla en lugar de tener abierta
    // y para obligar al usuario a volver a elegir un filtro
    dispatch({ tipo: accionesCliente.vacia })
    setDatTabla([])
    setFiltro(children ?? '')
    setFmtoNoExiste('')
    if (noClienteIni === null) setFmtoNoExiste(FMTO_NO_EXISTE)
  }, [children, noClienteIni, reiniciar])
  // ** No se puede poner el filtro como dependencia porque cada vez que cambie el filtro
  // ** (ej. ctrlClickChange) entraría al useEffect y volvería el filtro al original (children)

  // FUNCIONES PRIVADAS DEL COMPONENTE
  async function cargaClientes (nuevoOrden) {
    setCargando(true)
    if (nuevoOrden) setOrdenadoX(nuevoOrden)
    const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE CARGAR ${infoTablaLista.txtTabla}`,
      {
        method: 'post',
        url: infoTablaLista.url,
        data: {
          poolName,
          orclUserVSAM: sesion.orcl_usuarioVSAM,
          noEmp: sesion.no_empresaVSAM,
          campoFiltro: infoTablaLista.campoFiltro,
          filtro,
          condFija: infoTablaLista.condFija,
          condClaveUnica: '',
          ordenadoX: nuevoOrden ? nuevoOrden.campo : ordenadoX.campo
        }
      }
    )
    if (respuestaAPI.status === 200) {
      if (respuestaAPI.data.length > infoTablaLista.maxRegs) {
        Swal.fire(
          `EL FILTRO SELECCIONADO CARGA MAS DE ${infoTablaLista.maxRegs} ${infoTablaLista.txtTabla} (${respuestaAPI.data.length})`,
          'Elija un filtro que reduzca la cantidad de registros para seleccionar',
          'info'
        )
      } else {
        setDatTabla(respuestaAPI.data)
      }
    }
    setCargando(false)
  }
  async function actualizaCampo ({ campo, valor }) {
    setCargando(true)
    // Establece los datos para actualizar la Tabla temporal
    let infoTablaActualiza
    if (opcMenuCall === gcTablasLogicas.fact) {
      infoTablaActualiza = setInfoCampoFactura(campo, valor)
    } else infoTablaActualiza = setInfoCampoProforma(campo, valor)
    // Establece la clave (valor) de la condición que va en la tabla temporal que actualiza
    const condValor = (opcMenuCall === gcTablasLogicas.fact
      ? sesion.facturas.clave
      : (opcMenuCall === gcTablasLogicas.prof ? sesion.proformas.clave : 'null')
    )
    const infoTablaCampo = {
      poolName,
      tabla: infoTablaActualiza.tabla,
      campo: infoTablaActualiza.campo,
      valor,
      cond: infoTablaActualiza.campoClave + '=' + condValor,
      ceroEsNulo: false
    }
    const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ACTUALIZAR EL CAMPO EN LA BD',
      {
        method: 'patch',
        url: infoTablaActualiza.url,
        data: infoTablaCampo
      }
    )
    setCargando(false)
    if (respuestaAPI.status === 200) {
      if (respuestaAPI.data[0] === 0) {
        Swal.fire({
          title: 'EL VALOR NO SE ACTUALIZO EN LA BD',
          text: 'consulte a su técnico',
          footer: 'La respuesta de la API fue 0',
          icon: 'info'
        })
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  // FUNCIONES DE CONTROL (EVENTOS) DE ELEMENTOS DEL COMPONENTE
  function ctrlChangeFiltro (e) {
    // e.preventDefault()
    setFiltro(e.target.value)
    // No se debe devolver el filtro (SOLO SI NO ESTOY FILTRANDO) para que aparezca el color amarillo,
    // que muestra si el campo no es el mismo que el de la BD
    if (devuelveFiltro && filtrando) devuelveFiltro(e.target.value)
    if (!opcMenuCall && datTabla.length > 0) setDatTabla([])
  }
  async function ctrlBlurFiltro (e) {
    // e.preventDefault()
    // No hubo cambios en el campo
    if (e.target.value === children) return
    // (e.target.value = filtro) ==> ya que se cambia el filtro en ctrlChangeFiltro
    // Actualiza el campo a NULL
    if (e.target.value === '' && children !== null) {
      dispatch({ tipo: accionesCliente.vacia })
      setDatTabla([])
      setFmtoNoExiste(opcMenuCall ? FMTO_NO_EXISTE : '')
      if (devuelveRegistro) devuelveRegistro(infoTablaLista.campoFiltro, '', [])
      if (updateTablaDoc && !filtrando) {
        if (await actualizaCampo({ campo: 'NO_CLIENTE', valor: null })) {
          setFiltro('')
          if (devuelveFiltro) devuelveFiltro('')
        } else {
          !children ? setFiltro('') : setFiltro(children)
          if (devuelveFiltro) devuelveFiltro(children ?? '')
        }
      } else {
        setFiltro('')
        if (devuelveFiltro) devuelveFiltro('')
      }
    } else if (e.target.value !== '') {
      setFiltro(e.target.value)
      setFmtoNoExiste(datCliente.NO_CLIENTE > 0 ? '' : FMTO_NO_EXISTE)
      // ** Falta actualizar el campo N_CLIENTETMP en T_FACTURA y poner en amarillo el campo
    }
    // else ** BUSCAR POR CEDULA O RUC DIRECTAMENTE O EL PRIMER NOMBRE QUE ENCUENTRE
  }
  function ctrlLblDblClick (e) {
    // e.preventDefault()
    if (devuelveDblClick === undefined) {
      if (opcMenuCall) {
        Swal.fire(
          'NO ESTA ACTIVA LA OPCION DE ORDENADO PARA ESTE CAMPO',
          '',
          'info'
        )
      }
    } else devuelveDblClick()
  }
  function ctrlDblClickLista (e) {
    // e.preventDefault()
    if (!disableBotMod) ctrClickModificar(e)
  }
  async function ctrlClickToggle (e) {
    // e.preventDefault()
    // Porque con el dispatch, primero muestra la lista anterior (si la hubiera), y eso puede tomar tiempo
    setCargando(true)
    dispatch({ tipo: accionesCliente.vacia })
    if (!cargando && datTabla.length > 0) {
      setDatTabla([])
      setCargando(false)
    } else {
      // Verifica si se quiere filtrar por ID (ruc, cédula o pasaporte)
      if (filtro.substring(0, 1) === '.') {
        const indexCol = infoTablaLista.txtCampos.indexOf('ID')
        if (indexCol >= 0) cargaClientes({ campo: infoTablaLista.tabCampos[indexCol], index: indexCol })
        // No encuentra la columna ID
        else cargaClientes()
      } else cargaClientes()
    }
  }
  async function ctrlClickLista (e) {
    // e.preventDefault()
    // Verifica si el clic fue en una fila
    const fila = e.target.closest('tr')
    if (fila) {
      // Captura la columna donde presiona el click para ver si es el botón Modificar
      const columna = e.target.closest('td')
      // Capturo el atributo name para verificar en que columna estoy
      const colName = columna.getAttribute('name')
      // Captura el index de la fila
      const index = columna.getAttribute('index')

      // Si estoy en el botón Modifica no hace nada (tiene que dar paso a la modificación)
      // o en caso de que presione en la cabecera u otro lugar donde index es null
      if (colName === 'BOTON-MODIFICA' || !index) return

      const nuevoFiltro = datTabla[index][infoTablaLista.campoFiltro]
      if (updateTablaDoc && !filtrando) {
        // actualiza el Campo en la Tabla
        if (await actualizaCampo({ campo: 'NO_CLIENTE', valor: datTabla[index].NO_CLIENTE })) {
          setFiltro(nuevoFiltro)
          dispatch({ tipo: accionesCliente.asigna, valor: datTabla[index] })
          setDatTabla([])
          setFmtoNoExiste('')
          if (devuelveFiltro) devuelveFiltro(nuevoFiltro)
          if (devuelveRegistro) devuelveRegistro(infoTablaLista.campoFiltro, nuevoFiltro, datTabla[index])
        } else {
          !children ? setFiltro('') : setFiltro(children)
          dispatch({ tipo: accionesCliente.vacia })
          setDatTabla([])
          setFmtoNoExiste(children && children !== '' ? FMTO_NO_EXISTE : '')
          if (devuelveFiltro) devuelveFiltro(!children ? '' : children)
          if (devuelveRegistro) devuelveRegistro(infoTablaLista.campoFiltro, '', [])
        }
      } else {
        dispatch({ tipo: accionesCliente.asigna, valor: datTabla[index] })
        setFiltro(nuevoFiltro)
        setDatTabla([])
        setFmtoNoExiste('')
        if (devuelveFiltro) devuelveFiltro(nuevoFiltro)
        if (devuelveRegistro) devuelveRegistro(infoTablaLista.campoFiltro, nuevoFiltro, datTabla[index])
      }
    }
  }
  function ctrlClickHeadLista (e) {
    // e.preventDefault()
    if (e.target.textContent !== '') {
      const indexCol = infoTablaLista.txtCampos.indexOf(e.target.textContent)
      if (indexCol > -1) {
        cargaClientes({ campo: infoTablaLista.tabCampos[indexCol], index: indexCol })
      }
    }
  }
  function ctrlClickNuevo (e) {
    // e.preventDefault()
    dispatch({ tipo: accionesCliente.crea })
  }
  function ctrClickModificar (e) {
    // e.preventDefault()
    const index = e.target.getAttribute('index')
    if (index) {
      dispatch({ tipo: accionesCliente.modifica, valor: datTabla[index] })
    }
  }
  function ctrClickEliminar (e) {
    // e.preventDefault()
    const index = e.target.getAttribute('index')
    if (index) {
      async function eliminar () {
        setCargando(true)
        const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ELIMINAR EL CLIENTE',
          {
            method: 'delete',
            url: '/cliente',
            data: {
              poolName,
              orclUserVSAM: sesion.orcl_usuarioVSAM,
              noUser: sesion.no_usuarioVSAM,
              noEmp: sesion.no_empresaVSAM,
              noCliente: datTabla[index].NO_CLIENTE,
              noInstit: datTabla[index].NO_INSTIT
            }
          }
        )
        setCargando(false)
        if (respuestaAPI.status === 200) {
          setReiniciar(!reiniciar)
        }
      }
      Swal.fire({
        title: 'CONFIRME',
        text: `Desea elinar el cliente (${datTabla[index].ID}) ${datTabla[index][infoTablaLista.campoFiltro]}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'SI',
        cancelButtonText: 'NO'
      }).then((result) => {
        if (result.isConfirmed) eliminar()
      })
    }
  }
  // FUNCIONES DE CONTROL (EVENTOS) DEVUELTOS POR LOS COMPONENTES
  function ctrlCambioEmp () {
    setReiniciar(!reiniciar)
  }
  function ctrlOcultaCliEdit () {
    setReiniciar(!reiniciar)
  }
  function ctrlCreaModCli (nuevoRegistro, esNuevo) {
    const nuevoFiltro = nuevoRegistro[infoTablaLista.campoFiltro]
    setFiltro(nuevoFiltro)
    setDatTabla([nuevoRegistro])
    if (devuelveFiltro) devuelveFiltro(nuevoFiltro)
    if (devuelveRegistro) {
      devuelveRegistro(infoTablaLista.campoFiltro, nuevoFiltro, nuevoRegistro)
    }
    dispatch({ tipo: esNuevo ? accionesCliente.creado : accionesCliente.modificado, valor: nuevoRegistro })
  }

  const size = opcMenuCall ? 'sm' : ''
  const fmtoOrden = ' bg-primary text-white'
  const widthImg = gcFmtosBotones.imgWidthSM
  const idRowFiltro = 'tabla-' + infoTablaLista.txtTabla + '-' + infoTablaLista.campoFiltro
  const styleBotsTbl = gcFmtosBotones.styleTabla
  const disableBotMod = opcMenuCall === gcTablasLogicas.prof
    ? !accMnuFact || !accCejaProf || !accMnuClientes || !accCreaMod
    : opcMenuCall === gcTablasLogicas.fact
      ? !accMnuFact || !accMnuClientes || !accCreaMod
      : !accMnuFact || !accCreaMod
  const disableBotEli = opcMenuCall === gcTablasLogicas.prof
    ? !accMnuFact || !accCejaProf || !accMnuClientes
    : opcMenuCall === gcTablasLogicas.fact
      ? !accMnuFact || !accMnuClientes
      : !accMnuFact
  const posLCREDITO = infoTablaLista.tabCampos.indexOf('L_CREDITO')
  const anchoColBot = 44
  const anchoColCont = 35

  return (
    <>
      <Encabezado sinEncabezado={opcMenuCall} />
      <Row className={gcFmtosTablas(opcMenuCall).rowEmp} hidden={opcMenuCall}>
        <EmpSel existentes devuelveCambiaEmp={ctrlCambioEmp} esHeader />
      </Row>
      {/* FILTRO */}
      <Row
        className={gcFmtosTablas(opcMenuCall).row}
        id={idRowFiltro} disabled={existentes}
      >
        <InputGroup
          className={gcFmtosTablas(opcMenuCall).inputGroup} size={size}
          style={gcFmtosTablas(opcMenuCall).inputGStyle}
        >
          <InputGroup.Text
            id='lblCliente' className={gcFmtosTablas(opcMenuCall).labelFiltro}
            onDoubleClick={ctrlLblDblClick}
          >Cliente:
          </InputGroup.Text>
          <Spinner animation='grow' size={size} hidden={!cargando} />
          <Form.Control
            id='clientes-NOMBRE'
            className={gcFmtosTablas(opcMenuCall).filtro + fmtoNoExiste}
            type='text'
            autoFocus
            value={filtro}
            disabled={opcMenuCall && existentes && !filtrando}
            placeholder='Elija un filtro para obtener clientes'
            aria-describedby='lblCliente'
            onChange={ctrlChangeFiltro}
            onBlur={ctrlBlurFiltro}
          />
          <Dropdown.Toggle
            className='bg-secondary-subtle'
            split
            variant='outline-secondary'
            id='tablaCtrl'
            disabled={!filtro}
            hidden={opcMenuCall && existentes && !filtrando}
            onClick={ctrlClickToggle}
          />
          <OverlayTrigger overlay={<Tooltip>nuevo cliente</Tooltip>}>
            <Button
              className={gcFmtosBotones.filtro}
              id='NUEVO' type='submit' size={size} aria-label='Close'
              hidden={!accCreaMod || filtrando || existentes} onClick={ctrlClickNuevo}
            ><img src={imgMas} alt='nuevo' width={gcFmtosBotones.imgWidthSM} />
            </Button>
          </OverlayTrigger>
        </InputGroup>
      </Row>
      {/* TABLA */}
      {datCliente[0] === accionesCliente.crea || datCliente[0] === accionesCliente.modifica
        ? <Cliente
            filtrando={filtrando} existentes={existentes} datCliente={datCliente}
            estaCreando={datCliente[0] === accionesCliente.crea}
            opcMenuCall={opcMenuCall} idRowFiltro={idRowFiltro}
            devuelveOcultaCliEdit={ctrlOcultaCliEdit}
            devuelveCargando={(valor) => setCargando(valor)}
            devuelveCreaModCli={ctrlCreaModCli}
          />
        : <div
            id='tablaClientes'
            className='overflow-scroll border border-3'
            style={{ maxHeight: opcMenuCall ? 400 : 600, minWidth: opcMenuCall ? anchoPantDisponible(idRowFiltro) : undefined }}
            hidden={opcMenuCall && datTabla.length === 0}
          >
            <table
              className='table table-bordered table-sm table-hover table-responsive-md'
              style={{ fontSize: '11px' }}
            >
              <thead className='sticky-top'>
                <tr className='table-primary text-center'>
                  <th
                    colSpan={posLCREDITO + ((opcMenuCall || disableBotEli) && disableBotMod ? 1 : opcMenuCall || disableBotMod || disableBotEli ? 2 : 3)}
                  >DATOS  DEL  CLIENTE
                  </th>
                  <th colSpan='4'>Datos para control del crédito</th>
                  <th colSpan='3'>Ultima actualización</th>
                </tr>
                <tr className='table-primary'>
                  <th
                    key={0} name='INDEX'
                    className={'text-center ' + gcFmtoMaxRows(datTabla.length, infoTablaLista.maxRegs)}
                    style={{ minWidth: anchoColCont, position: 'sticky', left: 0 }}
                  >{datTabla.length}
                  </th>
                  <th
                    key='BOTON-MODIFICA' name='BOTON-MODIFICA'
                    hidden={disableBotMod}
                    style={{ minWidth: anchoColBot, position: 'sticky', left: anchoColCont }}
                  />
                  {opcMenuCall
                    ? null
                    : <th
                        key='BOTON-ELIMINA' name='BOTON-ELIMINA'
                        hidden={disableBotEli}
                        style={{
                          minWidth: anchoColBot,
                          position: 'sticky',
                          left: disableBotMod ? anchoColCont : anchoColCont + anchoColBot
                        }}
                      />}
                  {infoTablaLista.txtCampos.map((txtCampo, index) => (
                    <th
                      key={index + 1} name={infoTablaLista.tabCampos[index]}
                      className={datTabla.length === 0
                        ? ''
                        : fmtosTablaFiltroCampo(
                          infoTablaLista.txtTabla,
                          infoTablaLista.tabCampos[index],
                          datTabla[0][infoTablaLista.tabCampos[index]],
                          userEmpConfig.decimales
                        ).fmto + (txtCampo === infoTablaLista.txtCampos[ordenadoX.index] ? fmtoOrden : '')}
                      hidden={txtCampo === ''}
                      style={datTabla.length === 0
                        ? null
                        : {
                            minWidth: fmtosTablaFiltroCampo(
                              infoTablaLista.txtTabla,
                              infoTablaLista.tabCampos[index],
                              datTabla[0][infoTablaLista.tabCampos[index]],
                              userEmpConfig.decimales).ancho,
                            position: (index < 2 ? 'sticky' : ''),
                            left: (index === 0
                              ? opcMenuCall
                                ? anchoColCont + (disableBotMod ? 0 : anchoColBot)
                                : anchoColCont + (disableBotMod ? anchoColBot : anchoColBot * 2)
                              : index === 1
                                ? (opcMenuCall
                                    ? anchoColCont + (disableBotMod ? 0 : anchoColBot)
                                    : anchoColCont + (disableBotMod ? anchoColBot : anchoColBot * 2)) +
                                  fmtosTablaFiltroCampo(
                                    infoTablaLista.txtTabla,
                                    infoTablaLista.tabCampos[0],
                                    datTabla[0][infoTablaLista.tabCampos[0]],
                                    userEmpConfig.decimales).ancho
                                : null)
                          }}
                      onClick={ctrlClickHeadLista}
                    >{txtCampo}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody
                className='table-light'
                onClick={opcMenuCall ? ctrlClickLista : undefined}
                onDoubleClick={opcMenuCall ? undefined : ctrlDblClickLista}
              >
                {datTabla.map((registro, indexReg) => (
                  <tr key={registro.NO_CLIENTE}>
                    <td
                      key='INDEX' name='INDEX'
                      className='text-center'
                      style={{ position: 'sticky', left: 0 }}
                      id={registro.NO_CLIENTE} index={indexReg}
                    >{indexReg + 1}
                    </td>
                    <td
                      key='BOTON-MODIFICA' name='BOTON-MODIFICA'
                      style={{ position: 'sticky', left: anchoColCont }}
                      hidden={disableBotMod} index={indexReg}
                    >
                      <OverlayTrigger overlay={<Tooltip>modificar</Tooltip>}>
                        <Button
                          className={gcFmtosBotones.enTabla}
                          size='sm'
                          id={indexReg} index={indexReg}
                          style={styleBotsTbl}
                          onClick={ctrClickModificar}
                        ><img
                          className={gcFmtosBotones.imgTabla}
                          id={indexReg} index={indexReg}
                          src={imgModificar}
                          alt='modificar' width={widthImg}
                         />
                        </Button>
                      </OverlayTrigger>
                    </td>
                    {opcMenuCall
                      ? null
                      : <td
                          key='BOTON-ELIMINA' name='BOTON-ELIMINA'
                          hidden={disableBotEli} index={indexReg}
                          style={{ position: 'sticky', left: anchoColCont + (disableBotMod ? 0 : anchoColBot) }}
                        >
                          <OverlayTrigger overlay={<Tooltip>eliminar</Tooltip>}>
                            <Button
                              className={gcFmtosBotones.enTabla}
                              size='sm'
                              id={indexReg} index={indexReg}
                              style={styleBotsTbl}
                              onClick={ctrClickEliminar}
                            ><img
                              className={gcFmtosBotones.imgTabla}
                              id={indexReg} index={indexReg}
                              src={imgMenos}
                              alt='eliminar' width={widthImg}
                             />
                            </Button>
                          </OverlayTrigger>
                        </td>}
                    {infoTablaLista.tabCampos.map((campo, indexCampo) => (
                      <td
                        key={campo} name={campo}
                        className={fmtosTablaFiltroCampo(infoTablaLista.txtTabla, campo, registro[campo], userEmpConfig.decimales).fmto}
                        style={indexCampo === 0
                          ? {
                              position: 'sticky',
                              left: (opcMenuCall
                                ? anchoColCont + (disableBotMod ? 0 : anchoColBot)
                                : anchoColCont + (disableBotMod ? anchoColBot : anchoColBot * 2))
                            }
                          : indexCampo === 1
                            ? {
                                position: 'sticky',
                                left: (opcMenuCall
                                  ? anchoColCont + (disableBotMod ? 0 : anchoColBot)
                                  : anchoColCont + (disableBotMod ? anchoColBot : anchoColBot * 2)) +
                                  fmtosTablaFiltroCampo(
                                    infoTablaLista.txtTabla,
                                    infoTablaLista.tabCampos[0],
                                    datTabla[0][infoTablaLista.tabCampos[0]],
                                    userEmpConfig.decimales).ancho
                              }
                            : null}
                        id={registro.NO_CLIENTE}
                        index={indexReg}
                        hidden={infoTablaLista.txtCampos[indexCampo] === ''}
                      >{fmtosTablaFiltroCampo(infoTablaLista.txtTabla, campo, registro[campo], userEmpConfig.decimales).texto}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>}
    </>
  )
}

export default Clientes
