import restAPIAxios, { restAPIMySQL } from './axios'

// Obtiene los parámetros de la empresa
export async function getParamsEmp (poolName, orclUserVSAM, noEmp) {
  const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CARGAR LOS PARAMETROS DE LA EMPRESA',
    {
      method: 'get',
      url: `/empresas/${poolName}/${orclUserVSAM}/${noEmp}`
    }
  )
  if (respuestaAPI.status === 200) {
    return { status: respuestaAPI.status, datos: respuestaAPI.data }
  } else return { status: respuestaAPI.status, datos: [] }
}
// Obtiene los parámetros de la empresa del usuario web respectivo (equivalente a los parámetros de la COMPU)
export async function getParamsUserEmp (noUser, noEmp) {
  const respuestaAPI = await restAPIMySQL(`NO FUE POSIBLE CARGAR LOS PARAMETROS DE LA EMPRESA ${noEmp} - USUARIO  ${noUser}`,
    {
      method: 'get',
      url: `/userEmpresa/${noUser}/${noEmp}`
    }
  )
  if (respuestaAPI.status === 200) {
    // Convierte a JSON los campos respectivos porque en MySQL están como texto
    if (typeof respuestaAPI.data[0].textos_labels !== 'object') {
      respuestaAPI.data[0].textos_labels = JSON.parse(respuestaAPI.data[0].textos_labels)
    }
    if (typeof respuestaAPI.data[0].numeros_maximos !== 'object') {
      respuestaAPI.data[0].numeros_maximos = JSON.parse(respuestaAPI.data[0].numeros_maximos)
    }
    if (typeof respuestaAPI.data[0].series !== 'object') {
      respuestaAPI.data[0].series = JSON.parse(respuestaAPI.data[0].series)
    }
    return { status: respuestaAPI.status, datos: respuestaAPI.data }
  } else return { status: respuestaAPI.status, datos: [] }
}
// Obtiene el siguiente número de Factura o NC dependiendo de la serie
export async function getSigNoFactXSerie (serie, poolName, orclUsuarioVSAM, noEmpresaVSAM) {
  const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE OBTENER EL SIGUIENTE NUMERO DE ${serie.substring(0, 1) === 'B' ? 'NTA.CREDITO' : 'FACTURA'}`,
    {
      method: 'get',
      url: `/facturas/sigNoXSerie/${poolName}/${orclUsuarioVSAM}/${noEmpresaVSAM}/${serie}`
    }
  )
  if (respuestaAPI.status === 200) {
    const sigNoFact = respuestaAPI.data[0].SIG_NO_FACT
    return sigNoFact
  } else return (serie + '-000000000')
}
// Verifica si existe o no el No. de Factura
export async function existeNoFact (noFactura, poolName, orclUsuarioVSAM, noEmpresaVSAM) {
  const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE DETERMINAR SI EXISTE EL NUMERO DE ${noFactura.substring(0, 1) === 'B' ? 'NTA.CREDITO' : 'FACTURA'} '${noFactura}'`,
    {
      method: 'get',
      url: `/facturas/existeNoFact/${poolName}/${orclUsuarioVSAM}/${noEmpresaVSAM}/${noFactura}`
    }
  )
  if (respuestaAPI.status === 200) {
    return (respuestaAPI.data.length === 1)
  } else return false
}
// Verifica si existe o no el No. de Factura
export async function existeNoProf (noProforma, poolName, orclUsuarioVSAM, noEmpresaVSAM) {
  const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE DETERMINAR SI EXISTE EL NUMERO DE PROFORMA '${noProforma}'`,
    {
      method: 'get',
      url: `/proformas/existeNoProf/${poolName}/${orclUsuarioVSAM}/${noEmpresaVSAM}/${noProforma}`
    }
  )
  if (respuestaAPI.status === 200) {
    return (respuestaAPI.data.length === 1)
  } else return false
}
// Verifica que no grabe items con stock negativo
export async function factConStockNegativo (poolName, orclUserVSAM, noRegFact, noBodega) {
  const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE VALIDAR EL STOCK NEGATIVO EN LA VENTA ${noRegFact}`,
    {
      method: 'get',
      url: `/facturas/nueva/stockNeg/${poolName}/${orclUserVSAM}/${noRegFact}/${noBodega}`
    }
  )
  if (respuestaAPI.status === 200) {
    return (respuestaAPI.data)
  } else return -1
}
