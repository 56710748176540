import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'

import restAPIAxios from '../../utils/axios'
import { SesionContext, nuevaSesion } from '../../context/SesionContext'

const MnuLogin = () => {
  const navigate = useNavigate()

  // Utilizo CONTEXT para guardar los valores si el Login es correcto
  const [sesion, setSesion] = useContext(SesionContext)

  const poolName = sesion.orcl_host

  const cierraSesion = (e) => {
    // e.preventDefault()
    async function cierraBD () {
      const respuestaAPI = await restAPIAxios('',
        {
          method: 'post',
          url: '/logout',
          data: { poolName }
        }
      )
      // console.log(respuestaAPI)
      if (respuestaAPI.status !== 200) console.log(respuestaAPI)
    }

    Swal.fire({
      title: 'CONFIRME',
      text: 'Desea cerrar la sesión?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'SI',
      cancelButtonText: 'NO'
    }).then((result) => {
      if (result.isConfirmed) {
        setSesion(nuevaSesion(true))
        cierraBD()
        navigate('/login')
      }
    })
  }

  return (
    <Navbar className='justify-content-end me-3 p-'>
      <Button className='btn-sm' variant='outline-primary' onClick={cierraSesion}>
        Cerrar Sesión
      </Button>
    </Navbar>
  )
}

export default MnuLogin
