import { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import { Dropdown, SplitButton } from 'react-bootstrap'

import { SesionContext, nuevaSesion } from '../../context/SesionContext'
import encryptStorage from '../../utils/encryptStorage'

const MnuFacturas = () => {
  const navigate = useNavigate()
  const [sesion, setSesion] = useContext(SesionContext)
  // console.log(sesion)
  const userVSAM = encryptStorage.getItem('userVSAM')
  if (!userVSAM) return null
  // Asigna si tiene acceso a todo el botón (menú Facturación y opción Ventas)
  const accMnuFact = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_FACTURACION) === -1
  const accMnuVtas = userVSAM.RESTRICC_ACC_MENU.indexOf(process.env.REACT_APP_ACC_MNU_VENTAS) === -1
  const accCrear = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_CREA) === -1
  const accModificar = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_MOD) === -1

  const ctrlClickNuevas = (e) => {
    if (accCrear) {
    // Por si acaso este modificando una Factura
    // console.log(sesion)
      const nSesion = nuevaSesion(false, sesion,
        {
          facturas: { clave: 0, index: 0, modificando: false, filtrando: false }
        })
      setSesion(nSesion)
      navigate('/facturas/nuevas')
    } else {
      Swal.fire(
        'NO TIENE ACCESO A CREAR FACTURAS',
        'Elija otra opción de menú',
        'info'
      )
    }
  }

  function ctrlClickExistente (e) {
    // console.log('ctrlClickExistente', sesion)
    const nSesion = nuevaSesion(false, sesion,
      {
        facturas: {
          clave: sesion.facturas.clave,
          index: sesion.facturas.index,
          modificando: false,
          filtrando: true
        }
      })
    setSesion(nSesion)
    navigate('/facturas/existentes')
  }

  function ctrlClickModificando (e) {
    // console.log(sesion)
    const nSesion = nuevaSesion(false, sesion,
      {
        facturas: { clave: 0, index: 0, modificando: true, filtrando: false }
      })
    setSesion(nSesion)
    navigate('/facturas/nuevas')
  }

  return (
    <SplitButton
      className='bg-secondary-subtle me-3'
      size='sm'
      variant='outline-secondary text-black'
      title='Facturas'
      disabled={!accMnuFact}
      onClick={ctrlClickNuevas}
    >
      <Dropdown.Item
        className='p-0'
        size='sm'
        disabled={!accMnuVtas || !accCrear}
      >
        <Link
          className={'dropdown-item' + ((accMnuVtas && accCrear) ? '' : ' disabled')}
          to='/facturas/nuevas' onClick={ctrlClickNuevas}
        >Nuevas
        </Link>
      </Dropdown.Item>
      <Dropdown.Item
        className='p-0'
        size='sm'
        disabled={!accMnuVtas}
      >
        <Link
          className={'dropdown-item' + (accMnuVtas ? '' : ' disabled')}
          to='/facturas/existentes' onClick={ctrlClickExistente}
        >Existentes
        </Link>
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        className='p-0'
        size='sm'
        disabled={!accMnuVtas || !accModificar}
      >
        <Link
          className={'dropdown-item' + (accMnuVtas && accModificar ? '' : ' disabled')}
          to='/facturas/nuevas' onClick={ctrlClickModificando}
        >Modificando
        </Link>
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        className='p-0'
        size='sm'
      >
        <Link
          className='dropdown-item'
          to='/facturas/reporte'
        >Reporte
        </Link>
      </Dropdown.Item>
    </SplitButton>
  )
}

export default MnuFacturas
