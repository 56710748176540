import { Outlet } from 'react-router-dom'
import { Card } from 'react-bootstrap'

// Layout
import Header from './Header'
import Footer from './Footer'
import Navegacion from '../menu/Navegacion'

function VisualSAMWeb () {
  return (
    <>
      <Header />
      <Card className='border-primary-subtle'>
        <Card.Header className='p-0'>
          <Navegacion />
        </Card.Header>
        <Card.Body className='p-0'>
          <Outlet />
        </Card.Body>
        <Footer />
      </Card>
    </>
  )
}

export default VisualSAMWeb
