import { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import { Dropdown, Form, InputGroup, Row, Spinner } from 'react-bootstrap'

import restAPIAxios from '../../utils/axios'
import { SesionContext } from '../../context/SesionContext'
import encryptStorage from '../../utils/encryptStorage'

import { anchoPantDisponible, fmtosTablaFiltroCampo, gcTablasLogicas, gcFmtoMaxRows } from '../../utils/generales'

function TablaFiltro (props) {
  const {
    url, maxRegs, txtTabla, tabCampos, txtLblTabla, txtCampos, campoFiltro, condFija, condBodega,
    condGrupo, obligatorio, deshabilitado, sinPlaceHolder, fmto
  } = props.infoTablaLista
  const filtrando = props.filtrando
  const [sesion] = useContext(SesionContext)
  const [datosTabla, setDatosTabla] = useState([])
  const [filtro, setFiltro] = useState('')
  const [registroSel, setRegistroSel] = useState({})
  const [cargando, setCargando] = useState(false)

  const [fmtoNoExiste, setFmtoNoExiste] = useState('')
  const FMTO_NO_EXISTE = ' bg-warning-subtle'

  const infoTActualiza = props.infoTablaActualiza
  const noActualiceTabla = (infoTActualiza === undefined)

  // Extrae los datos de la empresa (mysql)
  const userEmpConfig = encryptStorage.getItem('config')
  const poolName = sesion.orcl_host
  const idRowFiltro = 'tabla-' + txtTabla + '-' + campoFiltro.enTabla

  useEffect(() => {
    // Para que oculte la tabla en lugar de tener abierta y para obligar a volver a cargar datos
    setDatosTabla([])
    // Para que borre el FMTO_NO_EXISTE en caso de haberlo tenido antes
    setFmtoNoExiste('')

    // Para que no vaya UNDEFINED al valor del combo (sale un Warning)
    setFiltro(props.children ?? '')
  }, [props.children])

  // FUNCIONES PRIVADAS DEL COMPONENTE
  const claveReg = () => {
    switch (infoTActualiza.tabla) {
      case 'T_PROFORMA_TMP': return sesion.proformas.clave
      case 'T_FACTURA_TMP': return sesion.facturas.clave
      default: return 0
    }
  }
  const condClaveUnica = () => {
    let condTxt = ''
    let condNoTxt = ''
    // Evita que se envíe al API el AND sin valor
    if (filtro) {
      condTxt = `and ${campoFiltro.enSql} = '${filtro}'`
      condNoTxt = `and ${campoFiltro.enSql} = ${filtro}`
    }
    let respuesta = ''
    switch (txtTabla) {
      case gcTablasLogicas.cliente:
        if (registroSel.KEY_TAB) respuesta = `and NO_CLIENTE = ${registroSel.KEY_TAB}`
        // Evita que se envíe al API el AND sin valor
        respuesta = ''
        break
      case gcTablasLogicas.grupoItem:
        respuesta = condTxt
        break
      case gcTablasLogicas.item:
        respuesta = campoFiltro.enTabla === 'NO_ITEM_USER' ? condNoTxt : condTxt
        break
      default:
        respuesta = ''
        break
    }
    return respuesta
  }
  // Verifica si el filtro es una clave única válida
  function esFiltroClaveUnica () {
    switch (txtTabla) {
      case gcTablasLogicas.item: return (campoFiltro.enTabla === 'NO_ITEM_USER' || campoFiltro.enTabla === 'CODIGO')
      case gcTablasLogicas.grupoItem: return (campoFiltro.enTabla === 'CODIGO')
      default: return false
    }
  }
  // Asigna el body que se envía en el RestAPI que captura el registro único
  const bodyBuscaClaveUnica = {
    method: 'post',
    url,
    data: txtTabla === gcTablasLogicas.item
      ? {
          poolName,
          orclUserVSAM: sesion.orcl_usuarioVSAM,
          noEmp: sesion.no_empresaVSAM,
          campoFiltro,
          filtro,
          condFija,
          condBodega,
          condGrupo,
          condClaveUnica: condClaveUnica()
        }
      : {
          poolName,
          orclUserVSAM: sesion.orcl_usuarioVSAM,
          noEmp: sesion.no_empresaVSAM,
          campoFiltro,
          filtro,
          condFija,
          condClaveUnica: condClaveUnica()
        }
  }

  async function actualizaCampo (valor) {
    setCargando(true)
    const infoTablaCampo = {
      poolName,
      tabla: infoTActualiza.tabla,
      campo: infoTActualiza.campo,
      valor,
      cond: infoTActualiza.campoClave + '=' + claveReg(),
      ceroEsNulo: false
    }
    const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ACTUALIZAR EL CAMPO EN LA BD',
      {
        method: 'patch',
        url: infoTActualiza.url,
        data: infoTablaCampo
      }
    )
    if (respuestaAPI.status === 200) {
      if (respuestaAPI.data[0] === 0) {
        Swal.fire({
          title: 'EL VALOR NO SE ACTUALIZO EN LA BD',
          text: 'consulte a su técnico',
          footer: 'La respuesta de la API fue 0',
          icon: 'info'
        })
        setCargando(false)
        return false
      } else {
        setCargando(false)
        return true
      }
    } else {
      setCargando(false)
      return false
    }
  }
  function tamanoLetra () {
    switch (txtTabla) {
      case gcTablasLogicas.item:
        if (['DESCRIP', 'DESCRIP_AUX', 'OTROS_COD'].includes(campoFiltro.enTabla)) return { fontSize: '11px' }
        else return {}
      default: return {}
    }
  }

  // FUNCIONES DE CONTROL (EVENTOS) DE ELEMENTOS DEL COMPONENTE
  // Carga los registros de la Tabla para selección
  async function ctrlClickToggle (e) {
    // e.preventDefault()
    if (!cargando && datosTabla.length > 0) {
      setCargando(false)
      setDatosTabla([])
    } else {
      setCargando(true)
      const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE CARGAR ${txtTabla}`,
        {
          method: 'post',
          url,
          data: txtTabla === gcTablasLogicas.item
            ? {
                poolName,
                orclUserVSAM: sesion.orcl_usuarioVSAM,
                noEmp: sesion.no_empresaVSAM,
                campoFiltro,
                filtro,
                condFija,
                condBodega,
                condGrupo,
                condClaveUnica: ''
              }
            : {
                poolName,
                orclUserVSAM: sesion.orcl_usuarioVSAM,
                noEmp: sesion.no_empresaVSAM,
                campoFiltro,
                filtro,
                condFija,
                condClaveUnica: ''
              }
        }
      )
      if (respuestaAPI.status === 200) {
        if (respuestaAPI.data.length > maxRegs) {
          Swal.fire(
            `EL FILTRO SELECCIONADO CARGA MAS DE ${maxRegs} ${txtTabla} (${respuestaAPI.data.length})`,
            'Elija un filtro que reduzca la cantidad de registros para seleccionar',
            'info'
          )
        } else {
          setDatosTabla(respuestaAPI.data)
        }
      }
      setCargando(false)
    }
  }
  function ctrlChangeFiltro (e) {
    // e.preventDefault()
    setFiltro(e.target.value)
    // No se debe devolver el filtro (SOLO SI NO ESTOY FILTRANDO) para que aparezca el color amarillo,
    // que muestra si el campo no es el mismo que el de la BD
    if (props.devuelveFiltro && filtrando) props.devuelveFiltro(e.target.value)
  }
  async function ctrlBlurFiltro (e) {
    // e.preventDefault()
    // No hubo cambios en el campo
    if (e.target.value === props.children) return
    // (e.target.value = filtro) ==> ya que se cambia el filtro en ctrlChangeFiltro
    // Actualiza el campo a NULL
    if (e.target.value === '' && props.children !== null) {
      setRegistroSel({})
      setDatosTabla([])
      if (props.devuelveRegistro) props.devuelveRegistro(campoFiltro.enTabla, '', [])
      if (noActualiceTabla) {
        setFiltro('')
        if (props.devuelveFiltro) props.devuelveFiltro('')
      } else
        if (await actualizaCampo(null)) {
          setFiltro('')
          if (props.devuelveFiltro) props.devuelveFiltro('')
        } else {
          !props.children ? setFiltro('') : setFiltro(props.children)
          if (props.devuelveFiltro) props.devuelveFiltro(!props.children ? '' : props.children)
        }
    // Busca el dato en la BD cuando es un filtro con clave única y con valor
    // y siempre que aún no se haya elegido un registro en la tabla respectiva
    // y también siempre que haya cambiado el valor del registro seleccionado
    } else if (esFiltroClaveUnica() && e.target.value !== '' && (!registroSel[campoFiltro.enTabla] || registroSel[campoFiltro.enTabla].toString() !== e.target.value)) {
      setDatosTabla([])
      if (props.devuelveFiltro) props.devuelveFiltro(e.target.value)
      setCargando(true)
      const respuestaAPI = await restAPIAxios('', bodyBuscaClaveUnica)
      // Encontro el registro
      if (respuestaAPI.status === 200) {
        if (noActualiceTabla) {
          setRegistroSel(respuestaAPI.data[0])
          if (props.devuelveRegistro) props.devuelveRegistro(campoFiltro.enTabla, e.target.value, respuestaAPI.data[0])
        } else {
          if (await actualizaCampo(respuestaAPI.data[0].KEY_TAB)) {
            setRegistroSel(respuestaAPI.data[0])
            if (props.devuelveRegistro) props.devuelveRegistro(campoFiltro.enTabla, e.target.value, respuestaAPI.data[0])
          } else {
            setRegistroSel({})
            if (props.devuelveRegistro) props.devuelveRegistro(campoFiltro.enTabla, e.target.value, {})
          }
        }
        setFmtoNoExiste('')
      // ** no debe hacer nada **
      // Hubo un error o no encuentra el registro para el valor (e.target.value) digitado
      } else {
        setFmtoNoExiste(e.target.value ? FMTO_NO_EXISTE : '')
        setRegistroSel({})
        if (props.devuelveRegistro && !['', '*', '%'].includes(e.target.value)) props.devuelveRegistro(campoFiltro.enTabla, e.target.value, {})
      }
      setCargando(false)
    }
  }
  async function ctrlClickLista (e) {
    // e.preventDefault()
    const index = e.target.getAttribute('index')
    const nuevoFiltro = datosTabla[index][campoFiltro.enTabla]
    // En caso de que presione en la cabecera u otro lugar donde index es null
    if (index) {
      if (noActualiceTabla) {
        setRegistroSel(datosTabla[index])
        setFiltro(nuevoFiltro)
        setDatosTabla([])
        if (props.devuelveFiltro) props.devuelveFiltro(nuevoFiltro)
        if (props.devuelveRegistro) props.devuelveRegistro(campoFiltro.enTabla, nuevoFiltro, datosTabla[index])
      } else {
        // actualiza el Campo en la Tabla
        if (await actualizaCampo(datosTabla[index].KEY_TAB)) {
          setFiltro(datosTabla[index][campoFiltro.enTabla])
          setRegistroSel(datosTabla[index])
          setDatosTabla([])
          if (props.devuelveFiltro) props.devuelveFiltro(nuevoFiltro)
          if (props.devuelveRegistro) props.devuelveRegistro(campoFiltro.enTabla, nuevoFiltro, datosTabla[index])
        } else {
          !props.children ? setFiltro('') : setFiltro(props.children)
          setRegistroSel({})
          setDatosTabla([])
          if (props.devuelveFiltro) props.devuelveFiltro(!props.children ? '' : props.children)
          if (props.devuelveRegistro) props.devuelveRegistro(campoFiltro.enTabla, '', [])
        }
      }
    }
  }
  function ctrlDblClick (e) {
    // e.preventDefault()
    if (props.devuelveDblClick === undefined) {
      Swal.fire(
        'NO ESTA ACTIVA LA OPCION DE ORDENADO PARA ESTE CAMPO',
        '',
        'info'
      )
    } else props.devuelveDblClick()
  }

  if (!sesion.auth) return null
  return (
    <>
      <Row className='' id={idRowFiltro}>
        <InputGroup className={fmto[0]} size='sm'>
          <InputGroup.Text
            id={'lblTabla-' + txtTabla + '-' + campoFiltro.enTabla} className={fmto[1]}
            onDoubleClick={ctrlDblClick}
          >{txtLblTabla}
          </InputGroup.Text>
          <Spinner animation='grow' hidden={!cargando} />
          <Form.Control
            id={'tablaFiltro-' + txtTabla + '-' + campoFiltro.enTabla}
            className={fmto[2] + (filtro === '' ? '' : fmtoNoExiste)}
            style={tamanoLetra()}
            type='text'
            value={filtro}
            placeholder={sinPlaceHolder ? '' : `Elija un filtro para obtener ${txtTabla.toLowerCase()}`}
            aria-describedby={'lblTabla-' + txtTabla + '-' + campoFiltro.enTabla}
            required={obligatorio}
            disabled={deshabilitado}
            onChange={ctrlChangeFiltro}
            onBlur={ctrlBlurFiltro}
          />
          <Dropdown.Toggle
            split
            variant='outline-secondary'
            id='tablaCtrl'
            disabled={!filtro}
            hidden={deshabilitado}
            onClick={ctrlClickToggle}
          />
        </InputGroup>
      </Row>
      <div
        id='tablaDiv'
        className='overflow-scroll border border-3'
        style={{ maxHeight: 400, minWidth: anchoPantDisponible(idRowFiltro) }}
        hidden={datosTabla.length === 0}
      >
        <table
          className='table table-bordered table-sm table-hover table-responsive-md'
          style={{ fontSize: '11px' }}
          onClick={ctrlClickLista}
        >
          <thead>
            <tr className='table-primary sticky-top'>
              <th
                key={0}
                className={' border border-primary ' + gcFmtoMaxRows(datosTabla.length, maxRegs)}
              >{datosTabla.length}
              </th>
              {txtCampos.map((txtCampo, index) => (
                <th
                  key={index + 1}
                  className={datosTabla.length === 0
                    ? ''
                    : fmtosTablaFiltroCampo(txtTabla, tabCampos[index], datosTabla[0][tabCampos[index]], userEmpConfig.decimales).fmto}
                  hidden={txtCampo === ''}
                  style={{
                    minWidth: datosTabla.length === 0
                      ? 0
                      : fmtosTablaFiltroCampo(txtTabla, tabCampos[index], datosTabla[0][tabCampos[index]], userEmpConfig.decimales).ancho
                  }}
                >{txtCampo}
                </th>
              ))}
            </tr>
          </thead>
          <tbody
            className='table-light'
          >
            {datosTabla.map((registro, indexReg) => (
              <tr key={registro.KEY_TAB}>
                <td
                  key='INDEX'
                  className='text-center'
                  id={registro.KEY_TAB}
                  index={indexReg}
                >{indexReg + 1}
                </td>
                {tabCampos.map((campo, indexCampo) => (
                  <td
                    key={campo}
                    className={fmtosTablaFiltroCampo(txtTabla, campo, registro[campo], userEmpConfig.decimales).fmto}
                    id={registro.KEY_TAB}
                    index={indexReg}
                    hidden={txtCampos[indexCampo] === ''}
                  >{fmtosTablaFiltroCampo(txtTabla, campo, registro[campo], userEmpConfig.decimales).texto}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default TablaFiltro
