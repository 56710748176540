import { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import { SesionContext, nuevaSesion } from '../context/SesionContext.jsx'
import { getParamsEmp } from '../utils/bd.js'
import verificaAuth from '../utils/verificaAuth.js'
import encryptStorage from '../utils/encryptStorage.js'

export function useGetParamsEmp () {
  const [sesion] = useContext(SesionContext)
  const [params, setParams] = useState('')
  useEffect(() => {
    async function getParams () {
      const paramsEmp = await getParamsEmp(sesion.orcl_host, sesion.orcl_usuarioVSAM, sesion.no_empresaVSAM)
      if (paramsEmp.status === 200) setParams(paramsEmp.datos[0])
      // Es mejor devolver un string vacio cuando hay error, para simplificar el if() respectivo
      else setParams('')
    }
    getParams()
  }, [sesion.orcl_host, sesion.orcl_usuarioVSAM, sesion.no_empresaVSAM])
  return params
}

export function useVerificaToken () {
  const navigate = useNavigate()
  const [sesion, setSesion] = useContext(SesionContext)
  useEffect(() => {
    async function verifica () {
      try {
        const resVerifAuth = await verificaAuth(sesion)
        // Maneja el error de Verificación de la Autenticación
        if (resVerifAuth.ok) {
          const sesionStorage = encryptStorage.getItem('sesion')
          // console.log(sesionStorage)
          // Token válido pero sin autorización (hay que reasignar)
          if (resVerifAuth.opcion === 2) {
            Swal.fire(
              'LA AUTORIZACION DE SU ULTIMA SESION SE RECUPERO CON EXITO',
                            `Usuario reconocido: ${resVerifAuth.msj.nombre}`,
                            'info'
            )
            // Actualiza la autorización (sesion) para los controles
            setSesion({ ...sesionStorage, auth: true })
          }
          // Token inválido o con error
        } else {
          switch (resVerifAuth.opcion) {
            // Hubo error
            case 0:
              // console.log(resVerifAuth.msj, typeof resVerifAuth.msj)
              Swal.fire(
                'ERROR AL TRATAR DE VERIFICAR LOS DATOS DEL USUARIO',
                resVerifAuth.msj.TypeError,
                'error'
              )
              navigate('/login')
              setSesion(nuevaSesion(true))
              break
              // El token expiró
            case 3:
              Swal.fire(
                'EL TIEMPO DE SU SESION EXPIRO',
                'Para ingresar nuevamente al sistema, debe digitar su usuario y contraseña',
                'info'
              )
              navigate('/login')
              setSesion(nuevaSesion(true))
              break
            // redireccionar a Login sin mensaje
            case 4:
              navigate('/login')
              break
            default:
              navigate('/login')
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    verifica()
  }, [])
  // No van dependencias en este useEffect porque se debe ejecutar una sola vez al cargar la pantalla
}
