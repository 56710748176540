import Navbar from 'react-bootstrap/Navbar'

import MnuFacturas from './MnuFacturas'
import MnuProformas from './MnuProformas'
import MnuClientes from './MnuClientes'

const MnuPrincipal = () => {
  return (
    <Navbar className='' collapseOnSelect expand='sm'>
      <Navbar.Toggle aria-controls='menu-principal' />
      <Navbar.Collapse className='justify-content' id='menu-principal'>
        <MnuProformas />
        <MnuFacturas />
        <MnuClientes />
      </Navbar.Collapse>
    </Navbar>
  )
}

export default MnuPrincipal
