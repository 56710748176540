/* eslint-disable camelcase */
import React, { useContext, useState } from 'react'
import encryptStorage from '../utils/encryptStorage'

// const SesionContext = React.createContext([{}, () => {}])
const SesionContext = React.createContext()

function useSesion () {
  return useContext(SesionContext)
}

const sesionVacia = {
  token: '',
  userNombre: '** Sin Usuario **',
  no_usuarioVSAM: 0,
  orcl_host: '',
  orcl_usuarioVSAM: '',
  no_empresaVSAM: 0,
  email_config: { auth: { pass: '', user: '' }, secure: true, service: 'gmail' },
  proformas: { clave: 0, index: 0, modificando: false, filtrando: false },
  facturas: { clave: 0, index: 0, modificando: false, filtrando: false },
  auth: false
}

const SesionProvider = ({ children }) => {
  // definir el state inicial
  let sesionIni
  // No hay ningún elemento en el sessionStorage
  if (encryptStorage.length === 0) {
    sesionIni = sesionVacia
    // Para recuperar los datos de la última sesión en caso de haber usado
    // navigate(...) u otra forma de ir directamente a una página específica
  } else {
    // console.log('encryptStorage.length === ' + encryptStorage.length)
    const {
      token, userNombre, no_usuarioVSAM, orcl_host, orcl_usuarioVSAM, no_empresaVSAM
      , email_config, proformas, facturas
    } = encryptStorage.getItem('sesion')
    sesionIni = {
      token,
      userNombre,
      no_usuarioVSAM,
      orcl_host,
      orcl_usuarioVSAM,
      no_empresaVSAM,
      email_config,
      proformas,
      facturas,
      auth: true
    }
  }
  const [sesion, setSesion] = useState(sesionIni)

  return (
    <SesionContext.Provider value={[sesion, setSesion]}>
      {children}
    </SesionContext.Provider>
  )
}
// Devuelve el objeto Sesion (solo cambia los campos que se pasan por parámetro)
function nuevaSesion (limpiaSesion, sesion, cambios) {
  let nuevaSesion
  if (limpiaSesion) {
    // console.log('nuevaSesion true');
    nuevaSesion = {
      token: '',
      userNombre: '** Sin Usuario **',
      no_usuarioVSAM: 0,
      orcl_host: '',
      orcl_usuarioVSAM: '',
      no_empresaVSAM: 0,
      email_config: { auth: { pass: '', user: '' }, secure: true, service: 'gmail' },
      proformas: { clave: 0, index: 0, modificando: false, filtrando: false },
      facturas: { clave: 0, index: 0, modificando: false, filtrando: false },
      auth: false
    }
  } else {
    nuevaSesion = { ...sesion, ...cambios }
    // Object.assign(nuevaSesion, cambios)
  }
  const sesionSinAuth = { ...nuevaSesion }
  delete sesionSinAuth.auth
  // console.log('nuevaSesion', nuevaSesion, 'sesionSinAuth', sesionSinAuth);
  if (limpiaSesion) encryptStorage.clear()
  else encryptStorage.setItem('sesion', nuevaSesion)

  return nuevaSesion
}

export { useSesion, SesionContext, SesionProvider, nuevaSesion }
