import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import { Button, Container, Col, Form, OverlayTrigger, Tooltip, Row, SplitButton, Dropdown } from 'react-bootstrap'

import { SesionContext, nuevaSesion } from '../../../context/SesionContext.jsx'
import restAPIAxios from '../../../utils/axios.js'
import encryptStorage from '../../../utils/encryptStorage.js'

import { sriDatosDoc, gcFmtosBotones } from '../../../utils/generales.js'

import imgGrabar from '../../../iconos/GRABAR.BMP'
import imgCancelar from '../../../iconos/CANCELAR.BMP'
import imgModificar from '../../../iconos/MODIFICAR.ICO'
import imgFiltrar from '../../../iconos/FILTRAR.ICO'
import imgListado from '../../../iconos/LISTADO.BMP'
import imgImprimir from '../../../iconos/IMPRIMIR.ICO'
import imgMail from '../../../iconos/MAIL.ICO'

import imgMas from '../../../iconos/MAS.ICO'
import imgMenos from '../../../iconos/MENOS.ICO'

import imgPrimero from '../../../iconos/PRIMERO.PNG'
import imgAnterior from '../../../iconos/ANTERIOR.PNG'
import imgSiguiente from '../../../iconos/SIGUIENTE.PNG'
import imgUltimo from '../../../iconos/ULTIMO.PNG'

function Botones (props) {
  const totalRegs = props.totalRegs
  const existentes = props.existentes
  const regActual = props.datosReg
  const editando = props.editando
  const diasCre = props.diasCre
  const esElect = props.esElect
  const paramsEmp = props.paramsEmp
  const datosFPs = props.datosFPs

  const navigate = useNavigate()
  const [sesion, setSesion] = useContext(SesionContext)

  const [grabando, setGrabando] = useState(false)

  const userVSAM = encryptStorage.getItem('userVSAM')
  const accCrear = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_CREA) === -1
  const accModificar = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_MOD) === -1
  const accEliminar = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_ELI) === -1
  const accSRIAutoriz = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_SRI_AUTORIZ) === -1
  // const accSRIAutorizMod = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_SRI_AUTORIZ_MOD) >= 0
  const accFormasPago = userVSAM.RESTRICC_ACC_FACT.indexOf(process.env.REACT_APP_ACC_FACT_FORMAS_PAGO) === -1

  const userEmpConfig = encryptStorage.getItem('config')

  const poolName = sesion.orcl_host

  function ctrlClickSiguiente (e) {
    // e.preventDefault()
    if (sesion.facturas.index < totalRegs - 1) {
      props.devuelveSiguiente()
    }
  }
  function ctrlClick10Sig (e) {
    // e.preventDefault()
    if (sesion.facturas.index < totalRegs - 10) {
      props.devuelve10Sig()
    }
  }
  function ctrlClickUltima (e) {
    // e.preventDefault()
    if (sesion.facturas.index !== totalRegs - 1) {
      props.devuelveUltima()
    }
  }

  function ctrlClickAnterior (e) {
    // e.preventDefault()
    if (sesion.facturas.index > 0) {
      props.devuelveAnterior()
    }
  }
  function ctrlClick10Ant (e) {
    // e.preventDefault()
    if (sesion.facturas.index > 9) {
      props.devuelve10Ant()
    }
  }
  function ctrlClickPrimera (e) {
    // e.preventDefault()
    if (sesion.facturas.index !== 0) {
      props.devuelvePrimera()
    }
  }

  function ctrlClickNuevoReg (e) {
    // e.preventDefault()
    const maxNuevas = userEmpConfig.numeros_maximos ? userEmpConfig.numeros_maximos.factNuevas : 5
    if (totalRegs >= maxNuevas) {
      Swal.fire(
        'NO ES POSIBLE CREAR MAS FACTURAS NUEVAS',
        `El número máximo de facturas nuevas permitido es ${maxNuevas}`,
        'info'
      )
      return
    }
    async function insNuevoReg () {
      const serie = props.children.substr(0, 8)
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CREAR UNA NUEVA FACTURA',
        {
          method: 'post',
          url: '/facturas/nuevas',
          data: {
            poolName,
            orclUserVSAM: sesion.orcl_usuarioVSAM,
            noEmp: sesion.no_empresaVSAM,
            noUser: sesion.no_usuarioVSAM,
            serie
          }
        }
      )
      if (respuestaAPI.status === 200) {
        const nSesion = nuevaSesion(false, sesion,
          {
            facturas: {
              clave: respuestaAPI.data[0].NO_REG_CREADO,
              index: respuestaAPI.data[0].CONT_REGS - 1,
              modificando: sesion.facturas.modificando,
              filtrando: sesion.facturas.filtrando
            }
          })
        setSesion(nSesion)
      }
    }
    insNuevoReg()
  }
  function ctrlClickEliminaReg (e) {
    // e.preventDefault()
    async function deleteReg () {
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE ELIMINAR EL REGISTRO',
        {
          method: 'delete',
          url: `/facturas/nuevas/${sesion.facturas.clave}`,
          data: { poolName }
        }
      )
      if (respuestaAPI.status === 200) {
        const nuevoIndex = sesion.facturas.index === 0 ? 0 : sesion.facturas.index - 1
        const nSesion = nuevaSesion(false, sesion,
          {
            facturas: {
              clave: -1,
              index: nuevoIndex,
              modificando: sesion.facturas.modificando,
              filtrando: sesion.facturas.filtrando
            }
          })
        setSesion(nSesion)
      // No hace falta porque ya devuelve un mejor mensaje en el restAPIAxios(...
      // } else {
      //   // (respuestaAPI.message) Estructura de error de Axios
      //   Swal.fire(
      //     'ERROR AL TRATAR DE ELIMINAR EL REGISTRO',
      //     respuestaAPI.message,
      //     'error',
      //   );
      }
    }
    Swal.fire({
      title: 'CONFIRME',
      text: `Desea eliminar el registro ${sesion.facturas.index + 1}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'SI',
      cancelButtonText: 'NO'
    }).then((result) => {
      if (result.isConfirmed) deleteReg()
    })
  }
  function ctrlClickGrabar (e) {
    // e.preventDefault()
    props.factValida().then((esValida) => {
      const noFactura = modificando ? regActual.NO_FACTURA : props.children
      const esNC = (noFactura.substring(0, 1) === 'B')
      const fFactura = regActual.FECHA
      if (esValida) {
        async function grabaFactura () {
          setGrabando(true)
          props.estaGrabando({ enProceso: true, msjPantalla: 'Grabando...' })
          const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE ${modificando ? 'MODIFICAR' : 'GRABAR'} LA FACTURA`,
            {
              method: 'post',
              url: modificando ? '/facturas/modifica' : '/facturas/crea',
              data: modificando
                ? {
                    poolName,
                    orclUserVSAM: sesion.orcl_usuarioVSAM,
                    noEmp: sesion.no_empresaVSAM,
                    noUser: sesion.no_usuarioVSAM,
                    noReg: sesion.facturas.clave,
                    esElect,
                    rutaCarpeta: userEmpConfig.ruta_carpetas,
                    p12Nombre: userEmpConfig.p12_nombre,
                    p12Clave: userEmpConfig.p12_clave,
                    obsDatoAdic: userEmpConfig.obs_dato_adicional,
                    cualCodEnviaSRI: paramsEmp.CTRL_ITEMS_CODS_XML_ENVIA_SRI,
                    decimales: userEmpConfig.decimales,
                    accSRIAutoriz
                  }
                : {
                    poolName,
                    orclUserVSAM: sesion.orcl_usuarioVSAM,
                    noEmp: sesion.no_empresaVSAM,
                    noUser: sesion.no_usuarioVSAM,
                    noReg: sesion.facturas.clave,
                    noFactura,
                    fFactura,
                    diasCre,
                    esElect,
                    rutaCarpeta: userEmpConfig.ruta_carpetas,
                    p12Nombre: userEmpConfig.p12_nombre,
                    p12Clave: userEmpConfig.p12_clave,
                    obsDatoAdic: userEmpConfig.obs_dato_adicional,
                    decimales: userEmpConfig.decimales,
                    cualCodEnviaSRI: paramsEmp.CTRL_ITEMS_CODS_XML_ENVIA_SRI,
                    datosFPs,
                    seriesIng: userEmpConfig.series.ing,
                    accSRIAutoriz
                  }
            }
          )
          setGrabando(false)
          props.estaGrabando({ enProceso: false, msjPantalla: '' })
          if (respuestaAPI.status === 200) {
            const respuestaData = respuestaAPI.data.resultado
            const nuevoNoFact = respuestaData.length > 0 ? respuestaData[0].NO_FACTURA : ''
            if (respuestaAPI.data.msjAdic) {
              Swal.fire({
                title: `LA FACTURA SE ${modificando ? 'MODIFICO' : 'GRABO'} CON EXITO, PERO FALLO LA AUTORIZACION DEL SRI`,
                text: noFactura === nuevoNoFact ? '' : `El sistema grabó la factura con el No. ${nuevoNoFact}`,
                footer: respuestaAPI.data.respuesta + '\n' + respuestaAPI.data.msjAdic,
                icon: 'info'
              })
            } else {
              Swal.fire(
                `LA FACTURA SE ${modificando ? 'MODIFICO' : 'GRABO'} CON EXITO`,
                noFactura === nuevoNoFact ? '' : `El sistema grabó la factura con el No. ${nuevoNoFact}`,
                'success'
              )
            };
            if (modificando) {
              // No se pudo recuperar la nueva factura modificada
              if (respuestaData.length === 0) {
                const nSesion = nuevaSesion(false, sesion,
                  {
                    facturas: { clave: 0, index: 0, modificando: false, filtrando: true }
                  })
                setSesion(nSesion)
              // Si se pudo recuperar la nueva factura modificada y envía a existentes para verla
              } else {
                props.devuelveDatDoc(respuestaData, true, true)
                const nSesion = nuevaSesion(false, sesion,
                  {
                    facturas: { clave: regActual.NO_FACTURA, index: 0, modificando: false, filtrando: false }
                  })
                setSesion(nSesion)
                navigate('/facturas/existentes')
              }
            } else props.devuelveDatDoc(respuestaData, false, true)
          } else {
            if (respuestaAPI.response.status === 500 && respuestaAPI.response.data.yaExiste) {
              Swal.fire(
                'LA FACTURA YA EXISTE',
                'Cambie el número de factura y vuelva a intentarlo',
                'info'
              )
            }
          }
        }
        Swal.fire({
          title: 'CONFIRME',
          text: 'Los datos ingresados son correctos?',
          footer: !esNC && datosFPs.sinAsignar ? '** La factura se grabará sin ninguna Forma de Pago' : '',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'SI',
          cancelButtonText: 'NO'
        }).then((result) => {
          if (result.isConfirmed) grabaFactura()
        })
      }
    })
  }
  function ctrClickModificar (e) {
    // e.preventDefault()
    async function modificaFactura () {
      setGrabando(true)
      props.estaGrabando({ enProceso: true, msjPantalla: 'Modificando...' })
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE PREPARAR LA FACTURA PARA SU MODIFICACION',
        {
          method: 'post',
          url: '/facturas/preModifica',
          data: {
            poolName,
            orclUserVSAM: sesion.orcl_usuarioVSAM,
            noEmp: sesion.no_empresaVSAM,
            noUser: sesion.no_usuarioVSAM,
            noFactura: props.children
          }
        }
      )
      setGrabando(false)
      props.estaGrabando({ enProceso: false, msjPantalla: '' })
      // console.log(respuestaAPI)
      if (respuestaAPI.status === 200) {
        // Coloca la clave devuelta (NO_REG) del registro creado para la modificación
        const claveFact = respuestaAPI.data[0]
        const nSesion = nuevaSesion(false, sesion,
          {
            facturas: {
              clave: claveFact,
              index: sesion.facturas.index,
              modificando: true,
              filtrando: sesion.facturas.filtrando
            }
          })
        setSesion(nSesion)
        navigate('/facturas/nuevas')
      } else {
        // console.log(respuestaAPI.response.status, respuestaAPI.response.data)
        if (respuestaAPI.response.status === 500 && respuestaAPI.response.data.yaExiste) {
          Swal.fire(
            'ESTA FACTURA YA ESTA EN PROCESO DE MODIFICACION',
            'Primero cancele la modificación pendiente',
            'info'
          )
        }
      }
    }
    modificaFactura()
  }
  function ctrlClickFiltrando (e) {
    // e.preventDefault()
    props.devuelveNuevoFiltro()
  }
  function ctrlClickCancelar (e) {
    // e.preventDefault()
    const modificando = sesion.facturas.modificando
    async function cancela () {
      setGrabando(true)
      props.estaGrabando({ enProceso: true, msjPantalla: `${modificando ? 'Cancelando la modificación...' : 'Eliminando detalle...'}` })
      const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE
         ${modificando ? ' CANCELAR LA MODIFICACION' : ' ELIMINAR LOS REGISTROS'}`,
      {
        method: 'post',
        url: `${modificando ? '/facturas/cancelaMod' : '/facturas/nueva/cancela'}`,
        data: modificando
          ? {
              poolName,
              orclUserVSAM: sesion.orcl_usuarioVSAM,
              noReg: sesion.facturas.clave,
              noFactura: regActual.NO_FACTURA_MOD
            }
          : {
              poolName,
              orclUserVSAM: sesion.orcl_usuarioVSAM,
              noReg: sesion.facturas.clave,
              noEmp: sesion.no_empresaVSAM,
              serie: regActual.NO_FACTURA.substr(0, 8),
              dias: diasCre
            }
      }
      )
      setGrabando(false)
      props.estaGrabando({ enProceso: false, msjPantalla: '' })
      if (respuestaAPI.status === 200) {
        if (modificando) {
          const nSesion = nuevaSesion(false, sesion,
            {
              facturas: { clave: 0, index: 0, modificando: true, filtrando: sesion.facturas.filtrando }
            })
          setSesion(nSesion)
        } else props.recargarCab()
      }
    }

    Swal.fire({
      title: 'CONFIRME',
      text: modificando
        ? 'Desea cancelar el proceso de modificación de la factura?'
        : 'Desea eliminar todos los registros de la factura?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'SI',
      cancelButtonText: 'NO'
    }).then((result) => {
      if (result.isConfirmed) cancela()
    })
  }
  function ctrlClickListar (e) {
    // e.preventDefault()
    props.devuelveListar()
  }
  function ctrlClickSRIAutoriz (e) {
    // e.preventDefault()
    async function sriAutoriza () {
      const esNC = sesion.facturas.clave.substr(0, 1) === 'B'
      setGrabando(true)
      props.estaGrabando({ enProceso: true, msjPantalla: 'Autorizando (SRI)...' })
      const respuestaAPI = await restAPIAxios('NO FUE POSIBLE CONSEGUIR LA AUTORIZACION DEL SRI',
        {
          method: 'post',
          url: '/facturas/autorizaSRI',
          data: {
            poolName,
            orclUserVSAM: sesion.orcl_usuarioVSAM,
            noEmp: sesion.no_empresaVSAM,
            noFactura: sesion.facturas.clave,
            rutaCarpeta: userEmpConfig.ruta_carpetas,
            p12Nombre: userEmpConfig.p12_nombre,
            p12Clave: userEmpConfig.p12_clave,
            obsDatoAdic: userEmpConfig.obs_dato_adicional,
            cualCodEnviaSRI: paramsEmp.CTRL_ITEMS_CODS_XML_ENVIA_SRI,
            decimales: userEmpConfig.decimales
          }
        }
      )
      setGrabando(false)
      props.estaGrabando({ enProceso: false, msjPantalla: '' })
      if (respuestaAPI.status === 200) {
        if (respuestaAPI.data.respuesta) {
          Swal.fire({
            title: `LA ${esNC ? 'NOTA DE CREDITO' : 'FACTURA'} NO FUE AUTORIZADA. EL SRI RESPONDIO LO SIGUIENTE:`,
            text: respuestaAPI.data.respuesta,
            footer: respuestaAPI.data.msjAdic,
            icon: 'info'
          })
        } else {
          Swal.fire(
            `LA ${esNC ? 'NOTA DE CREDITO' : 'FACTURA'} SE AUTORIZO CON EXITO`,
            '** sin mensaje de respuesta SRI **',
            'success'
          )
        }
        props.devuelveDatDoc(respuestaAPI.data.resultado, true, true)
        const nSesion = nuevaSesion(false, sesion,
          {
            facturas: { clave: sesion.facturas.clave, index: 0, modificando: false, filtrando: false }
          })
        setSesion(nSesion)
        navigate('/facturas/existentes')
      }
    }

    Swal.fire({
      title: 'CONFIRME',
      text: 'Desea enviar el documento para ser autorizado por el SRI?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'SI',
      cancelButtonText: 'NO'
    }).then((result) => {
      if (result.isConfirmed) sriAutoriza()
    })
  }

  if (!sesion.auth || !userVSAM) return null

  const modificando = sesion.facturas.modificando
  const fmtoBotsImg = gcFmtosBotones.conImagen
  const widthBots = gcFmtosBotones.imgWidth

  return (
    <Container className='bg-primary-subtle rounded'>
      <Row className='d-flex justify-content-around'>
        {/* LADO IZQUIERDO DE LA FILA DE BOTONES */}
        <Col className='' xs='auto'>
          <OverlayTrigger overlay={<Tooltip>{existentes ? 'nuevo filtro' : 'existentes'}</Tooltip>}>
            <Button
              className={fmtoBotsImg} size='sm' disabled={grabando}
              onClick={ctrlClickFiltrando}
            ><img src={imgFiltrar} alt='Filtrar' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>modificar</Tooltip>}>
            <Button
              className={fmtoBotsImg} size='sm' disabled={grabando}
              hidden={!accModificar || !existentes || regActual.ESTADO === 'A' ||
                (esElect && sriDatosDoc(regActual.SRI_AUTORIZACION).estaAutoriz)}
              onClick={ctrClickModificar}
            ><img src={imgModificar} alt='modificar' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>{modificando ? 'grabar modificación' : 'grabar'}</Tooltip>}>
            <Button
              className={fmtoBotsImg} size='sm' disabled={grabando} hidden={!accCrear || existentes}
              onClick={ctrlClickGrabar}
            ><img src={imgGrabar} alt='grabar' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>listado</Tooltip>}>
            <Button
              className={fmtoBotsImg} size='sm' hidden={!existentes}
              onClick={ctrlClickListar}
            ><img src={imgListado} alt='Listado' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>Email Cliente</Tooltip>}>
            <Button
              className={fmtoBotsImg} size='sm' hidden={!existentes}
              onClick={(e) => props.devuelveRepIndividual('CLIENTE', true)}
            ><img src={imgMail} alt='Email Cliente' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>imprimir</Tooltip>}>
            <SplitButton
              className={gcFmtosBotones.conImagenMenu} variant='' size='sm'
              disabled={grabando} hidden={!existentes}
              title={(<img src={imgImprimir} alt='imprimir' width={widthBots} />)}
              onClick={(e) => props.devuelveRepIndividual('RODILLO')}
            >
              <Dropdown.Item
                className={gcFmtosBotones.dropDownItem}
                onClick={(e) => props.devuelveRepIndividual('RODILLO')}
              >Rodillo
              </Dropdown.Item>
              <Dropdown.Item
                className={gcFmtosBotones.dropDownItem}
                onClick={(e) => props.devuelveRepIndividual('CLIENTE')}
              >Cliente
              </Dropdown.Item>
              <Dropdown.Item
                className={gcFmtosBotones.dropDownItem}
                onClick={(e) => props.devuelveRepIndividual('INTERNO')}
              >Interno
              </Dropdown.Item>
            </SplitButton>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>{modificando ? 'cancelar modificación' : 'cancelar'}</Tooltip>}>
            <Button
              className={fmtoBotsImg}
              size='sm'
              disabled={grabando}
              hidden={existentes}
              onClick={ctrlClickCancelar}
            ><img src={imgCancelar} alt='cancelar' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>nueva factura</Tooltip>}>
            <Button
              className={fmtoBotsImg}
              size='sm'
              disabled={grabando}
              hidden={!accCrear || existentes || modificando}
              onClick={ctrlClickNuevoReg}
            ><img src={imgMas} alt='crear' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>elimina factura {existentes ? props.children : sesion.facturas.index + 1}</Tooltip>}>
            <Button
              className={fmtoBotsImg}
              size='sm'
              disabled={grabando || totalRegs <= 1}
              hidden={(existentes && !accEliminar) || (existentes && regActual.ESTADO !== 'A') || modificando}
              onClick={ctrlClickEliminaReg}
            ><img src={imgMenos} alt='eliminar' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>pide autorización SRI</Tooltip>}>
            <Button
              className='fw-bold fst-italic'
              size='sm'
              disabled={grabando}
              hidden={!existentes || !accSRIAutoriz || regActual.ESTADO === 'A' || modificando ||
                (esElect && sriDatosDoc(regActual.SRI_AUTORIZACION).estaAutoriz)}
              onClick={ctrlClickSRIAutoriz}
            >SRI
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>Formas de Pago</Tooltip>}>
            <Button
              className='fw-bold fst-italic'
              size='sm'
              disabled={grabando}
              hidden={existentes || !accFormasPago || regActual.NO_FACTURA.substring(0, 1) === 'B'}
              onClick={(e) => props.devuelveFP()}
            >FP
            </Button>
          </OverlayTrigger>
        </Col>
        {/* TEXTO EN LA MITAD DE LA FILA DE BOTONES */}
        <Col className='text-center' xs={1}>
          <Form.Label className='fs-6'>{sesion.facturas.index + 1}/{totalRegs}</Form.Label>
        </Col>
        {/* LADO DERECHO DE LA FILA DE BOTONES */}
        <Col className='' xs='auto'>
          <OverlayTrigger overlay={<Tooltip>primera factura</Tooltip>}>
            <Button
              className={fmtoBotsImg}
              size='sm'
              disabled={grabando || editando || sesion.facturas.index === 0}
              onClick={ctrlClickPrimera}
            ><img src={imgPrimero} alt='primero' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>10 anteriores</Tooltip>}>
            <Button
              className={fmtoBotsImg}
              size='sm'
              disabled={grabando || editando || sesion.facturas.index <= 9}
              onClick={ctrlClick10Ant}
            ><img src={imgAnterior} alt='anterior' width={widthBots} />
              <img src={imgAnterior} alt='anterior' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>factura anterior</Tooltip>}>
            <Button
              className={fmtoBotsImg}
              size='sm'
              disabled={grabando || editando || sesion.facturas.index === 0}
              onClick={ctrlClickAnterior}
            ><img src={imgAnterior} alt='anterior' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>factura siguiente</Tooltip>}>
            <Button
              className={fmtoBotsImg}
              size='sm'
              disabled={grabando || editando || sesion.facturas.index === totalRegs - 1}
              onClick={ctrlClickSiguiente}
            ><img src={imgSiguiente} alt='siguiente' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>10 siguientes</Tooltip>}>
            <Button
              className={fmtoBotsImg}
              size='sm'
              disabled={grabando || editando || sesion.facturas.index > totalRegs - 11}
              onClick={ctrlClick10Sig}
            ><img src={imgSiguiente} alt='siguiente' width={widthBots} />
              <img src={imgSiguiente} alt='siguiente' width={widthBots} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>última factura</Tooltip>}>
            <Button
              className={fmtoBotsImg}
              size='sm'
              disabled={grabando || editando || sesion.facturas.index === totalRegs - 1}
              onClick={ctrlClickUltima}
            ><img src={imgUltimo} alt='ultimo' width={widthBots} />
            </Button>
          </OverlayTrigger>
        </Col>
      </Row>
    </Container>
  )
}

export default Botones
