/* eslint-disable react/jsx-indent */
import { Row, Col } from 'react-bootstrap'

import { sriDatosDoc } from '../../utils/generales'

function BarraSRI ({ sriAutoriz, sriClave, sriMensaje }) {
  const cDatosClaveDoc = sriDatosDoc(sriAutoriz)
  const txtAmbienteYAutoriz = sriAutoriz === null || sriAutoriz === '' ? '' : (cDatosClaveDoc.esProducc ? 'PRODUCCION: ' : 'PRUEBAS: ')
  const fmtoTxtAutoriz = sriMensaje
    ? 'ms-1 me-1 bg-success-subtle rounded border border-primary'
    : 'ms-1 me-1 mb-1 bg-success-subtle rounded border border-primary'

  return sriAutoriz === null || sriAutoriz === ''
    ? null
    : <>
      <Row
        className={fmtoTxtAutoriz}
        style={{ fontSize: '12px' }}
      >
        <Col className='text-center'>
          <span className='fw-bold'>{txtAmbienteYAutoriz}</span>{sriAutoriz}
        </Col>
        <Col className='text-center'>
          <span className='fw-bold'>{(cDatosClaveDoc.estaAutoriz ? 'FECHA Y HORA AUTORIZACIÓN: ' : 'CLAVE: ')}</span>
          {sriClave}
        </Col>
      </Row>
      {sriMensaje
        ? <Row
            className='ms-1 me-1 mb-1 bg-success-subtle rounded border-start border-end border-bottom border-primary'
            style={{ fontSize: '11px' }}
          ><Col className='text-center'>{sriMensaje}</Col>
          </Row>
        : null}

      </>
}

export default BarraSRI
