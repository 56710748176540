import { Card } from 'react-bootstrap'

function Encabezado ({ existentes, modificando, noProfMod, sinEncabezado }) {
  if (sinEncabezado) return null
  return (
    <Card.Header
      className={existentes
        ? 'h5 bg-dark-subtle text-center fw-bold p-0'
        : modificando
          ? 'h5 bg-success-subtle text-center fw-bold p-0'
          : 'h5 bg-secondary-subtle text-center fw-bold p-0'}
    >{existentes
      ? 'PROFORMAS EXISTENTES'
      : modificando ? 'MODIFICANDO LA PROFORMA #' + noProfMod : 'PROFORMAS NUEVAS'}
    </Card.Header>
  )
}

export default Encabezado
