import { numberFormat, gcFmtoMaxRows } from '../../../utils/generales'
import encryptStorage from '../../../utils/encryptStorage'

function ItemsTransaccLista ({ registros, ctrlClickLista, datosAPI }) {
  // console.log(registros, datosAPI)
  // Extrae los datos de la empresa (mysql). Parámetros de la empresa y usuario
  const userEmpConfig = encryptStorage.getItem('config')
  const decMoneda = 2
  let campoReg
  switch (datosAPI.urlBase) {
    case '/proformas': campoReg = 'NO_REG_PROF'; break
    case '/facturas': campoReg = 'NO_REG_FACT'; break
    default: campoReg = ''
  }

  function ctrlClickTabla (e) {
    // e.preventDefault()
    const index = e.target.getAttribute('index')
    // console.log(index, e.target, registros[index])
    // En caso de que presione en la cabecera u otro lugar donde index es null
    if (index === null) {
      ctrlClickLista({}, -1)
    } else {
      ctrlClickLista(registros[index], index)
    }
  }

  if (!userEmpConfig) return null

  const fmtoTitulo = 'h6 pt-1 pb-1 m-0 border-primary-subtle border-bottom bg-secondary-subtle text-center'

  return (
    <>
      {/* console.log(userEmpConfig) */}
      <div id='ItemsTransaccLista' className={fmtoTitulo}>DETALLE DE ITEMS</div>
      <div className='overflow-scroll' style={{ maxHeight: 628 }}>
        <table
          className='table table-sm table-bordered table-hover caption-top'
          style={{ fontSize: '11px' }}
          onClick={ctrlClickTabla}
        >
          <thead className='table-primary sticky-top'>
            <tr className='' key='itemCabecera'>
              <th
                key={0}
                className={'text-center border border-primary ' + gcFmtoMaxRows(registros.length)}
                style={{ minWidth: 29, position: 'sticky', left: 0 }}
              >{registros.length}
              </th>
              <th key={1} className='text-center' style={{ minWidth: 60, position: 'sticky', left: 30 }}>No. Item</th>
              <th key={2} className='text-center' style={{ minWidth: 100 }}>Código</th>
              <th key={3} className='' style={{ minWidth: 250 }}>Descripción</th>
              <th key={4} className='text-end' style={{ minWidth: 70 }}>Cantidad</th>
              <th key={5} className='text-center'>UM</th>
              <th key={6} className='text-end' style={{ minWidth: 110 }}>Precio Venta</th>
              <th key={7} className='text-center' style={{ minWidth: 100 }}>Dscto.</th>
              <th key={8} className='text-end' style={{ minWidth: 100 }}>Dscto. Valor</th>
              <th key={9} className='text-end' style={{ minWidth: 110 }}>Valor Unit.</th>
              <th key={10} className='text-end' style={{ minWidth: 120 }}>Valor Total</th>
              <th key={11} className='' style={{ minWidth: 250 }}>Detalle</th>
              <th key={12} className='text-center' style={{ minWidth: 65 }}>Tipo IVA</th>
              <th key={13} className='' style={{ minWidth: 180 }}>Auxiliar</th>
              <th key={14} className='' style={{ minWidth: 250 }}>Otros Códigos</th>
              <th key={15} className='text-center' style={{ minWidth: 220 }} colSpan='2'>Grupo</th>
            </tr>
          </thead>
          <tbody
            className=''
          >
            {registros.map((registro, indexReg) => (
              <tr key={registro[campoReg]}>
                <th
                  key='INDEX' id={registro[campoReg]} index={indexReg}
                  className='text-center table-primary border-bottom border-secondary-subtle'
                  style={{ position: 'sticky', left: 0 }}
                >{registro[campoReg]}
                </th>
                <td
                  key='NO_ITEM_USER' id={registro[campoReg]} index={indexReg}
                  className='text-center table-primary border-bottom border-secondary-subtle'
                  style={{ position: 'sticky', left: 30 }}
                >{registro.NO_ITEM_USER}
                </td>
                <td
                  key='CODIGO' className='' id={registro[campoReg]} index={indexReg}
                >{registro.CODIGO}
                </td>
                <td
                  key='DESCRIP' className='' id={registro[campoReg]} index={indexReg}
                >{registro.DESCRIP}
                </td>
                <td
                  key='CANT' className='text-end' id={registro[campoReg]} index={indexReg}
                >{numberFormat(registro.CANT, userEmpConfig.decimales)}
                </td>
                <td
                  key='ABREV' id={registro[campoReg]} index={indexReg}
                  className={'text-center' + (registro.NO_UND_MED_X_ITEM === null ? ' bg-danger' : '')}
                >{registro.ABREV}
                </td>
                <td
                  key='VU_SIN_DSCTO' className='text-end' id={registro[campoReg]} index={indexReg}
                >{numberFormat(registro.VU_SIN_DSCTO, userEmpConfig.decimales)}
                </td>
                <td
                  key='P_DSCTO' className='text-end' id={registro[campoReg]} index={indexReg}
                >{registro.VU_SIN_DSCTO === 0
                  ? '0.00 %'
                  : numberFormat((1 - registro.VALOR_UNIT / registro.VU_SIN_DSCTO) * 100, userEmpConfig.decimales) + ' %'}
                </td>
                <td
                  key='V_DSCTO' className='text-end' id={registro[campoReg]} index={indexReg}
                >{numberFormat(registro.VU_SIN_DSCTO - registro.VALOR_UNIT, userEmpConfig.decimales)}
                </td>
                <td
                  key='VALOR_UNIT' className='text-end' id={registro[campoReg]} index={indexReg}
                >{numberFormat(registro.VALOR_UNIT, userEmpConfig.decimales)}
                </td>
                <td
                  key='V_TOTAL' className='text-end' id={registro[campoReg]} index={indexReg}
                >{numberFormat(registro.CANT * registro.VALOR_UNIT, decMoneda)}
                </td>
                <td
                  key='DETALLE_ITEM' className='' id={registro[campoReg]} index={indexReg}
                >{registro.DETALLE_ITEM}
                </td>
                <td
                  key='TIPO_IVA' id={registro[campoReg]} index={indexReg}
                  className={'text-center' + (registro.T_IVA === '2' ? '' : ' bg-success-subtle')}
                >{registro.T_IVA === '2' ? 'IVA<>0%' : 'IVA 0%'}
                </td>
                <td
                  key='DESCRIP_AUX' id={registro[campoReg]} index={indexReg}
                  className='table-primary border-bottom border-secondary-subtle'
                >{registro.DESCRIP_AUX}
                </td>
                <td
                  key='OTROS_COD' id={registro[campoReg]} index={indexReg}
                  className='table-primary border-bottom border-secondary-subtle'
                >{registro.OTROS_COD}
                </td>
                <td
                  key='GRUPO_COD' id={registro[campoReg]} index={indexReg}
                  className='table-primary text-center border-bottom border-secondary-subtle'
                  style={{ width: 30 }}
                >{registro.GRUPO_COD}
                </td>
                <td
                  key='GRUPO_DESCRIP' id={registro[campoReg]} index={indexReg}
                  className='table-primary border-bottom border-secondary-subtle'
                >{registro.GRUPO_DESCRIP}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default ItemsTransaccLista
