import { memo, useContext } from 'react'

import { Card, Col } from 'react-bootstrap'

import { SesionContext } from '../../context/SesionContext'

function Footer () {
  const [sesion] = useContext(SesionContext)
  // console.log('Footer')

  if (!sesion.auth) return null

  return (
    <Card.Footer className='ps-2 pb-1 pt-1 pe-2'>
      <Card.Text className='d-flex'>
        <Col>{sesion.userNombre}</Col>
        <Col className='text-end'>{sesion.orcl_host}</Col>
      </Card.Text>
    </Card.Footer>
  )
}

export default memo(Footer)
