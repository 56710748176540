import { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import { Dropdown, InputGroup, Row, Spinner } from 'react-bootstrap'

import restAPIAxios from '../../utils/axios'
import { SesionContext } from '../../context/SesionContext'
import encryptStorage from '../../utils/encryptStorage'

import CampoNumerico from '../generales/CampoNumerico'
import { numberFormat } from '../../utils/generales'

function infoPVsXItem (noItem) {
  // console.log(condAdicional)
  return {
    url: '/pvsxitem',
    maxRegs: 100,
    txtTabla: 'PVS X ITEM',
    tabCampos: ['KEY_TAB', 'V_PVENTA'],
    txtLblTabla: 'PV:',
    txtCampos: ['No.', 'Precio'],
    campoFiltro: { enTabla: 'V_PVENTA', enSql: 'V_PVENTA' },
    noItem
  }
}

function PVsXItem (props) {
  const { url, maxRegs, txtTabla, tabCampos, txtLblTabla, txtCampos, campoFiltro, noItem } = infoPVsXItem(props.noItem)
  const [sesion] = useContext(SesionContext)
  // console.log(props.infoTablaLista)
  const [datosTabla, setDatosTabla] = useState([])
  const [filtro, setFiltro] = useState('')
  const [cargando, setCargando] = useState(false)

  // Extrae los datos de la empresa (mysql). Parámetros de la empresa y usuario
  const userEmpConfig = encryptStorage.getItem('config')
  const poolName = sesion.orcl_host

  useEffect(() => {
    // console.log(!props.valTabla, datosTabla)
    // Para que no vaya UNDEFINED al valor del combo (sale un Warning)
    !props.valTabla ? setFiltro(0) : setFiltro(props.valTabla)
    // Para que oculte la tabla en lugar de tener abierta y para obligar a volver a cargar datos
    setDatosTabla([])
  }, [sesion, props.valTabla])

  // Carga los registros de la Tabla para selección
  async function ctrlClickToggle (e) {
    // e.preventDefault()
    setCargando(true)
    if (datosTabla.length > 0) {
      setCargando(false)
      setDatosTabla([])
    } else {
      const respuestaAPI = await restAPIAxios(`NO FUE POSIBLE CARGAR ${txtTabla}`,
        {
          method: 'post',
          url,
          data: { poolName, orclUserVSAM: sesion.orcl_usuarioVSAM, noEmp: sesion.no_empresaVSAM, noItem }
        }
      )
      // console.log(respuestaAPI)
      if (respuestaAPI.status === 200) {
        if (respuestaAPI.data.length > maxRegs) {
          Swal.fire(
            `EL FILTRO SELECCIONADO CARGA MAS DE ${maxRegs} ${txtTabla} (${respuestaAPI.data.length})`,
            'Elija un filtro que reduzca la cantidad de registros para seleccionar',
            'info'
          )
        } else {
          // console.log(respuestaAPI.data)
          setDatosTabla(respuestaAPI.data)
        }
      }
      setCargando(false)
    }
  }
  // Devuelve el precio de venta que selecciona en la tabla
  async function ctrlClickLista (e) {
    // e.preventDefault()
    const index = e.target.getAttribute('index')
    // En caso de que presione en la cabecera u otro lugar donde index es null
    if (index === null) {
      console.log(e.target, index, datosTabla)
    } else {
      setFiltro(datosTabla[index][campoFiltro.enTabla])
      setValorNumerico('pvsXItem', datosTabla[index][campoFiltro.enTabla])
      if (props.devuelveRegistro !== undefined) props.devuelveRegistro(datosTabla[index])
      setDatosTabla([])
    }
  }
  // Devuelve el valor que digita el usuario
  function setValorNumerico (campo, valor) {
    props.devuelveFiltro(campo, valor)
  }

  if (!sesion.auth) return null

  return (
    <>
      <Row className='' id={'tabla-' + txtTabla + '-' + campoFiltro.enTabla} style={{ maxWidth: 250 }}>
        <InputGroup size='sm'>
          <InputGroup.Text className={props.fmtoText} id='lblTabla'>{txtLblTabla}</InputGroup.Text>
          <Spinner animation='grow' size='sm' hidden={!cargando} />
          <CampoNumerico
            idName='pvsXItem' valor={filtro} devuelveVal={setValorNumerico}
            fmtoInput={props.fmtoInput} decimales={userEmpConfig.decimales} obligatorio
            deshabilitado={props.deshabilitado} soloPositivo
          >{filtro}
          </CampoNumerico>
          <Dropdown.Toggle
            split
            variant='outline-secondary'
            id='tablaCtrl'
            disabled={props.deshabilitado}
            onClick={ctrlClickToggle}
          />
        </InputGroup>
      </Row>
      <div
        id='tablaDiv'
        className='overflow-scroll border border-3'
        style={{ maxHeight: 400 }}
        hidden={datosTabla.length === 0}
      >
        <table
          className='table table-bordered table-sm table-hover table-responsive-md'
          onClick={ctrlClickLista}
        >
          <thead>
            <tr className='table-primary sticky-top'>
              {txtCampos.map((txtCampo, index) => (
                <th
                  key={index}
                  className={datosTabla.length === 0 ? '' : index === 0 ? 'text-center' : ''}
                  hidden={txtCampo === ''}
                >{txtCampo}
                </th>
              ))}
            </tr>
          </thead>
          <tbody
            className='table-light'
          >
            {datosTabla.map((registro, indexReg) => (
              <tr key={registro.KEY_TAB}>
                {tabCampos.map((campo, indexCampo) => (
                  <td
                    key={campo}
                    className={indexCampo === 0 ? 'text-center' : typeof registro[campo] === 'number' ? 'text-end' : ''}
                    id={registro.KEY_TAB}
                    index={indexReg}
                    hidden={txtCampos[indexCampo] === ''}
                  >{numberFormat(registro[campo], 2)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default PVsXItem
