import { useContext, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'

import restAPIAxios, { restAPIMySQL } from '../../utils/axios'
import { SesionContext, nuevaSesion } from '../../context/SesionContext'
import encryptStorage from '../../utils/encryptStorage'
import { colocaFocoDirecto } from '../../utils/generales'
import CampoNumerico from '../generales/CampoNumerico'

function Login () {
  const navigate = useNavigate()
  // Define el Estado para datos del formulario
  // los datos se guardan en leeDatos
  const [credenciales, setCredenciales] = useState({
    email: '',
    password: ''
  })
  const [servidores, setServidores] = useState([])
  const [servidor, setServidor] = useState(0)
  const [cargando, setCargando] = useState(false)
  const [noUserAVerificar, setNoUserAVerificar] = useState({ sistema: 0, digitado: 0 })
  const [intentosVerifica, setIntentosVerifica] = useState(1)

  const datosUserAcc = useRef([])
  const datosUserOrcl = useRef({})

  // Utilizo CONTEXT para guardar los valores si el Login es correcto
  const [, setSesion] = useContext(SesionContext)

  // Método utilizado para validar el Inicio de Sesión
  async function iniciaSesion (e) {
    e.preventDefault()
    setCargando(true)
    // Obtiene los datos del Usuario ACCESS
    const respuestaAPI = await restAPIMySQL('',
      {
        method: 'post',
        url: '/login',
        data: { ...credenciales, id: servidor }
      }, true
    )
    if (respuestaAPI.status === 200) {
      // ** MARIADB solo porque mariadb no maneja campos json realmente (son de tipo longtext)
      if (typeof respuestaAPI.data[0].orcl_config !== 'object') {
        respuestaAPI.data[0].orcl_config = JSON.parse(respuestaAPI.data[0].orcl_config)
      }
      if (typeof respuestaAPI.data[0].orcl_configPool !== 'object') {
        respuestaAPI.data[0].orcl_configPool = JSON.parse(respuestaAPI.data[0].orcl_configPool)
      }
      if (typeof respuestaAPI.data[0].email_config !== 'object') {
        respuestaAPI.data[0].email_config = JSON.parse(respuestaAPI.data[0].email_config)
      }
      // datos user_empresas
      if (typeof respuestaAPI.data[1].textos_labels !== 'object') {
        respuestaAPI.data[1].textos_labels = JSON.parse(respuestaAPI.data[1].textos_labels)
      }
      if (typeof respuestaAPI.data[1].numeros_maximos !== 'object') {
        respuestaAPI.data[1].numeros_maximos = JSON.parse(respuestaAPI.data[1].numeros_maximos)
      }
      if (typeof respuestaAPI.data[1].series !== 'object') {
        respuestaAPI.data[1].series = JSON.parse(respuestaAPI.data[1].series)
      }

      // Extrae los datos del usuario de la respuesta y los coloca en el sessionStorage
      const { token, nombre, noAVerificar, bloqueado } = respuestaAPI.data[0]
      if (bloqueado) {
        Swal.fire({
          title: 'EL USUARIO FUE BLOQUEADO POR EL ADMINISTRADOR DEL SISTEMA',
          text: 'Consulte a su técnico',
          icon: 'info'
        }).then((result) => {
          if (result.isConfirmed) {
            colocaFocoDirecto('idEmail')
          }
        })
        setNoUserAVerificar({ sistema: 0, digitado: 0 })
        setIntentosVerifica(1)
        setSesion(nuevaSesion(true))
        setCredenciales({ email: '', password: '' })
        setServidores([])
        setServidor(0)
        setCargando(false)
      } else {
        // Obtiene los datos del usuario ORACLE
        const respuestaAPIOrcl = await restAPIAxios('ERROR AL OBTENER EL USUARIO VSAM',
          {
            method: 'post',
            url: '/usuarios',
            data: {
              orclUserVSAM: respuestaAPI.data[0].orcl_usuarioVSAM,
              noUserVSAM: respuestaAPI.data[0].no_usuarioVSAM,
              userNombreWeb: nombre,
              orclConfig: respuestaAPI.data[0].orcl_config,
              orclConfigPool: respuestaAPI.data[0].orcl_configPool
            },
            headers: { Authorization: `Bearer ${token}` }
          }
        )
        if (respuestaAPIOrcl.status === 200) {
          // Envia el correo de verificación al usuario
          const resAPIEmail = await restAPIMySQL('ERROR AL ENVIAR EL CORREO DE VERIFICACION AL USUARIO',
            {
              method: 'post',
              url: '/sendEmail',
              data: {
                emailConfig: respuestaAPI.data[0].email_config,
                datos: {
                  from: `Visual SAM Web ${respuestaAPI.data[0].user}`,
                  to: `${respuestaAPI.data[0].email}`,
                  subject: 'Verificación de acceso al sistema Visual SAM Web',
                  text: `Bienvenido al sistema Visual SAM Web ${respuestaAPI.data[0].nombre}`,
                  html: `<p>Para confirmar el ingreso al sistema, digite en la pantalla de inicio el siguiente número:</p>
                      <p style="text-align: center">
                        <span style="padding: 3px 8px 2px 8px; text-align: center; border-style: solid; border-color: blue; border-width: 1px;">
                          <b>${respuestaAPI.data[0].noAVerificar}</b>
                        </span>
                      </p>`
                }
              }
            }
          )
          if (resAPIEmail.status === 200) {
            datosUserAcc.current = respuestaAPI.data
            datosUserOrcl.current = respuestaAPIOrcl.data[0]
            setNoUserAVerificar({ sistema: noAVerificar, digitado: 0 })
            setCargando(false)
          } else {
            setSesion(nuevaSesion(true))
            setCargando(false)
            Swal.fire({
              title: `NO SE PUDO ENVIAR EL CORREO A (${respuestaAPI.data[0].email})`,
              text: 'Consulte a su técnico',
              footer: respuestaAPIOrcl.response ? respuestaAPIOrcl.response.data : respuestaAPIOrcl.message,
              icon: 'error'
            }).then((result) => {
              if (result.isConfirmed) {
                colocaFocoDirecto('idEmail')
              }
            })
          }
        } else {
          setSesion(nuevaSesion(true))
          setCargando(false)
          Swal.fire({
            title: `NO SE PUDO ENCONTRAR EL USUARIO VSAM (${respuestaAPI.data[0].no_usuarioVSAM})`,
            text: 'Consulte a su técnico',
            footer: respuestaAPIOrcl.response ? respuestaAPIOrcl.response.data.msjError : respuestaAPIOrcl.message,
            icon: 'error'
          }).then((result) => {
            if (result.isConfirmed) {
              colocaFocoDirecto('idEmail')
            }
          })
        }
      }
    } else {
      setSesion(nuevaSesion(true))
      setCargando(false)
      if (!respuestaAPI.response) {
        Swal.fire({
          title: 'ERROR EN EL SERVIDOR',
          text: 'Los servicios no están funcionando.  Consulte a su técnico',
          icon: 'error'
        })
      } else if (respuestaAPI.response.data.msjError === 'Contraseña incorrecta') {
        Swal.fire({
          title: 'ERROR DE CREDENCIALES',
          text: respuestaAPI.response.data.msjError,
          icon: 'info'
        }).then((result) => {
          if (result.isConfirmed) {
            setCredenciales({ email: credenciales.email, password: '' })
            colocaFocoDirecto('idPassword')
          }
        })
      } else {
        Swal.fire({
          title: 'ERROR DE CREDENCIALES',
          text: respuestaAPI.response.data.msjError,
          icon: respuestaAPI.response.data.msjError.substr(0, 10) === 'El usuario' ? 'info' : 'error'
        }).then((result) => {
          if (result.isConfirmed) {
            setCredenciales({ email: credenciales.email, password: '' })
            colocaFocoDirecto('idPassword')
          }
        })
      }
    }
  };

  function confirmaNoUser (e) {
    e.preventDefault()
    // Si el número de intentos llega a 3 vuelve a los datos del login
    if (noUserAVerificar.sistema === noUserAVerificar.digitado) {
      const { token, nombre } = datosUserAcc.current[0]
      const userVSAM = datosUserOrcl.current
      const poolAlias = datosUserAcc.current[0].orcl_configPool.poolAlias
      // No hace falta token:token porque tienen el mismo nombre
      const nSesion = {
        token,
        userNombre: userVSAM.NOMBRE,
        no_usuarioVSAM: datosUserAcc.current[0].no_usuarioVSAM,
        orcl_host: poolAlias,
        orcl_usuarioVSAM: datosUserAcc.current[0].orcl_usuarioVSAM,
        no_empresaVSAM: datosUserAcc.current[1].no_empresaVSAM,
        email_config: datosUserAcc.current[0].email_config,
        proformas: { clave: 0, index: 0, modificando: false, filtrando: false },
        facturas: { clave: 0, index: 0, modificando: false, filtrando: false },
        auth: true
      }
      setSesion(nSesion)
      encryptStorage.setItem('sesion', nSesion)
      encryptStorage.setItem('userVSAM', userVSAM)
      encryptStorage.setItem('config', datosUserAcc.current[1])
      Swal.fire({
        title: 'SU SESION SE INICIO CON EXITO',
        text: `Bienvenido a Visual SAM Web "${nombre}"`,
        icon: 'success'
      })
      // Redireccionar al Menú Principal (Bienvenida)
      navigate('/bienvenida')
    } else {
      if (intentosVerifica === 3) {
        setNoUserAVerificar({ sistema: 0, digitado: 0 })
        setIntentosVerifica(1)
        setSesion(nuevaSesion(true))
        setCredenciales({ email: '', password: '' })
        setServidores([])
        setServidor(0)
      } else {
        Swal.fire({
          title: 'EL NUMERO DIGITADO NO ES CORRECTO',
          text: `Puede intentar ${3 - intentosVerifica} veces más`,
          icon: 'info'
        }).then((result) => {
          if (result.isConfirmed) {
            colocaFocoDirecto('noUserAVerificar')
          }
        })
        setIntentosVerifica(intentosVerifica + 1)
      }
    }
  }

  function ctrlChangeNoAVerificar (idName, valor) {
    setNoUserAVerificar({ sistema: noUserAVerificar.sistema, digitado: valor })
  }

  function datosValidos () {
    // Destructuring
    const { email, password } = credenciales
    // Revisar que las propiedades del objeto tengan contenido
    const esValido = email.length && password.length
    return esValido
  }

  // almacena lo que el usuario escribe en el Estado del formulario
  const leeDatos = (e) => {
    // e.preventDefault()
    setCredenciales({
      ...credenciales,
      [e.target.name]: e.target.value
    })
  }

  async function ctrlCargaServidores (e) {
    // e.preventDefault()
    if (credenciales.email) {
      const respuestaAPI = await restAPIMySQL('ERROR EN SERVIDOR',
        {
          method: 'get',
          url: `/servidores/${credenciales.email}`
        }
        , false)
      if (respuestaAPI.status === 200) {
        setServidores(respuestaAPI.data)
        setServidor(respuestaAPI.data.length > 0 ? respuestaAPI.data[0].id : 0)
      } else {
        setServidores([])
        setServidor(0)
      }
    }
  }

  // Asigna el nombre del servidor para el Alias del Pool de conexiones ORCL
  function colocaServidor (orclHost, orclUserVSAM) {
    let server = orclHost.substr(0, orclHost.indexOf(':'))
    server = server + '-' + orclUserVSAM
    return server
  }

  return (
  // Solo para que el CARD se coloque en el centro
    <div className='d-flex justify-content-center m-4'>
      <Card className='text-center' style={{ width: 400 }}>
        <Card.Header>
          Iniciar Sesión
        </Card.Header>
        <Card.Body>
          <Form onSubmit={iniciaSesion} hidden={noUserAVerificar.sistema !== 0}>
            {/* Email */}
            <InputGroup size='sm mb-1' hidden={cargando}>
              <InputGroup.Text style={{ width: 90 }}>Email:</InputGroup.Text>
              <Form.Control
                id='idEmail'
                type='email'
                name='email'
                autoFocus
                autoComplete='on'
                aria-autocomplete='list'
                value={credenciales.email}
                required
                onChange={leeDatos}
                onBlur={ctrlCargaServidores}
              />
            </InputGroup>
            {/* Contraseña */}
            <InputGroup size='sm mb-1' hidden={cargando}>
              <InputGroup.Text style={{ width: 90 }}>Contraseña:</InputGroup.Text>
              <Form.Control
                id='idPassword'
                type='password'
                name='password'
                maxLength={20}
                value={credenciales.password}
                required
                onChange={leeDatos}
              />
            </InputGroup>
            {/* Servidores */}
            <InputGroup size='sm mb-1' hidden={servidores.length < 2 || cargando}>
              <InputGroup.Text style={{ width: 90 }}>Servidores:</InputGroup.Text>
              <Form.Select
                className='text-center'
                size='sm'
                id='optServidor'
                aria-describedby='lblServidores'
                value={servidor}
                disabled={!credenciales.email}
                onChange={(e) => setServidor(e.target.value)}
              >
                {servidores.map((registro, index) => (
                  <option
                    value={registro.id}
                    key={registro.id}
                    index={index}
                  >{colocaServidor(registro.orcl_host, registro.orcl_usuarioVSAM)}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
            <div className='text center mt-3 mb-3' hidden={!cargando}>Conectando con la BD...</div>
            <Button
              className='mt-2'
              variant='outline-primary'
              id='btnIniciaSesion'
              type='submit'
              disabled={!datosValidos() || cargando}
            >Iniciar Sesión
            </Button>
          </Form>
          <Form className='' onSubmit={confirmaNoUser} hidden={noUserAVerificar.sistema === 0}>
            <InputGroup
              style={{ width: 370 }}
              className='justify-content-center mb-1' size='sm'
            >
              <Form.Text>
                Digite el número enviado a su correo para confirmar el ingreso al sistema:
              </Form.Text>
              <CampoNumerico
                idName='noUserAVerificar'
                fmtoInput='border border-primary-subtle mt-1'
                devuelveVal={ctrlChangeNoAVerificar} sinDivMiles
                decimales={0} obligatorio deshabilitado={false}
                soloPositivo filtrando={false} style={{ maxWidth: 90 }}
                actualizaEnChange
              >{noUserAVerificar.digitado}
              </CampoNumerico>
            </InputGroup>
            <Button
              className='mt-2 mb-1'
              variant='outline-primary'
              id='btnConfirmaUser'
              type='submit'
            >Confirmar usuario
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Login
